import { createRouter, createWebHistory} from 'vue-router'
import authGuard from './authGuard';


import Home from '../views/Home.vue'
import Articles from '../views/Articles.vue'
import News from '../views/News.vue'
import Services from '../views/Service.vue'
import Contacts from '../views/Contacts.vue'
import Training from '../views/Formation.vue'
import TrainingAll from '../views/FormationAll.vue'
import Dashboard from '../views/Dashboard.vue'
import ListeOfArticle from '../views/ListeOfArticles.vue'
import ListeOfNews from '../views/ListeOfNews.vue'
import ListOfMails from '../views/ListOfMails.vue'
import AddAnArticle from '../views/AddAnArticle.vue'
import AddAnNews from '../views/AddAnNews.vue'
import ImportSubscribers from '../views/ImportSubscribers.vue'
import AddAnMail from '../views/AddAnMail.vue'
import ProfileOverview from '../views/ProfileOverview.vue'
import Register from '../views/Register.vue'
import LogIn from '../views/LogIn.vue'
import ProfileEdit from '../views/ProfileEdit.vue'
import ProfilePassword from '../views/ProfilePassword.vue'
import servicesFC from '@/views/servicesFC.vue'
import servicesFCa from '@/views/servicesFCa.vue'
import servicesFCe from '@/views/servicesFCe.vue'
import article from '@/views/article.vue'
import NewsDetail from '@/views/NewsDetail.vue'
import trainingFC from '@/views/trainingFC.vue'
import trainingFS from '@/views/trainingFS.vue'
import ServicesMPME from '@/views/ServicesMPME.vue'
import resetPasswordConfirm from '@/views/resetPasswordConfirm.vue';
import resetPassword from '@/views/resetPassword.vue';
import about from '@/views/About.vue'
import users from '@/views/viewuser.vue'
import Desabonnement from '@/views/Desabonnement.vue';
import Politique from '@/views/Politique.vue';
import Conditions from '@/views/Conditions.vue';
import RegisterTrainingProspectVue from '@/views/RegisterTrainingProspect.vue';

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { scrollToTop: true }
  },
  {
    path: "/articles",
    name: "Articles",
    component: Articles,
    meta: { scrollToTop: true }
  },
  {
    path: "/news",
    name: "news",
    component: News,
    meta: { scrollToTop: true }
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
    meta: { scrollToTop: true }
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    meta: { scrollToTop: true }
  },
  {
    path: "/training",
    name: "TrainingAll",
    component: TrainingAll,
    meta: { scrollToTop: true }
  },
  {
    path: "/training-cybersecurity",
    name: "Training1",
    component: Training,
    meta: { scrollToTop: true }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { scrollToTop: true, requiresAuth: true },
  },
  {
    path: "/ListeOfArticle",
    name: "ListeOfArticle",
    component: ListeOfArticle,
    meta: { scrollToTop: true, requiresAuth: true }
  },
  {
    path: "/ListeOfNews",
    name: "ListeOfNews",
    component: ListeOfNews,
    meta: { scrollToTop: true, requiresAuth: true }
  },
  {
    path: "/ListOfMails",
    name: "ListOfMails",
    component: ListOfMails,
    meta: { scrollToTop: true, requiresAuth: true }
  },
  {
    path: "/log",
    name: "login",
    component: LogIn,
    meta: { scrollToTop: true }
  },
  {
    path: "/Register",
    name: "Register",
    component: Register,
    meta: { scrollToTop: true, requiresAuth: true }
  },
  {
    path: "/Profile",
    name: "Profile",
    component: ProfileOverview,
    meta: { scrollToTop: true, requiresAuth: true }
  },
  {
    path: "/AddAnArticle/:id/",
    name: "AddAnArticleWith",
    component: AddAnArticle,
    meta: { scrollToTop: true, requiresAuth: true }
  },
  {
    path: "/AddAnNews/:id/",
    name: "AddAnNewsWith",
    component: AddAnNews,
    meta: { scrollToTop: true, requiresAuth: true }
  },
  {
    path: "/AddAnMail/:id/",
    name: "AddAnMailWith",
    component: AddAnMail,
    meta: { scrollToTop: true, requiresAuth: true }
  },
  {
    path: "/subscribers/:id/:email/",
    name: "Desabonnement",
    component: Desabonnement,
    meta: { scrollToTop: true }
  },
  {
    path: "/AddAnArticle",
    name: "AddAnArticle",
    component: AddAnArticle,
    meta: { scrollToTop: true, requiresAuth: true }
  },
  {
    path: "/AddAnNews",
    name: "AddAnNews",
    component: AddAnNews,
    meta: { scrollToTop: true, requiresAuth: true }
  },
  {
    path: "/AddAnMail",
    name: "AddAnMail",
    component: AddAnMail,
    meta: { scrollToTop: true, requiresAuth: true }
  },
  {
    path: "/ImportSubscribers",
    name: "ImportSubscribers",
    component: ImportSubscribers,
    meta: { scrollToTop: true, requiresAuth: true }
  },
  {
    path: "/ProfilePassword",
    name: "ProfilePassword",
    component: ProfilePassword,
    meta: { scrollToTop: false, requiresAuth: true }
  },
  {
    path: "/ProfileEdit",
    name: "ProfileEdit",
    component: ProfileEdit,
    meta: { scrollToTop: false, requiresAuth: true }
  },
  {
    path: "/servicesFC",
    name: "servicesFC",
    component: servicesFC,
    meta: { scrollToTop: false }
  },
  {
    path: "/servicesFCa",
    name: "servicesFCa",
    component: servicesFCa,
    meta: { scrollToTop: false }
  },
  {
    path: "/servicesFCe",
    name: "servicesFCe",
    component: servicesFCe,
    meta: { scrollToTop: false }
  },
  {
    path: "/article/:id",
    name: "article",
    component: article,
    meta: { scrollToTop: true },
    props: true
  },
  {
    path: "/news/:id",
    name: "newsDetail",
    component: NewsDetail,
    meta: { scrollToTop: true },
    props: true
  },
  {
    path: "/trainingFC",
    name: "trainingFC",
    component: trainingFC,
    meta: { scrollToTop: false }
  },
  {
    path: "/trainingFS",
    name: "trainingFS",
    component: trainingFS,
    meta: { scrollToTop: false }
  },
  {
    path: "/servicesMPME",
    name: "ServicesMPME",
    component: ServicesMPME,
    meta: { scrollToTop: false }
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: resetPassword,
    meta: { scrollToTop: false }
  },
  {
    path: "/password/reset/confirm/:uid/:token",
    name: "resetPasswordConfirm",
    component: resetPasswordConfirm,
    meta: { scrollToTop: false },
    props: true
  },
  {
    path: "/about",
    name: "about",
    component: about,
    meta: { scrollToTop: true }
  },
  {
    path: "/users",
    name: "users",
    component: users,
    meta: { scrollToTop: false, requiresAuth: true }
  },
  {
    path: "/Politique",
    name: "Politique",
    component: Politique,
    meta: { scrollToTop: true }
  },
  {
    path: "/Conditions",
    name: "Conditions",
    component: Conditions,
    meta: { scrollToTop: true }
  },
  {
    path: "/registerTrainingProspect",
    name: "register-training-prospect",
    component: RegisterTrainingProspectVue,
    meta: { scrollToTop: true }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to: any, from: any, savedPosition: any) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
    else if (to.meta.scrollToTop) {
      return { top: 0 };
    }
  },
})

// Utilise le guard de navigation pour protéger les routes nécessitant une authentification
router.beforeEach(authGuard);

export default router
