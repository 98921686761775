<script setup>
import OneService from "../components/OneService.vue";
import OneSummary from "../components/OneSummary.vue";
import HeaderComponent from '../components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue';
import {ref} from 'vue'

const canShow = ref(false)

</script>

<template class="max-w-screen font-montserrat">
  <HeaderComponent/>
  <div id="services" class="lg:h-[80px] h-[50px]"></div>
  <section
    class="relative w-full lg:min-h-screen md:min-h-[400px] min-h-[300px] flex flex-row justify-between px-5 lg:px-0 lg:pl-20 lg:pr-0 bg-chocolate"
  >
    <div class="self-center w-full flex flex-1 flex-col lg:h-96 h-80 my-5 z-30">
      <div class="w-full">
        <h1
          class="lg:text-6xl md:text-5xl text-4xl font-bold lg:after:content-[''] lg:after:h-[9px] lg:after:w-[60px] lg:after:block lg:after:mt-8 barre-decoration uppercase text-secondary text-white lg:text-start text-center"
        >
          Nos<br class="lg:block hidden"/> Expertises
        </h1>
        <p class="lg:mt-8 mt-5 md:mb-0 mb-5 lg:text-black text-white lg:text-start text-center">Nous sommes disponibles et prêts à répondre à toutes vos questions et à vous assister de la manière qui vous convient le mieux.
        </p>
      </div>
      <div class="mt-auto flex md:flex-row md:flex-wrap flex-col lg:justify-start md:justify-center justify-start gap-5 lg:item-start item-center text-secondary">
        <router-link
          to="/contacts"
          class="uppercase font-bold shrink-0 inline-block py-2 px-4 rounded-md border-4 border-white text-white md:mx-0 mx-auto"
          >Contactez-nous</router-link
        >
        <a
          href="/Document/IronSecur_SDLC_Services.pdf" download="IronSecur_SDLC_Services.pdf"
          class="uppercase font-bold shrink-0 inline-block py-3 px-4 rounded-md bg-white md:mx-0 mx-auto"
          >notre catalogue <i class="fa-solid fa-download"></i>
        </a>
      </div>
    </div>
    <div class="absolute h-full right-0 top-0 bottom-0 left-0 lg:hidden block z-0">
      <div class="w-full h-full">
          <img src="../assets/img/mobile-banner.jpg" alt="" class="absolute h-full w-full left-0 top-0 bottom-0 block object-cover bg-center" />
      </div>
    </div>

    <div class="absolute h-full right-0 top-0 bottom-0 left-0 lg:hidden block z-20" style="background-color: rgba(0, 0, 0, 0.7);"></div>

    <div class="relative flex-1 min-h-screen lg:block hidden">
        <img src="../assets/img/expertise.png" alt="" class="absolute block bottom-0 right-0 h-full object-cover bg-center" />
    </div>
  </section>
  <div id="servicesList" class="h-[10px] w-full"></div>
  <section
    class="md:mt-20 mt-16 w-full flex flex-col justify-center items-center lg:px-20 md:px-10 px-5"
  >
    <h1 class="lg:text-4xl md:text-3xl text-2xl font-bold text-primary uppercase">Nos Services</h1>
    <div
      class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 grid-flow-row gap-8 justify-center mt-12"
    >
      <OneService :id="1">
        <h5 class="text-base font-bold mb-2">Définition des exigences de sécurité</h5>
        <p>Identifier les exigences de securité necéssaires pour votre application.</p>
      </OneService>

      <OneService :id="2">
        <h5 class="text-base font-bold mb-2">Modélisation de la menace</h5>
        <p>
          Identifier et quantifier les risques associés à votre application web/mobile ou
          desktop.
        </p>
      </OneService>

      <OneService :id="3">
        <h5 class="text-base font-bold mb-2">Audit du code source de l’application</h5>
        <p>
          Analyse approfondie du code source de votre application pour identifier les
          problèmes potentiels.
        </p>
      </OneService>

      <OneService :id="4">
        <h5 class="text-base font-bold mb-2">Création des cas de test de sécurité</h5>
        <p>
          Création de scénarios de test pour évaluer la robustesse de votre application
          face aux menaces potentielles.
        </p>
      </OneService>

      <OneService :id="5">
        <h5 class="text-base font-bold mb-2">
          Enumération des vulnérabilités potentielles des conteneurs
        </h5>
        <p>
          Identifier les vulnérabilités potentielles dans les conteneurs utilisés par
          votre application, comme Docker et Vagrant, etc.
        </p>
      </OneService>

      <OneService :id="6">
        <h5 class="text-base font-bold mb-2">
          Analyse dynamique de la sécurité de l’application
        </h5>
        <p>
          Analyse dynamique de la sécurité de votre application pendant son exécution.
        </p>
      </OneService>

      <template v-if="canShow">
        <OneService :id="7">
          <h5 class="text-base font-bold mb-2">
            Mise en place et configuration des outils de sécurité applicatifs
          </h5>
          <p>
            <ul class="list-disc list-outside ml-5">
              <li>Pare-feu</li>
              <li>WAF</li>
              <li>DAST/IAST/SAST, etc</li>
            </ul>
          </p>
        </OneService>

        <OneService :id="8">
          <h5 class="text-base font-bold mb-2">Tests d'intrusion</h5>
          <p>
            Évaluation de la résilience de vos applications web, mobiles et desktop face aux
            attaques.
          </p>
        </OneService>

        <OneService :id="9">
          <h5 class="text-base font-bold mb-2">Gestion des vulnérabilités</h5>
          <p>
            Gestion des vulnérabilités que vous pourriez rencontrer afin de les anticiper et
            les prévenir.
          </p>
        </OneService>

        <OneService :id="10">
          <h5 class="text-base font-bold mb-2">Surveillance des systèmes d'information</h5>
          <p>
            Mise en place des alertes en temps réel pour réagir rapidement à toute anomalie.
          </p>
        </OneService>

        <OneService :id="11">
          <h5 class="text-base font-bold mb-2">Audit de maturité cybernétique</h5>
          <p>
            Évaluation des procédures de votre entreprise, le niveau de sécurité de vos
            infrastructures logicielles et déterminons votre niveau de maturité actuel.
          </p>
        </OneService>
      </template>
    </div>
    <button @click="canShow=!canShow" class="uppercase font-bold py-3 px-10 rounded-md bg-primary mt-8 text-white">{{ canShow ? "Voir Moins" : "Voir Plus" }}</button>
  </section>
  <section class="md:mt-20 mt-16 w-full flex flex-col justify-center items-center lg:px-20 md:px-10 px-5">
    <h1 class="uppercase font-bold text-white py-4 w-full lg:text-4xl md:text-3xl text-2xl text-center" style="background-color: #427E7E;">Nos Offres</h1>
  <div id="trust" class="md:h-[40px] h-[10px] w-full"></div>
    <div class="mt-12">
      <div>
        <h2 class="uppercase font-bold text-2xl mb-3 pb-5 after:content-['']  md:after:h-[9px] after:h-[7px] after:w-[60px] after:block after:mt-3 barre-decoration">Pack “Trust”</h2>
        <p class="text-justify">Les codes renferment naturellement des failles et des vulnérabilités qui pourraient être exploitées à des fins malveillantes par des cybercriminels. Même les développeurs les plus compétents ne peuvent échapper à cette réalité. La méthode la plus fiable pour réduire ces failles consiste à intégrer la sécurité dès les premières étapes du projet jusqu'à sa finalisation. Le Pack Trust vise à sécuriser vos applications tout au long du cycle de développement (SDLC). Grâce à cette solution, votre application est protégée contre divers types d'attaques. Ce pack jouit d'une grande popularité en raison de la richesse, de l'exhaustivité et de la complétude de ses services. En choisissant ce Pack, nous collaborons avec votre équipe interne dès la phase de planification jusqu'à la phase de maintenance, garantissant ainsi que la sécurité est intégrée à l'ensemble du processus, de bout en bout.</p>
        <div class="text-center w-full mt-5">
          <img src="../assets/img/software-dev-life-cycle.png" alt="" class="d-block bg-contain bg-center md:w-96 w-64 h-auto mx-auto" />
        </div>
      </div>
      <div class="mt-10 p-0 w-full rounded-lg shadow-md">
        <h1 class="uppercase font-bold text-white py-4 w-full text-xl text-center shadow-md rounded-t-lg outline outline-gray-300 outline-4" style="background-color: #265555;">Avantages pour vous</h1>
        <div class="py-3 lg:px-16 md:px-10 px-5">
          <ul class="list-outside">
            <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;Solution la plus économique et la plus valorisée;</li>
            <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;Implication de nos experts end-to-end sur l’ensemble du cycle de vie du produit;</li>
            <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;Réduction maximale des risques;</li>
            <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;Renforcement global de la sécurité de votre application;</li>
            <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;Conformité aux normes de sécurité les plus élevées;</li>
            <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;Protection renforcée des données sensibles.</li>
          </ul>
        </div>
      </div>
    </div>
  
    <div id="pick" class="md:h-[40px] h-[10px] w-full"></div>
    <div class="mt-12">
      <div>
        <h2 class="uppercase font-bold text-2xl mb-3 pb-5 after:content-['']  md:after:h-[9px] after:h-[7px] after:w-[60px] after:block after:mt-3 barre-decoration">Pack “Pick & Choose”</h2>
        <p class="text-justify mb-3">Nous sommes pleinement conscients de l'importance cruciale de vous accorder une autonomie totale et surtout, une flexibilité dans la sélection des services en fonction de vos besoins spécifiques. C'est dans cette optique que le Pack <b>Pick & Choose</b> est mis à votre disposition.</p>
        <p class="font-bold mb-3">Choisissez un ou plusieurs services parmi ceux-ci :</p>
        <div>
          <ul class="list-decimal list-outside ml-6">
            <li class="md:mb-1 mb-3">Définition des exigences de sécurité;</li>
            <li class="md:mb-1 mb-3">Threat Modeling (Modélisation de la menace);</li>
            <li class="md:mb-1 mb-3">Audit du code source de l’application;</li>
            <li class="md:mb-1 mb-3">Création des cas de test de sécurité;</li>
            <li class="md:mb-1 mb-3">Footprinting (énumération) des vulnérabilités potentielles des conteneurs;</li>
            <li class="md:mb-1 mb-3">Analyse dynamique de la sécurité de l’application (DAST);</li>
            <li class="md:mb-1 mb-3">Mise en place et configuration des outils de sécurité applicatifs;</li>
            <li class="md:mb-1 mb-3">Tests d'intrusion;</li>
            <li class="md:mb-1 mb-3">Gestion des vulnérabilités;</li>
            <li class="md:mb-1 mb-3">Surveillance des systèmes d'information;</li>
            <li>Audit de maturité cybernétique.</li>
          </ul>
        </div>
      </div>
      <div class="mt-10 p-0 w-full rounded-lg shadow-md">
        <h1 class="uppercase font-bold text-white py-4 w-full text-xl text-center shadow-md rounded-t-lg outline outline-gray-300 outline-4" style="background-color: #265555;">Avantages pour vous</h1>
        <div class="py-3 lg:px-16 md:px-10 px-5">
          <ul class="list-outside">
            <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;Solution flexible qui répond à vos besoins individuels;</li>
            <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;Gestion autonome de vos dépenses;</li>
            <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;Protection renforcée des données sensibles.</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="md:mt-20 mt-16 w-full flex flex-col justify-center items-center lg:px-20 md:px-10 px-5">
    <h1 class="lg:text-4xl md:text-3xl text-2xl font-bold text-primary uppercase">POURQUOI NOUS CHOISIR ?</h1>
    <div class="w-full mt-8">
      <ul class="list-outside ml-3">
        <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;Nous intervenons à chaque étape du cycle de vie du projet (SDLC);</li>
        <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;Vous avez la possibilité d'accroître la visibilité de votre entreprise grâce à cette couche de sécurité que vous offrez;</li>
        <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;La fidélisation de votre clientèle repose non seulement sur la qualité du service, mais également sur un environnement sécurisé qui inspire confiance;</li>
        <li class="md:mb-1 mb-3"><i class="fa-solid fa-circle-check text-primary"></i>&nbsp;En déléguant les risques de sécurité liés à votre produit à notre responsabilité, vous vous libérez de cette charge.</li>
      </ul>
    </div>
  </section>
  <div id="operons" ref="operons" class="h-[10px] w-full"></div>
  <section class="md:my-20 my-16 w-full flex flex-col justify-center items-center lg:px-20 md:px-10 px-5">
    <h1 class="lg:text-4xl md:text-3xl text-2xl font-bold text-primary uppercase">COMMENT OPERONS NOUS ?</h1>
    <p class="mt-8">
      Nous réalisons des tests et des revues de code tout au long du cycle de vie du projet, assurant un suivi continu pour garantir la mise en oeuvre de toutes les recommandations formulées. Voici les étapes que nous effectuons tout au long du cycle :
    </p>
    <table class=" w-full mt-8 table-auto border-collapse border border-black">
      <tbody>
        <tr>
          <td class="border border-black p-0">
            <OneSummary title="Planification(planning)">
              Identification des ressources nécessaires et estimation de la durée du projet.
            </OneSummary>
          </td>
        </tr>
        <tr>
          <td class="border border-black p-0">
            <OneSummary title="Exigences(requirements)">
              Documentation des mesures de sécurité nécessaires pour une application sécurisée.
            </OneSummary>
          </td>
        </tr>
        <tr>
          <td class="border border-black p-0">
            <OneSummary title="Design">
              Modélisation de l'architecture de l'application pour identifier les points de menace, revue et commentaires sur l'architecture de l'application (Diagramme des classes, etc.), et Vérification de la prise en compte des exigences définies dans l'architecture de l'application.
            </OneSummary>
          </td>
        </tr>
        <tr>
          <td class="border border-black p-0">
            <OneSummary title="Implémentation">
              En cas d'utilisation du CI/CD, nous intégrons l'ensemble de nos outils de sécurité dans la pipeline. Pour les entreprises ne suivant pas cette méthodologie, nous procédons aux tests étape par étape. Dans les deux cas, des interventions manuelles demeurent nécessaires.
            </OneSummary>
          </td>
        </tr>
        <tr>
          <td class="border border-black p-0">
            <OneSummary title="Test et intégration">
              Identifier les vulnérabilités potentielles susceptibles de compromettre la sécurité de votre système, rédiger des scénarios de tests de sécurité spécifiques, documenter et analyser chaque test réalisé afin de déterminer les actions à entreprendre.
            </OneSummary>
          </td>
        </tr>
        <tr>
          <td class="border border-black p-0">
            <OneSummary title="Déploiement">
            Effectuer une analyse de vulnérabilité sur vos divers conteneurs (docker, vagrant, etc.), suivi d'une analyse dynamique de votre code (DAST).
            </OneSummary>
          </td>
        </tr>
        <tr>
          <td class="border border-black p-0">
            <OneSummary title="Maintenance">
              Adopter une perspective d'attaquant pour identifier les éventuelles
              vulnérabilités résiduelles depuis l'extérieur par le biais de Test en Black-box ou
              Test en Gray-box. Un rapport détaillé des tests d'intrusion sera fourni, incluant une
              analyse approfondie des vulnérabilités détectées et des recommandations
              spécifiques pour les corriger. Nous collaborerons étroitement avec votre équipe
              pour nous assurer que les correctifs nécessaires soient appliqués de manière
              opportune et mettrons en place un plan de gestion des vulnérabilités à long terme.
            </OneSummary>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
  
  <FooterComponent/>
</template>

<style scoped>

@media (min-width: 1024px) { 
  .text-secondary {
    color:  #427e7e !important;
  }

  .bg-chocolate {
    background-color: #dddcda
  }

 }
.text-primary {
  color: #00b7b7;
}

.bg-primary {
  background-color: #00b7b7;
}

.border-primary {
  border-color: #00b7b7;
}

.barre-decoration::after {
    background-color: #427e7e;
}
</style>
