<template class="max-w-screen">
    <div class="flex font-montserrat flex-col justify-center items-center">
    <HeaderComponent/>
    <div id="top" class="lg:h-[80px] h-[50px]"></div>
    <div class="w-full md:h-[400px] h-[300px] mb-[50px] relative flex justify-center items-center">
        <div class="absolute w-full h-full top-0 left-0 right-0">
            <img src="../assets/img/Politique.jpg" alt="" class="w-full h-full object-cover">
        </div>
        <div class="absolute w-full h-full top-0 left-0 right-0 flex justify-center px-[20px] items-center bg-blue-500/20">
            <h1 class="md:text-5xl font-bold text-white text-2xl text-center">Politique de confidentialité</h1>
        </div>
    </div>
    <div class="w-full container mb-[100px] px-[10px] max-w-[1024px]">
        <h1 class="text-blue-500 font-bold text-xl my-[20px]">Introduction</h1>
        <p class="text-gray-700">
            <span class="font-bold"> IronSecur </span> s'engage à protéger la confidentialité et la sécurité de vos données
            personnelles. Cette politique de confidentialité explique quelles informations nous
            collectons sur vous, comment nous les utilisons et les partageons, et vos choix
            concernant la manière dont nous traitons vos données
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]">Informations collectées</h1>
        <p class="text-gray-700">
            Lorsque vous visitez notre site Web, <a href="https://ironsecur.com/" class="text-blue-500 hover:underline">https://ironsecur.com/</a>, nous pouvons collecter
            les informations suivantes :
        </p>
        <ul class="my-[10px] text-gray-700">
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b>Informations que vous nous fournissez: </b> Cela inclut les informations que
                vous saisissez dans nos formulaires, telles que votre nom, votre adresse
                e-mail, votre numéro de téléphone et votre message.
            </li>
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b>Informations de navigation: </b>  Nous pouvons collecter des informations sur
                votre ordinateur ou appareil mobile, telles que votre adresse IP, le type de
                navigateur et de système d'exploitation que vous utilisez, et les pages que
                vous visitez sur notre site Web.
            </li>
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b>Cookies et technologies similaires: </b> Nous utilisons des cookies et des
                technologies similaires pour collecter des informations sur votre utilisation de
                notre site Web. Ces technologies nous permettent de stocker vos
                préférences, de vous reconnaître lorsque vous revenez sur notre site Web et
                de vous fournir des publicités personnalisées.
            </li>
        </ul>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]">Utilisation de vos informations</h1>
        <p class="text-gray-700">
            Nous utilisons les informations que nous recueillons à votre sujet pour :
        </p>
        <ul class="my-[10px] text-gray-700">
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b>Fournir et améliorer nos services: </b> Nous utilisons vos informations pour
                vous fournir les services que vous avez demandés, tels que l'envoi
                d'informations sur nos produits et services.
            </li>
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b>Communiquer avec vous: </b>  Nous pouvons utiliser vos informations pour vous
                contacter par e-mail, téléphone ou courrier postal pour vous fournir des
                informations sur nos produits et services, ou pour répondre à vos questions.
            </li>
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b>Améliorer notre site Web: </b> Nous utilisons les informations que nous
                recueillons pour améliorer l'expérience utilisateur de notre site Web.
            </li>
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b>Sécurité et protection contre la fraude: </b> Nous utilisons vos informations
                pour protéger notre site Web contre la fraude et les abus.
            </li>
        </ul>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]">Partage de vos informations</h1>
        <p class="text-gray-700">
            Nous ne partageons pas vos informations personnelles avec des tiers sans votre consentement, sauf dans les cas suivants :
        </p>
        <ul class="my-[10px] text-gray-700">
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b> Prestataires de services: </b>  Nous pouvons partager vos informations avec des
                prestataires de services qui nous aident à fournir nos services, tels que des
                fournisseurs d'hébergement Web et des services de marketing par e-mail.
            </li>
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b>Obligations légales: </b>   Nous pouvons divulguer vos informations si la loi l'exige
                ou si nous pensons de bonne foi que la divulgation est nécessaire pour
                protéger nos droits ou la sécurité de nos utilisateurs.
            </li>
        </ul>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]"> Vos choix</h1>
        <p class="text-gray-700">
            Vous avez le contrôle de vos données personnelles. Vous pouvez choisir de :
        </p>
        <ul class="my-[10px] text-gray-700">
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b>Accéder à vos informations: </b> Vous pouvez nous contacter pour demander
                une copie des informations que nous détenons à votre sujet.
            </li>
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b> Corriger vos informations: </b>  Vous pouvez nous contacter pour corriger ou
                mettre à jour les informations que nous détenons à votre sujet.
            </li>
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b>Supprimer vos informations: </b> Vous pouvez nous contacter pour demander
                la suppression de vos informations.
            </li>
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                <b>Désactiver les cookies: </b> Vous pouvez désactiver les cookies dans votre 
                navigateur Web.
            </li>
        </ul>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]"> Conformité aux lois en vigueur</h1>
        <p class="text-gray-700">
            IronSecur s'engage à respecter toutes les lois en vigueur au Bénin, notamment la loi
            n° 2009-09 portant protection des données à caractère personnel en République du
            Bénin et la loi n° 2017-20 portant code du numérique en République du Bénin.
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]"> Contact</h1>
        <p class="text-gray-700">
            Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, veuillez nous contacter à l'adresse suivante : <a href="mailto:service.client@ironsecur.com" class="text-blue-500 hover:underline">service.client@ironsecur.com</a>
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]"> Modifications de la politique de confidentialité</h1>
        <p class="text-gray-700">
            Nous pouvons modifier cette politique de confidentialité de temps à autre. La version la plus récente de la politique de confidentialité sera toujours disponible sur notre site Web.
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]"> Date d'entrée en vigueur</h1>
        <p class="text-gray-700">
            Cette politique de confidentialité est entrée en vigueur le 11 février 2024
        </p>
    </div>
    <FooterComponent/>
    </div>
  </template>
  
  <script setup lang="ts">
  import HeaderComponent from '../components/HeaderComponent.vue'
  import FooterComponent from '@/components/FooterComponent.vue';
  import { ref, onMounted } from 'vue'
  
  const pageTitle = ref('IronSecur'); 
  
  onMounted(() => {
      document.title = pageTitle.value; 
  });
  
  </script>
  
  <style>
  
  .parent::-webkit-scrollbar {
    width: 0%;
  }
  </style>