<template>
    <div class="w-full mb-[50px] h-full font-montserrat relative">
        <headerComponent/>
        <div class="relative w-full">
            <div class="p-[20px] relative bg-gray-50 min-h-screen top-[60px]">
                <h1 class="text-black font-bold py-[20px] md:py-[40px] text-center">Home / Dashboard</h1>
                <div class="w-full relative justify-center md:justify-start flex items-center flex-wrap">
                  <!--
                    <div class="w-[250px] h-[150px] flex justify-between items-center py-[15px] px-[20px] bg-cyan-500/20 rounded-[10px] border-none m-[20px]">
                        <div>
                            <h1 class="text-black font-bold text-2xl ">Articles postés</h1>
                            <span class="text-[orange]/80 text-4xl">{{ userPosts }}</span>
                        </div>
                        <div>
                            <i class="fa-solid fa-file-pen text-[orange]/80 text-5xl"></i>
                        </div>
                    </div>
                  -->
                    <div class="w-[250px] h-[150px] flex justify-between items-center py-[15px] px-[20px] bg-cyan-500/20 rounded-[10px] border-none m-[20px]">
                        <div>
                            <h1 class="text-black font-bold text-2xl ">Total articles</h1>
                            <span class="text-[orange]/80 text-4xl"> {{ totalPosts }} </span>
                        </div>
                        <div>
                            <i class="fa-solid fa-user text-[orange]/80 text-5xl"></i>
                        </div>
                    </div>
                    <div v-if="isSuperUser" class="w-[250px] h-[150px] flex justify-center bg-cyan-600 items-center py-[15px] px-[20px] shadow-4xl rounded-[10px] border-none m-[20px]">
                      <button @click="exportFile('subscribers')" class="flex flex-col justify-center items-center p-[5px] bg-cyan-600 text-white text-sm font-semibold rounded-[5px]">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256">
                        <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(8.53333,8.53333)"><path d="M15,3c-0.13457,0.00082 -0.26871,0.01521 -0.40039,0.04297l-0.00195,-0.00195l-9.96875,1.99414l-0.00195,0.00195c-0.94311,0.17905 -1.62599,1.00293 -1.62695,1.96289v16c0.00021,0.9613 0.68429,1.78648 1.62891,1.96484l9.96875,1.99414c0.13238,0.02723 0.26719,0.04097 0.40234,0.04102c1.10457,0 2,-0.89543 2,-2v-20c0,-1.10457 -0.89543,-2 -2,-2zM19,5v3h2v2h-2v2h2v2h-2v2h2v2h-2v2h2v2h-2v3h6c1.105,0 2,-0.895 2,-2v-16c0,-1.105 -0.895,-2 -2,-2zM23,8h1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1h-1zM6.18555,10h2.40234l1.24414,2.99023c0.101,0.244 0.18177,0.52666 0.25977,0.84766h0.0332c0.045,-0.193 0.13353,-0.48609 0.26953,-0.87109l1.39063,-2.9668h2.1875l-2.61328,4.95508l2.69141,5.04297h-2.33398l-1.50391,-3.25781c-0.057,-0.115 -0.12369,-0.34697 -0.17969,-0.66797h-0.02148c-0.034,0.154 -0.10113,0.38631 -0.20313,0.69531l-1.51367,3.23242h-2.3457l2.7832,-5.00586zM23,12h1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1h-1zM23,16h1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1h-1zM23,20h1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1h-1z"></path></g></g>
                        </svg>
                        Export Newsletter
                      </button>
                    </div>

                    <div v-if="isSuperUser" class="w-[250px] h-[150px] flex justify-center bg-cyan-600 items-center py-[15px] px-[20px] shadow-4xl rounded-[10px] border-none m-[20px]">
                      <button @click="exportFile('comments')" class="flex flex-col justify-center items-center p-[5px] bg-cyan-600 text-white text-sm font-semibold rounded-[5px]">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256">
                        <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(8.53333,8.53333)"><path d="M15,3c-0.13457,0.00082 -0.26871,0.01521 -0.40039,0.04297l-0.00195,-0.00195l-9.96875,1.99414l-0.00195,0.00195c-0.94311,0.17905 -1.62599,1.00293 -1.62695,1.96289v16c0.00021,0.9613 0.68429,1.78648 1.62891,1.96484l9.96875,1.99414c0.13238,0.02723 0.26719,0.04097 0.40234,0.04102c1.10457,0 2,-0.89543 2,-2v-20c0,-1.10457 -0.89543,-2 -2,-2zM19,5v3h2v2h-2v2h2v2h-2v2h2v2h-2v2h2v2h-2v3h6c1.105,0 2,-0.895 2,-2v-16c0,-1.105 -0.895,-2 -2,-2zM23,8h1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1h-1zM6.18555,10h2.40234l1.24414,2.99023c0.101,0.244 0.18177,0.52666 0.25977,0.84766h0.0332c0.045,-0.193 0.13353,-0.48609 0.26953,-0.87109l1.39063,-2.9668h2.1875l-2.61328,4.95508l2.69141,5.04297h-2.33398l-1.50391,-3.25781c-0.057,-0.115 -0.12369,-0.34697 -0.17969,-0.66797h-0.02148c-0.034,0.154 -0.10113,0.38631 -0.20313,0.69531l-1.51367,3.23242h-2.3457l2.7832,-5.00586zM23,12h1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1h-1zM23,16h1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1h-1zM23,20h1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1h-1z"></path></g></g>
                        </svg>
                        Export Comments
                      </button>
                    </div>

                    <div v-if="isSuperUser" class="w-[250px] h-[150px] flex justify-center bg-cyan-600 items-center py-[15px] px-[20px] shadow-4xl rounded-[10px] border-none m-[20px]">
                      <button @click="exportFile('training-prospects')" class="flex flex-col justify-center items-center p-[5px] bg-cyan-600 text-white text-sm font-semibold rounded-[5px]">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256">
                        <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(8.53333,8.53333)"><path d="M15,3c-0.13457,0.00082 -0.26871,0.01521 -0.40039,0.04297l-0.00195,-0.00195l-9.96875,1.99414l-0.00195,0.00195c-0.94311,0.17905 -1.62599,1.00293 -1.62695,1.96289v16c0.00021,0.9613 0.68429,1.78648 1.62891,1.96484l9.96875,1.99414c0.13238,0.02723 0.26719,0.04097 0.40234,0.04102c1.10457,0 2,-0.89543 2,-2v-20c0,-1.10457 -0.89543,-2 -2,-2zM19,5v3h2v2h-2v2h2v2h-2v2h2v2h-2v2h2v2h-2v3h6c1.105,0 2,-0.895 2,-2v-16c0,-1.105 -0.895,-2 -2,-2zM23,8h1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1h-1zM6.18555,10h2.40234l1.24414,2.99023c0.101,0.244 0.18177,0.52666 0.25977,0.84766h0.0332c0.045,-0.193 0.13353,-0.48609 0.26953,-0.87109l1.39063,-2.9668h2.1875l-2.61328,4.95508l2.69141,5.04297h-2.33398l-1.50391,-3.25781c-0.057,-0.115 -0.12369,-0.34697 -0.17969,-0.66797h-0.02148c-0.034,0.154 -0.10113,0.38631 -0.20313,0.69531l-1.51367,3.23242h-2.3457l2.7832,-5.00586zM23,12h1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1h-1zM23,16h1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1h-1zM23,20h1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1h-1z"></path></g></g>
                        </svg>
                        Export Training Prospects
                      </button>
                    </div>

                </div>
                <div class="w-full relative m-[20px]">
                    <h1 class="text-2xl">Derniers articles</h1>
                    <div class="w-full relative">
                      <div v-for="(article, index) in publishedArticles" :key="index" class="flex flex-wrap my-[10px]">
                        <div v-if="article.is_published" class="flex">
                          <img :src="article.image" class="shadow-2xl w-[170px] h-[100px] rounded-[10px]" :alt="article.title">
                          <div class="mx-[20px] flex flex-col justify-between h-full">
                            <div class="block">
                              <h1 class="font-bold">{{ article.title }}</h1>
                              <span class="text-gray-700 text-sm">{{ article.author_username }}</span>
                            </div>
                            <router-link :to="{ name: 'article', params: { id: article.id }}">
                                <span class="cursor-pointer text-sm text-[orange]">En savoir plus <i class="py-[5px] pl-[5px] rounded-[50%] bg-orange-50 mx-[5px] fa-solid fa-chevron-right text-xs"></i></span>
                            </router-link>
                        
                          </div>
                        </div>
                      </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="w-full pb-[10px] border-t border-gray-400 pt-[50px] flex justify-center items-center">
        <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
    </div>
</template>

<script setup lang="ts">
import headerComponent from '../components/headerComponentAdmin.vue'
import { ref, onMounted } from 'vue';
import axios from 'axios';
const pageTitle = ref('Dashboard')
const errorMessage = ref('')
import axiosInstance from '@/plugins/axios';
import * as XLSX from 'xlsx'; 
onMounted(() => {
    document.title = pageTitle.value;
});

let isSuperUser = ref(false);
const totalPosts = ref(0); 
const userPosts = ref(0);

let user = ref({});

import JSZip from 'jszip';

const exportFile = async (basename="comments") => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Authentication token not found');
      return;
    }

    // Effectuer la requête vers l'endpoint d'exportation des commentaires
    const response = await axiosInstance.get(`/${basename}/export/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'arraybuffer', // Indiquer le type de réponse comme étant un tableau d'octets (arraybuffer)
    });

    // Créer un Blob à partir des données de réponse
    const blob = new Blob([response.data], { type: response.headers['content-type'] });

    // Créer un URL objet à partir du Blob
    const url = window.URL.createObjectURL(blob);

    // Créer un lien pour télécharger le fichier
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${basename == 'training-prospects' ? 'training_prospects' : basename}.xlsx`); // Nom du fichier Excel par défaut
    document.body.appendChild(link);
    link.click();

    // Nettoyer après le téléchargement
    document.body.removeChild(link);

  } catch (error) {
    console.error(`Error exporting ${basename}:`, error);
  }
}


const fetchUserProfile = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const response = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    user.value = response.data;
    isSuperUser.value = response.data.is_staff;
  } catch (error) {
    console.error(error);
  }
};
onMounted(() => {
  fetchUserProfile();
});

const fetchUserId = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const response = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      const userId = response.data.id;
      fetchUserPosts(userId); 
      fetchTotalPosts();
    }
  } catch (error) {
  }
};

const fetchTotalPosts = async () => {
  try {
    let totalArticleCount = 0;
    let nextUrl = '/articles/';

    while (nextUrl) {
      const token = localStorage.getItem('token');
      if (!token) {
        break;
      }

      const response = await axiosInstance.get(nextUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const articles = response.data.results;
        totalArticleCount += articles.length;

        nextUrl = response.data.next; // Récupérer l'URL de la page suivante

        if (!nextUrl) {
          break; // Si la prochaine URL est null, cela signifie qu'il n'y a pas de page suivante
        }
      } else {
        break; // Sortir de la boucle si la requête échoue
      }
    }

    totalPosts.value = totalArticleCount;
  } catch (error) {
    console.error('Erreur lors du chargement du nombre total d\'articles', error);
  }
};

fetchTotalPosts();

const fetchUserPosts = async (userId: any) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    let totalArticles = 0;

    // Récupérer le nombre total d'articles de l'utilisateur
    const totalResponse = await axiosInstance.get(`/articles/?author=${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (totalResponse.status === 200) {
      totalArticles = totalResponse.data.count;
    }

    // Récupérer tous les articles de l'utilisateur
    const response = await axiosInstance.get(`/articles/?author=${userId}&limit=${totalArticles}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      // Utilisez la propriété 'count' pour obtenir le nombre total d'articles
      userPosts.value = response.data.results.length;
    }
  } catch (error) {
    console.error('Erreur lors du chargement du nombre total d\'articles de l\'utilisateur', error);
  }
};




fetchUserId();

interface Article {
  id: number;
  title: string;
  content: string;
  image: string;
  date: string;
  source: string;
  is_published: boolean;
  author: number;
}

const publishedArticles = ref<Article[]>([]);

const loadPublishedArticles = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const response = await axiosInstance.get('/articles/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const allArticles = response.data.results;

      // Filtrer les articles avec is_publish = true
      const publishedSortedArticles = allArticles
        .filter(article => article.is_published).sort((a, b) => new Date(b.date) - new Date(a.date));

      // Afficher seulement les 4 derniers articles
      publishedArticles.value = publishedSortedArticles.slice(0, 4);
    }
  } catch (error) {
    console.error('Erreur lors du chargement des articles', error);
  }
};

onMounted(() => {
  fetchUserId();
  loadPublishedArticles();
});



</script>

<style>

</style>