import axios from 'axios';

const baseURL = {
    // serverURL: "http://127.0.0.1:8000/",
    serverURL: "https://websiteapi.ironsecur.com",
};

const axiosInstance = axios.create({
    baseURL: baseURL.serverURL,
    timeout: 100000000000,
    headers: {
        common: {
            "Content-Type": 'multipart/form-data',
        }
    }
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        // Tout va bien, retourne la réponse
        return response;
    },
    (error) => {
        if (error.response) {
            // Gérer les erreurs HTTP spécifiques ici
            if (error.response.status === 401) {
                localStorage.clear();
            }
            // Autres codes d'erreur HTTP à gérer ici si nécessaire
        } else if (error.request) {
            // Gérer les erreurs réseau ici
            console.error('Problème de réseau:', error.request);
            // Peut-être rediriger vers une page d'erreur réseau
        } else {
            console.error('Erreur inattendue:', error.message);
        }
        return Promise.reject(error);
    }
);

// Utilisation dans tes composants Vue
export default axiosInstance;
