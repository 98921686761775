<template>
    <div class="my-[10px]">
      <textarea v-model="responseContent" placeholder="Votre réponse" class="w-full border outline-none p-[10px] resize-none md:h-[150px] h-[75px] rounded-[5px] focus:border-blue-300"></textarea>
      <button @click="submitResponse" class="text-sm font-bold text-white bg-cyan-500 p-[10px]">Envoyer</button>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref } from 'vue';
  import axios from 'axios';
  import axiosInstance from '@/plugins/axios';
  
  const props = defineProps<{
    commentId: number;
    username: string;
  }>();
  
  const responseContent = ref('');
  
  const submitResponse = async () => {
    try {
      const response = await axiosInstance.post(`/comments/${props.commentId}/responses/`, {
        content: responseContent.value,
        username: "IronSecur",
        comment: props.commentId
      });
      
      responseContent.value = '';
      window.location.reload();
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la réponse :', error);
    }
  };
  </script>
  
  <style>
  /* Styles CSS */
  </style>
  