<template>
    <div v-if="successMessage" class="bg-green-600 border border-green-600 my-[10px] text-white text-center fixed top-[30px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[550px] w-[90%] p-[20px] ">{{ successMessage }}</div>
    <div v-if="errorMessage" class="bg-red-600 border border-red-600 my-[10px] text-white text-center fixed top-[30px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[550px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
    <div class=" font-montserrat flex justify-center items-center relative min-w-screen min-h-screen py-[50px] px-[10px] bg-gray-50">
        <div class="w-full flex justify-center items-center flex-col relative py-[50px] md:px-[20px] px-[10px] max-w-[600px] border-2 border-gray-300 h-full">
            <h1 class="text-xl mb-[20px] text-center font-bold">Réinitialiser votre mot de passe</h1>
            <form class="relative w-full" @submit.prevent="requestPasswordReset">
                <div class=" py-[15px] relative block">
                    <label for="email" class="uppercase">Email</label>
                    <input v-model="email" type="email" id="email" class="focus:border-gray-600 border p-[10px] border-gray-300 outline-none w-full" placeholder="ironsecur@gmail.com">
                </div>
                <div class="pt-[25px] relative block">
                    <button type="submit" :disabled="loading" class="p-[10px] bg-cyan-500 rounded-[5px] font-bold text-sm hover:bg-cyan-600 duration-100 cursor-pointer text-white outline-none w-full">
                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                        <span v-else>Suivant</span>
                    </button>
                </div>
                
            </form>
        </div>
        
    </div>
    <div class="w-full border-t border-gray-400 py-[50px] bg-gray-50 flex justify-center items-center">
        <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
const pageTitle = ref('Reset Password | IronSecur');
import { useRouter } from 'vue-router';
const router = useRouter();
import axiosInstance from '@/plugins/axios';
onMounted(() => {
    document.title = pageTitle.value;
});

const email = ref('');
const loading = ref(false)
const errorMessage = ref('');
const successMessage = ref('')

const requestPasswordReset = async () => {
  try {
    loading.value = true;
    const response = await axiosInstance.post('/auth/users/reset_password/', {
      email: email.value
    });
    successMessage.value = 'Un mail comportant un lien de réinitialisation vous sera envoyé ! Cliquez sur le lien pour poursuivre le processus. Merci !!'
    setTimeout(() => {
      router.push('/log'); 
    }, 10000);
  } catch (error) {
    errorMessage.value = 'Erreur lors de la demande de réinitialisation';
    hideErrorMessageAfterDelay();
  } finally {
        loading.value = false; 
    }
};

const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 5000);
};
</script>

<style>

</style>