<template>
    <div class="w-full relative font-montserrat">
        <headerComponent/>
        <div v-if="errorMessage" class="bg-red-600 border border-red-600 my-[10px] text-white text-center fixed md:top-[80px] top-[60px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
        <div class="relative pt-[70px] w-full mb-[50px]">
          <div class="flex justify-end items-center">
            <div v-if="isNewRoute">
              <button :disabled="loading1" class="text-sm font-bold rounded-[5px] min-w-[60px] p-[10px] border border-gray-400 m-[10px] bg-cyan-800 text-white shadow-lg" @click="saveSubscribers">
                <i v-if="loading1" class="fas fa-spinner fa-spin"></i>
                <span v-else>Import Subscribers</span>
              </button>
            </div>
          </div>
    
            <div class="w-full relative py-[10px] flex justify-center items-center">
                <label>Please upload the Excel file containing the emails of users to add as subscribers.</label>
                <input type="file" @change="handleFileChange" class="m-[10px] max-w-[calc(100%-52%)] p-[10px]"> 
            </div>
        </div>
        <div class="w-full py-[20px] flex justify-center items-center">
            <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
        </div>
    </div>
</template>

<script setup lang="ts">
import headerComponent from '@/components/headerComponentAdmin.vue';
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import axiosInstance from '@/plugins/axios';


const loading = ref(false);
const loading1 = ref(false);
const loading2 = ref(false);
const router = useRouter();
const pageTitle = ref('Import subscribers');

const content = ref('');
const object = ref('');
const selectedFile = ref(null);
const errorMessage = ref("");

const isNewRoute = router.currentRoute.value.path === "/ImportSubscribers";

onMounted(() => {
    document.title = pageTitle.value;
});

const handleFileChange = (event: any) => {
  selectedFile.value = event.target.files[0];
};

let userId = ref(null);

const saveSubscribers = async () => {
  try {
    loading1.value = true;
    const token = localStorage.getItem('token');
    if (!token) return;


    const userResponse = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    userId.value = userResponse.data.id;

    const formData = new FormData();

    if (selectedFile.value) {
      formData.append('stylesheet', selectedFile.value);
    };

    const response = await axiosInstance.post('/subscribers/import/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    router.push('/dashboard');
  } catch (error) {
    console.log(error.response.data)
    if(error.response && error.response.status && error.response.status == 400) {
        if(error.response.data && error.response.data.stylesheet) {
          errorMessage.value = "uploaded file: " + error.response.data.stylesheet[0]
        } else {
          errorMessage.value = "Unknow error";
        }
    } else {
      errorMessage.value = 'Erreur lors de l\'importattion ou du sauvegarde des subscribers';
    }
    
    hideErrorMessageAfterDelay();
  } finally {
    loading1.value = false;
  }
};

const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 5000);
};

onMounted(() => {
  document.title = pageTitle.value;
});



</script>

<style>
    .ql-editor {
        height: 72vh;
    }

</style>