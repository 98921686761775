<template>
    <div class="flex flex-col justify-center font-montserrat items-center">
        <HeaderComponent/>
        <div class="container max-w-[1024px]">
            <div class="w-full relative flex justify-center items-center md:mt-[50px] mt-[10px]">
        <div class="container max-w-[1024px] relative w-full my-[50px]">
            <div class="w-full p-[10px] relative md:flex ">
                <div class="relative w-full text-3xl text-left flex">
                    <div class="text-blue-800 mb-[10px]">
                        <p class="md:text-left text-center">{{ article.title }}</p>
                    </div>
                </div>
                <!-- <div class="md:w-6/12 text-gray-600 w-full flex flex-col justify-center">
                    <p>
                        Les informations présentées dans cet article ont été recueillies 
                        à partir de <span class="font-bold text-blue-950 underline">{{ article.source }}</span>. Pour garantir 
                        l'exactitude et la crédibilité des données fournies, nous nous 
                        sommes appuyés sur une sélection rigoureuse de documents, d'articles 
                        académiques, de publications spécialisées et de ressources en ligne 
                        provenant d'institutions renommées. 
                    </p>
                    <br>
                    <p>
                        Chaque source est minutieusement 
                        référencée et citée pour assurer la transparence et l'authenticité 
                        des informations partagées.
                    </p>

                </div> -->
            </div>
            <div class="w-full overflow-hidden max-h-[300px] relative p-[10px]">
                <img :src="article.image" alt="" class="w-full max-h-[400px]">
            </div>
            <div class="w-full relative p-[10px] overflow-x-hidden max-w-screen h-auto">
                <div v-html="article.content" class="max-w-screen ql-editor h-auto"></div>
            </div>
            <div class="w-full relative p-[10px]">
                <div class="w-full relative pt-[10px] pb-[20px] border-b border-gray-500  flex justify-between items-center">
                    <div>{{ article.comments.length }}  Comments</div>
                    <div class="flex justify-center items-center">
                      <p class="mx-[10px]">{{ article.likes.length }}</p>
                      <div v-if="article.is_liked_by_current_user">
                        <i class="text-red-500 text-2xl cursor-pointer fa-solid fa-heart" @click="unLike"></i>
                      </div>
                      <div v-else>
                        <i class="fa-regular fa-heart text-2xl cursor-pointer text-black" @click="Like"></i>
                      </div>
                    </div>
                    
                </div>

                <div class="my-[20px] w-full relative">
                    

                    <CommentComponent/>
                    <One_comment :isSuperUser="isSuperUser" :latestComment="latestComment" :allComments="sortedComments"/>
                    <div v-if="latestComment" class="my-[20px] w-full relative">
                      <button v-if="allComments.length > 1" class="w-full p-[20px] font-bold rounded-[5px] bg-blue-500 text-white text-sm" @click="showAllComments">Load more comments</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
        </div>
    <FooterComponent/>
    </div>
  
</template>


<script setup lang="ts">
import HeaderComponent from '../components/HeaderComponent.vue';
import One_comment from '../components/One_comment.vue';
import CommentComponent from '../components/commentComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axiosInstance from '@/plugins/axios';
let isSuperUser = ref(false);

const router = useRouter();

const articleId = router.currentRoute.value.params.id;

interface Comment {
  id: number;
  content: string;
  article: number;
}

interface Article {
  id: number;
  comments: Comment[];
  author: string;
  title: string;
  content: string;
  image: string;
  date: string;
  source: string;
  is_published: boolean;
  likes: Like[];
  is_liked_by_current_user: boolean;
}


interface Like {
  id: number;
  ip_address: string;
  article: number;
}

const article = ref<Article>({
  id: 0,
  comments: [],
  author: '',
  title: '',
  content: '',
  image: '',
  date: '',
  source: '',
  is_published: false,
  likes: [],
  is_liked_by_current_user: false
});


function extractTextFromHTML(html: any) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.innerText || "";
}

const allComments = ref<Comment[]>([]);
const sortedComments = ref<Comment[]>([]);

const loadAllComments = async () => {
  try {
    const response = await axiosInstance.get(`/articles/${articleId}/`);
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const comments = response.data.comments || [];
      allComments.value = comments;
      sortedComments.value = comments.slice().sort((a, b) => b.id - a.id);
      latestComment.value = null;
    }
  } catch (error) {
    console.error('Erreur lors du chargement de tous les commentaires :', error);
  }
};


const showAllComments = async () => {
  await loadAllComments();
};

const textWithoutHTML = extractTextFromHTML(article.value.content);


const loadArticleDetails = async (id: any) => {
  try {
    const response = await axiosInstance.get(`/articles/${id}/`);
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const { title, author, content, image, date, source, is_published, comments, likes, is_liked_by_current_user } = response.data;
      article.value = {
        id,
        title,
        author,
        content,
        image,
        date,
        source,
        is_published,
        comments: comments || [],
        likes: likes || [],
        is_liked_by_current_user,
      };
    }
  } catch (error) {
    console.error('Erreur lors du chargement des détails de l\'article', error);
  }
};



const pageTitle = ref('IronSecur | Nom article');
const id = ref(''); 
onMounted(() => {
  const articleId = router.currentRoute.value.params.id;
  id.value = articleId;
  loadArticleDetails(articleId);
});

const Like = async () => {
  try {

    await axiosInstance.post(`/articles/${articleId}/likes/`);

    await loadArticleDetails(articleId);

  } catch (error) {
    console.error('Error toggling like:', error);
  }
};

const unLike = async () => {
  try {

    await axiosInstance.delete(`/articles/${articleId}/likes/${article.value.likes[0].id}/`);

    await loadArticleDetails(articleId);

  } catch (error) {
    console.error('Error toggling like:', error);
  }
};



const latestComment = ref<Comment | null>(null);

interface Comment {
  username: string;
  content: string;
  id: number;
}

const fetchComments = async () => {
  try {
    const response = await axiosInstance.get(`/articles/${articleId}/`);
    const comments: Comment[] = response.data.comments || [];
    allComments.value = comments;

    if (comments.length > 0) {
      latestComment.value = comments[comments.length - 1]; 
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des commentaires :', error);
  }
};


let user = ref({});

const fetchUserProfile = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const response = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    user.value = response.data;
    isSuperUser.value = response.data.is_staff;
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  fetchComments();
  fetchUserProfile();
});
</script>


<style>

</style>