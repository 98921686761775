<template>
    <div class="w-full font-montserrat relative">
        <headerComponent/>
        <div class="flex justify-center items-center z-30 flex-col fixed top-[70px] left-[50%] w-full translate-x-[-50%]">
            
            <div v-if="errorMessage" class="bg-red-500 border border-red-500 my-[10px] text-white text-center font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
            <div v-if="errorMessage1" class="bg-red-500 border border-red-500 my-[10px] text-white text-center font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ errorMessage1 }}</div>
            <div v-if="errorMessage2" class="bg-red-500 border border-red-500 my-[10px] text-white text-center font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ errorMessage2 }}</div>
            <div v-if="successMessage1" class="bg-green-600 border border-green-600 my-[10px] text-white text-center font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ successMessage1 }}</div>
            <div v-if="successMessage" class="bg-green-600 border border-green-600 my-[10px] text-white text-center font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ successMessage }}</div>
        </div>
        <div class="relative mb-[50px] w-full">
            <div class="py-[20px] flex flex-col items-center relative bg-gray-50 min-h-screen top-[60px]">
                <h1 class="text-black font-bold py-[20px] text-center">Home / Profil</h1>
                <h1 class="text-2xl uppercase font-bold text-center text-black py-[20px]">Profile</h1>
                <div class=" block md:flex justify-center w-[90%] py-[10px]">
                    <div class="md:w-[200px] p-[10px] w-full md:h-[200px] bg-white md:mx-[10px] flex flex-col justify-center items-center">
                        <img src="../assets/img/icon.jpeg" alt=""  class="w-[120px] h-[120px] rounded-[50%] border border-black">
                        <h1 class="uppercase my-[10px]"> {{ updatedUser.firstname }} {{ updatedUser.lastname }} </h1>
                    </div>
                    <div class="w-full md:my-[0px] my-[20px] md:w-[calc(100%-240px)] relative bg-white md:mx-[10px] px-[10px] py-[50px]">
                        <linkProfile/>
                        <div class="md:py-[40px] md:px-[20px] px-[10px] py-[30px]">
                            <form @submit.prevent="changePassword">
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">Ancien mot de passe</span>
                                    <div class="relative min-w-[45%] max-w-[50%]">
                                        <input :type="ashowPassword ? 'text' : 'password'" v-model="apassword" placeholder="Mot de passe" id="password" class="border border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full">
                                        <i class="password-toggle" :class="ashowPassword ? 'fa fa-eye text-sm absolute top-[10px] right-[10px]' : 'fa fa-eye-slash text-sm absolute top-[10px] right-[10px]'" @click="ashowPassword = !ashowPassword" ></i>
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">Nouveau mot de passe</span>
                                    <div class="relative min-w-[45%] max-w-[50%]">
                                        <input :type="nshowPassword ? 'text' : 'password'" v-model="npassword" placeholder="Mot de passe" id="password" class="border border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full">
                                        <i class="password-toggle" :class="nshowPassword ? 'fa fa-eye text-sm absolute top-[10px] right-[10px]' : 'fa fa-eye-slash text-sm absolute top-[10px] right-[10px]'" @click="nshowPassword = !nshowPassword" ></i>
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">Confirmer le nouveau mot de passe</span>
                                    <div class="relative min-w-[45%] max-w-[50%]">
                                        <input :type="cshowPassword ? 'text' : 'password'" v-model="cpassword" placeholder="Mot de passe" id="password" class="border border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full">
                                        <i class="password-toggle" :class="cshowPassword ? 'fa fa-eye text-sm absolute top-[10px] right-[10px]' : 'fa fa-eye-slash text-sm absolute top-[10px] right-[10px]'" @click="cshowPassword = !cshowPassword" ></i>
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-center">
                                    <button type="submit" :disabled="loading" class=" uppercase cursor-pointer font-bold bg-cyan-500 text-white outline-none rounded-[5px] py-[5px] px-[30px] w-auto">
                                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                                        <span v-else>Save</span>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                    
                    
                </div>
            </div>
        </div>
        <div class="w-full border-t border-gray-400 py-[50px] flex justify-center items-center">
            <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
        </div>
    </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import { ref, onMounted } from 'vue';
import headerComponent from '../components/headerComponentAdmin.vue';
import linkProfile from '../components/linkProfile.vue';
import { useRouter } from 'vue-router';
import axiosInstance from '@/plugins/axios';

const apassword = ref("");
const ashowPassword = ref(false);
const npassword = ref("");
const cpassword = ref("");
const nshowPassword = ref(false);
const cshowPassword = ref(false);
const pageTitle = ref('Profile password');
const errorMessage1 = ref('');
const errorMessage2 = ref('');
const successMessage1 = ref('');
const successMessage = ref('');
const errorMessage = ref('');
const updatedUser = ref({});
const router = useRouter();
const loading = ref(false);

onMounted(() => {
  document.title = pageTitle.value;
  fetchUserProfile();
});

const fetchUserProfile = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const response = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    updatedUser.value = { ...response.data }; 
  } catch (error) {
    console.error(error);
  }
};

const changePassword = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    // Vérification si les nouveaux mots de passe correspondent
    if (npassword.value !== cpassword.value) {
      errorMessage2.value = 'Les nouveaux mots de passe ne correspondent pas.';
        hideErrorMessageAfterDelay();
      return;
    }

    // Vérification de la longueur du nouveau mot de passe
    if (npassword.value.length < 8) {
      errorMessage1.value = 'Le nouveau mot de passe doit contenir au moins 8 caractères.';
        hideErrorMessageAfterDelay();
      return;
    }

    // Vérification du format du nouveau mot de passe (majuscules, minuscules, chiffres, caractères spéciaux)
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(npassword.value)) {
      errorMessage1.value = 'Le nouveau mot de passe doit contenir au moins 8 caractères avec majuscules, minuscules, chiffres et caractères spéciaux.';
        hideErrorMessageAfterDelay();
      return;
    }

    // Appel à l'API pour changer le mot de passe
    const response = await axiosInstance.post('/auth/users/set_password/',
      {
        current_password: apassword.value,
        new_password: npassword.value,
        re_new_password: cpassword.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
    // On successful password change
    successMessage1.value = 'Mot de passe changé avec succès.';
    successMessage.value = 'Vous serez déconnecté dans 5 secondes.';
    setTimeout(() => {
      localStorage.clear();
      router.push('/log');
    }, 5000);
  } catch (error: any) {
    console.error(error);

    // Handle error messages
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage.value = error.response.data.message;
        hideErrorMessageAfterDelay();
    } else {
      errorMessage.value = "Une erreur est survenue lors du changement de mot de passe.";
        hideErrorMessageAfterDelay();
    }
  } finally {
    loading.value = false;
  }
};
const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 5000);
  setTimeout(() => {
    errorMessage1.value = ''; 
  }, 5000);
};
</script>

<style>

</style>