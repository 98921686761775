<template>
    <div v-if="errorMessage" class="bg-red-600 border border-red-600 my-[10px] text-white text-center fixed md:top-[80px] top-[60px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[650px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
    <tr class="text-gray-600 flex relative text-sm bg-white border border-gray-400 p-[10px] w-full" v-for="article in props.articles">
        <td class="items-center p-[5px] flex w-[25px]"><input type="checkbox" name="article-checkbox-{{ article.id }}" id="article-checkbox-{{ article.id }}"></td>
        <td class="items-center p-[5px] flex w-[25px]">{{ article.id }}</td>
        <td class="items-center md:w-[calc(100%/6)] w-[calc(100%/3)] p-[5px] flex ">
            <router-link :to="{ name: 'article', params: { id: article.id }}" class="hover:text-black hover:underline duration-300">{{ article.title }}</router-link>
        </td>
        <td class="items-center md:w-[calc(100%/6)] w-[calc(100%/3)] p-[5px] flex overflow-x-auto flex-wrap">
            {{ article.author_username }}
        </td>
        <td class="items-center md:w-[calc(100%/6)] w-[calc(100%/3)] p-[5px] hidden  text-justify"> <div v-html="article.content"></div></td>
        <td class="items-center md:w-[calc(100%/6)] w-[calc(100%/3)] p-[5px] flex "><img v-bind:src="article.image" class="w-full" alt=""></td>
        <td class="items-center md:w-[calc(100%/6)] w-[calc(100%/3)] overflow-x-auto p-[5px] md:flex hidden ">{{ article.date }}</td>
        <td class="items-center md:w-[calc(100%/6)] w-[calc(100%/3)] p-[5px] overflow-x-auto md:flex hidden ">{{ article.source }}</td>
        <td class="items-center md:w-[calc(100%/6)] w-[calc(100%/3)] p-[5px] md:flex justify-center flex-wrap hidden ">
            <i class="fa-solid fa-trash-can bg-red-500 text-white p-[10px] rounded-[5px] mr-[5px] text-xl cursor-pointer" @click="confirmDelete(article)"></i>
            <i class="fa-solid fa-file-pen bg-blue-500 text-white p-[10px] rounded-[5px] ml-[5px] text-xl cursor-pointer" @click="confirmUpdate(article)"></i>
            <button v-if="!article.is_published" class="bg-green-600 p-[10px] rounded-[5px] m-[10px] text-white font-bold text-sm" @click="publishArticle(article.id)">Published</button>
        </td>
    </tr>

    <div v-if="showConfirmationModal" class="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
        <div class="bg-white p-8 rounded-lg">
          <h2 class="text-lg font-bold mb-4">Confirmez la suppression de l'article</h2>
          <p>ID : {{ selectedArticle.id }}</p>
          <p>Titre : {{ selectedArticle.title }}</p>
          <div class="flex justify-end mt-6">
            <button class="px-4 py-2 bg-red-500 text-white rounded mr-4" @click="deleteArticle">Supprimer</button>
            <button class="px-4 py-2 bg-gray-300 rounded" @click="cancelDelete">Annuler</button>
          </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import Article from '@/components/types/Article'
    import axios from 'axios';
    import { useRouter } from 'vue-router';
    const router = useRouter();
    import axiosInstance from '@/plugins/axios';
    
    const errorMessage = ref("");
    
    import { ref } from 'vue';
    const props = defineProps<{
        articles: Article[],
    }>();

    const publishArticle = async (newArticleId: number) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      const publishResponse = await axiosInstance.put(`/articles/${newArticleId}/publish/`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (publishResponse.status === 200 || publishResponse.status === 201 || publishResponse.status === 204 ) {
        window.location.reload();
      } else {
        console.error('Erreur lors de la publication de l\'article');
      }
    } catch (error) {
      console.error('Erreur lors de la publication de l\'article:', error);
    }
  };

  const showConfirmationModal = ref(false);
const selectedArticle = ref<Article | null>(null);

const confirmDelete = (article: Article) => {
  selectedArticle.value = article;
  showConfirmationModal.value = true;
};

const deleteArticle = async () => {
  if (!selectedArticle.value) return;

  try {
    const token = localStorage.getItem('token');
    if (!token) return;

    const response = await axiosInstance.delete(`/articles/${selectedArticle.value.id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200 || response.status === 204) {
      window.location.reload();
    }
  } catch (error) {
    errorMessage.value = 'Erreur lors de la suppression de l\'article: Vous n\'avez pas les autorisations requises';
    hideErrorMessageAfterDelay();
  } finally {
    selectedArticle.value = null;
    showConfirmationModal.value = false;
  }
};

const confirmUpdate = (article: Article) => {
  selectedArticle.value = article;
  router.push({ name: 'AddAnArticleWith', params: { id: article.id } });
};


const cancelDelete = () => {
  selectedArticle.value = null;
  showConfirmationModal.value = false;
};

const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 5000);
};
    


</script>

<style>

</style>