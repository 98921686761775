<template>
    <i
      class="cursor-pointer"
      :class="{
        'fa-regular fa-heart': !isLiked,
        'fa-solid fa-heart text-red-500': isLiked
      }"
      @click="toggleLike"
    ></i>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isLiked: false
      };
    },
    methods: {
      toggleLike() {
        this.isLiked = !this.isLiked;
      }
    }
  };
  </script>
  