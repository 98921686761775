<template>
    <div v-if="successMessage" class="bg-green-600 border border-green-600 my-[10px] text-white text-center fixed md:top-[80px] top-[60px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ successMessage }}</div>
    <div v-if="errorMessage" class="bg-red-600 border border-red-600 my-[10px] text-white text-center fixed md:top-[80px] top-[60px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
  <div class="w-full max-w-[700px] relative flex justify-center p-[20px] items-center ">
    <form class="w-full relative"  @submit.prevent="contact">
        <div class="flex md:justify-between justify-center flex-wrap items-center relative w-full">
            <div class="md:w-[47%] w-full my-[10px] text-left ">
                <label for="name" class="text-black block font-bold uppercase text-sm ">{{ $t('c_name') }}*</label>
                <input type="text" v-model="name" id="name" placeholder="Nom" class="w-full p-[5px] border border-gray-900 outline-none focus:border-blue-500">
            </div>
            <div class="md:w-[47%] w-full my-[10px] text-left ">
                <label for="email" class="text-black block font-bold uppercase text-sm">{{ $t('c_email') }}*</label>
                <input type="text" v-model="email" id="email" placeholder="Email" class="w-full p-[5px] border border-gray-900 outline-none focus:border-blue-500">
            </div>
        </div>
        <div class="flex md:justify-between justify-center flex-wrap items-center relative w-full">
            <div class="md:w-[47%] w-full my-[10px] text-left ">
                <label for="phone" class="text-black block font-bold uppercase text-sm">{{ $t('c_phone') }}*</label>
                <input type="text" v-model="phone" id="phone" placeholder="Téléphone" class="w-full p-[5px] border border-gray-900 outline-none focus:border-blue-500">
            </div>
            <div class="md:w-[47%] w-full my-[10px] text-left ">
                <label for="objet" class="text-black block font-bold uppercase text-sm">Objet*</label>
                <input type="text" v-model="subject" id="objet" placeholder="Objet" class="w-full p-[5px] border border-gray-900 outline-none focus:border-blue-500">
            </div>
        </div>
        <div class="flex justify-between items-center relative w-full">
            <div class="w-full my-[10px] text-left ">
              <label for="projet" class="text-black block font-bold uppercase text-sm">{{ $t('c_project') }} <span class="text-xs">(Optionel)</span></label>
              <input type="text" v-model="project" id="projet" placeholder="Projet" class="w-full p-[5px] border border-gray-900 outline-none focus:border-blue-500">
            </div>
        </div>
        <div class="flex justify-between items-center relative w-full">
            <div class="w-full my-[10px] text-left ">
                <label for="description" class="text-black block font-bold uppercase text-sm">{{ $t('c_description') }}*</label>
                <textarea type="text" v-model="message" id="description" placeholder="Description" class="w-full h-[200px] resize-none p-[5px] border border-gray-900 outline-none focus:border-blue-500"></textarea>
            </div>
        </div>
        <div class="flex justify-between items-center relative w-full">
            <div class="w-full flex my-[10px] text-left items-center ">
                <input type="checkbox" name="agree" id="agree" required>
                <label for="agree" class="mx-[10px] text-black block font-bold uppercase text-sm" >{{ $t('c_agree') }} </label>
            </div>
        </div>
        <div class="flex justify-between items-center relative w-full">
            <div class="w-full flex my-[20px] justify-end ">
                <button type="submit" :disabled="loading" class="rounded-[5px] min-w-[150px] bg-cyan-500 text-white p-[10px] ">
                  <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                  <span v-else>{{ $t('c_send') }}</span>
                </button>
            </div>
        </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios'
import { ref } from 'vue'
import axiosInstance from '@/plugins/axios';

const name = ref('');
const email = ref('');
const phone = ref('');
const subject = ref('');
const project = ref('');
const message = ref('');
const loading = ref(false);
const successMessage = ref('');
const errorMessage = ref('');

const contact = () => {
  loading.value = true;

  axiosInstance.post('/contact-us/', {
    fullname: name.value,
    email: email.value,
    phone_number: phone.value,
    subject: subject.value,
    project: project.value,
    message: message.value,
  })
  .then(response => {
    loading.value = false;

    if (response.status === 200 || response.status === 201 || response.status === 201) {
      successMessage.value = 'Message envoyé avec succès !';
      hideSuccessMessageAfterDelay();
      clearForm();
    } else {
      successMessage.value = '';
    }
  })
  .catch(error => {
    loading.value = false;
    console.error(error);
    errorMessage.value = 'Une erreur s\'est produite. Veuillez réessayer.';
    hideErrorMessageAfterDelay();
  });
};

const hideSuccessMessageAfterDelay = () => {
  setTimeout(() => {
    successMessage.value = ''; 
  }, 5000);
};

const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 5000);
};

const clearForm = () => {
  name.value = '';
  email.value = '';
  phone.value = '';
  subject.value = '';
  message.value = '';
};
</script>
  
<style>

</style>