<template>
    <div v-if="successMessage" class="bg-green-600 border border-green-600 my-[10px] text-white text-center fixed md:top-[80px] top-[60px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ successMessage }}</div>
    <div v-if="errorMessage" class="bg-red-600 border border-red-600 my-[10px] text-white text-center fixed md:top-[80px] top-[60px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
    <div class=" font-montserrat flex justify-center items-center relative min-w-screen min-h-screen py-[50px] px-[10px] bg-gray-50">
        <div class="w-full flex justify-center items-center flex-col relative py-[50px] md:px-[20px] px-[10px] max-w-[600px] border-2 border-gray-300 h-full">
            <h1 class="text-1xl mb-[20px] text-center font-bold">Réinitialiser votre mot de passe</h1>
            <form class="relative w-full" @submit.prevent="requestPasswordReset">
              <div class=" relative block">
                    <input v-model="resetData.uid" type="hidden" id="uid" class="focus:border-gray-600 border p-[10px] border-gray-300 outline-none w-full" placeholder="uid">
              </div>
              <div class=" relative block">
                    <input v-model="resetData.token" type="hidden" id="token" class="focus:border-gray-600 border p-[10px] border-gray-300 outline-none w-full" placeholder="token">
              </div>
              <div class=" py-[15px] relative block">
                    <label for="password" class="uppercase">Password</label>
                    <div class="relative">
                        <input :type="showPassword ? 'text' : 'password'" v-model="resetData.password" placeholder="Mot de passe" id="password" class="focus:border-gray-600 border p-[10px] border-gray-300 outline-none w-full">
                        <i class="password-toggle" :class="showPassword ? 'fa fa-eye text-1xl absolute top-[15px] right-[15px]' : 'fa fa-eye-slash text-1xl absolute top-[15px] right-[15px]'" @click="showPassword = !showPassword" ></i>
                    </div>
                </div>
                <div class="pt-[25px] relative block">
                    <button type="submit" :disabled="loading" class="p-[10px] bg-green-600 rounded-[5px] hover:bg-green-700 duration-100 text-sm font-bold cursor-pointer text-white outline-none w-full">
                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                        <span v-else>Réinitialiser</span>
                    </button>
                </div>
                
            </form>
        </div>
        
    </div>
    <div class="w-full border-t border-gray-400 py-[50px] bg-gray-50 flex justify-center items-center">
        <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import axiosInstance from '@/plugins/axios';

const pageTitle = ref('Reset Password | IronSecur');
const showPassword = ref(false);
const resetData = ref({
  uid: '', 
  token: '', 
  password: '',
});
const loading = ref(false);
const errorMessage = ref('');
const successMessage = ref('');
const router = useRouter();

onMounted(() => {
  resetData.value.uid = router.currentRoute.value.params.uid || ''; 
  resetData.value.token = router.currentRoute.value.params.token || '';
});


const isPasswordValid = (password: string) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
};

const requestPasswordReset = async () => {
  try {
    loading.value = true;
    // Vérification si le mot de passe respecte les critères
    if (!isPasswordValid(resetData.value.password)) {
      errorMessage.value = 'Le mot de passe doit contenir au moins 8 caractères avec majuscules, minuscules, chiffres et caractères spéciaux.';
      hideErrorMessageAfterDelay();
      return;
    }

    // Appel à l'API pour réinitialiser le mot de passe
    const response = await axiosInstance.post('/auth/users/reset_password_confirm/', {
      uid: resetData.value.uid, 
      token: resetData.value.token,
      new_password: resetData.value.password,
    });
    successMessage.value = 'Mot de passe changé avec succès';
    setTimeout(() => {
      router.push('/log'); 
    }, 2000);
  } catch (error) {
    errorMessage.value = 'Erreur lors de la demande de réinitialisation';
    hideErrorMessageAfterDelay();
  } finally {
    loading.value = false; 
  }
};


const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 5000);
};
</script>

<style>
 
</style>