<template>
    <div class="flex relative"> 
        <router-link to="/Profile" 
        :class="{
                'text-center w-[calc(100%/3)] border-b-4 ': true, 
                'border-cyan-500': $route.path === '/Profile'
            }">
            Overview
        </router-link>
        <router-link to="/ProfileEdit" 
        :class="{
                'text-center w-[calc(100%/3)] border-b-4 ': true, 
                'border-cyan-500': $route.path === '/ProfileEdit'
            }">
            Edit Profile
        </router-link>
        <router-link to="/ProfilePassword" 
        :class="{
                'text-center w-[calc(100%/3)] border-b-4 ': true, 
                'border-cyan-500': $route.path === '/ProfilePassword'
            }">
            Change Password
        </router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>