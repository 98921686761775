<template>
  <div v-if="latestComment">
    <div class="w-full relative flex my-[15px] justify-center items-start">
      <div class="relative h-full px-[10px] flex justify-center items-center">
        <img src="../assets/img/Rectangle.jpg" alt="Avatar" class="md:w-[50px] md:h-[50px] w-[30px] h-[30px] rounded-[50%]">
      </div>
      <div class="w-full text-left px-[10px]">
        <h1 class="text-blue-600 font-bold">{{ latestComment.username }}</h1>
        <p class="py-[10px]">{{ latestComment.content }}</p>
        <div class="flex">
            <button v-if="isSuperUser" @click="toggleResponseForm">Répondre</button>
        </div>
        <!-- Afficher le formulaire de réponse si showResponseForm est true -->
        <ResponseComponent v-if="showResponseForm" :commentId="latestComment.id" :username="latestComment.username" />
      </div>
      
    </div>
    <div v-if="latestComment.responses.length > 0">
      <div v-for="response in latestComment.responses" :key="response.id" class="w-full relative my-[5px] bg-gray-200 md:p-[20px] p-[10px]">
        <p><strong class="text-blue-500">{{ response.username }} :</strong> {{ response.content }}</p>
      </div>
    </div>
  </div>
  <div v-else-if="allComments.length > 0">
      <div v-for="comment in allComments" :key="comment.id" class="w-full relative my-[15px] flex justify-center items-start">
        <!-- Afficher chaque commentaire -->
        <div class="relative h-full px-[10px] flex justify-center items-center">
          <img src="../assets/img/Rectangle.jpg" alt="Avatar" class="md:w-[50px] md:h-[50px] w-[30px] h-[30px] rounded-[50%]">
        </div>
        <div class="w-full text-left px-[10px]">
          <h1 class="text-blue-600 font-bold">{{ comment.username }}</h1>
          <p class="py-[10px]">{{ comment.content }}</p>
          <div class="flex">
            <!-- Utiliser un événement personnalisé pour déclencher l'affichage du formulaire de réponse -->
            <button v-if="isSuperUser" @click="toggleResponseForm1(comment.id)">Répondre</button>
          </div>
          <!-- Afficher le formulaire de réponse uniquement si showResponseForm est true pour ce commentaire -->
          <ResponseComponent v-if="comment.showResponseForm" :commentId="comment.id" :username="comment.username" />
          <!-- Afficher les réponses aux commentaires -->
          <div v-if="comment.responses.length > 0">
            <div v-for="response in comment.responses" :key="response.id" class="w-full relative my-[5px] bg-gray-200 md:p-[20px] p-[10px]">
              <p><strong class="text-blue-500">{{ response.username }} :</strong> {{ response.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Afficher un message si aucun commentaire n'est disponible -->
    <div v-else class="w-full relative flex justify-center items-center flex-wrap text-center p-[20px] font-semibold text-sm text-blue-950">
      <p>Pas de commentaire disponible</p>
    </div>
</template>

<script setup lang="ts">
import ResponseComponent from '../components/Responses.vue';
import { ref } from 'vue';

const props = defineProps<{
  latestComment: Comment | null;
  allComments: Comment[];
  isSuperUser: boolean
}>();
interface Comment {
  id: number;
  username: string;
  content: string;
  responses: Response[];
  showResponseForm: boolean;
}

const showResponseForm = ref(false);

const toggleResponseForm = () => {
  showResponseForm.value = !showResponseForm.value;
};

const toggleResponseForm1 = (commentId: number) => {
  // Trouver le commentaire correspondant dans la liste et basculer son état showResponseForm
  const comment = props.allComments.find(c => c.id === commentId);
  if (comment) {
    comment.showResponseForm = !comment.showResponseForm;
  }
};

</script>

<style>
</style>