<template class="max-w-screen">
    <div class="flex font-montserrat flex-col justify-center items-center">
        <HeaderComponent/>
        <div class="lg:h-[80px] h-[50px]"></div>
        <div class="container flex flex-col justify-center items-center max-w-[1024px] md:my-[50px] my-[25px] py-[20px] px-[10px] w-full ">
            <h1 class="md:text-3xl text-xl font-semibold text-center uppercase text-black">Formation en cybersécurité</h1>
            <div class="w-full relative my-[25px] md:my-[50px] flex justify-center items-center">
                <form class="w-full relative">
                    <div class="w-full relative flex justify-center items-center">
                        <input type="text" id="search" v-model="searchQuery" @input="searchFormations" class="w-full border md:px-[30px] md:p-[20px] border-l-none outline-none focus:shadow-4xl text-sm px-[15px] p-[10px]">
                        <input value="Rechercher" type="submit" class="bg-cyan-500 cursor-pointer text-white font-semibold border border-cyan-500 text-sm md:px-[30px] md:p-[20px] px-[15px] p-[10px]">
                    </div>
                </form>
            </div>
            <div v-show="searchQuery.length === 0" class="w-full relative md:text-justify md:p-[50px] p-[20px] text-gray-700 bg-gray-100">
                La digitalisation prend de plus en plus de l'ampleur et elle s’accompagne 
                des risques cybernétiques. Comment se protéger? C’est en réponse à ce 
                besoin grandissant que IronSecur propose un programme de formation alliant 
                compétences théoriques et pratiques pour former des experts capables de 
                faire face à ces défis. L'engagement éthique de cette mission vous amène 
                à vous mettre au service d'entreprises traitant des données sensibles ou 
                fournissant des services en cybersécurité. Dans le cadre de notre programme, 
                une immersion en entreprise est prévue pour vous préparer à cette réalité 
                professionnelle. Chez IronSecur nous faisons de vous des acteurs prêts à 
                contrer les cyberattaques.
            </div>
        </div>
        <div class="container max-w-[1024px] py-[20px] mb-[150px] px-[10px] w-full ">
            <div class="w-[50px] h-[4px] bg-[#427E7E]"></div>
            <h1 class="uppercase text-[#427E7E] text-2xl font-bold my-[10px]">Nos programmes de formations</h1>
            <div class="flex md:justify-start justify-center items-center flex-wrap">
                <router-link v-for="formation in filteredFormations" :key="formation.id" :to="'/training-' + formation.id" class="m-[10px] pa md:w-[300px] md:h-[300px] w-[265px] h-[265px] shadow-4xl bg-white p-[20px] flex flex-col justify-center items-start relative transition-opacity duration-500 ease-linear hover:opacity-75">
                    <h1 class="text-cyan-500 font-bold text-xl">{{ formation.title }}</h1>
                    <p class="text-xs my-[5px]">
                        <b>Durée : </b> {{ formation.duration }}
                    </p>
                    <p class="text-xs my-[5px]">
                        <b>Métier visé : </b> {{ formation.job }}
                    </p>
                    <p class="text-xs my-[5px]">
                        <b>Certification cible : </b> {{ formation.certification }}
                    </p>
                    <div class="absolute en1 top-0 left-0 right-0 duration-300 ease-in-out w-full h-full">
                        <img src="../assets/img/hacker.jpg" alt="" class="w-full h-full">
                    </div>
                    <div class="absolute en2 top-0 left-0 right-0 duration-300 ease-in-out w-full p-[25px] bg-black/25 flex flex-col justify-end items-center h-full">
                        <h1 class="text-center font-bold text-white">{{ formation.title }}</h1>
                    </div>
                </router-link>
                <div class="m-[10px] cursor-not-allowed md:w-[300px] md:h-[300px] w-[265px] h-[265px] border bg-[url('./img/logo.png')] bg-cover bg-no-repeat bg-center p-[20px] flex flex-col justify-center items-center relative transition-opacity" >
                    <h1 class="text-center uppercase text-sm z-10 font-semibold">Bientôt disponible</h1>
                    <p class="text-xs my-[5px]">
                    </p>
                    <p class="text-xs my-[5px]">
                    </p>
                    <p class="text-xs my-[5px]">
                    </p>
                    <div class="absolute top-0 left-0  backdrop-blur-lg right-0 duration-300 ease-in-out w-full h-full">
                    </div>
                    <div class="absolute top-0 left-0 right-0 hidden duration-300 ease-in-out w-full p-[25px] bg-black/25 flex-col justify-end items-center h-full">
                        <h1 class="text-center font-bold text-white"></h1>
                    </div>
                </div>
                
            </div>
        </div>
        <FooterComponent/>
    </div>
  </template>
  
<script setup lang="ts">
import HeaderComponent from '../components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter()

// Données des formations
const formations = ref([
    { id: "cybersecurity", title: "Introduction à la cybersécurité", duration: "14 semaines", job: "Analyste en cybersécurité", certification: "CompTIA’s Security+" },
    // Ajoutez d'autres formations ici...
]);

// Recherche des formations
const searchQuery = ref('');
const filteredFormations = ref([]);

const searchFormations = () => {
    filteredFormations.value = formations.value.filter(formation =>
        formation.title.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
}

onMounted(() => {
    filteredFormations.value = formations.value; // Charger toutes les formations au démarrage
});
</script>

<style scoped>
.pa {
  transition: 0.5s;
}
.pa:hover {
  transition: 0.5s;
}
.pa:hover .en1 {
  display: none;
}
.pa:hover .en2 {
  display: none;
}
</style>