<template class="max-w-screen">
    <div class="flex flex-col px-[10px] items-center justify-center"  v-for="training in props.trainings">
        <div class="text-center container max-w-[1024px] flex font-bold text-2xl w-auto md:max-w-[600px] md:my-[50px] my-[20px]">
               <img src="../assets/img/Vector.png" class="mx-[10px] md:h-[35px] h-[25px]" alt="">
            {{ $t(training.nam) }} 
            </div>
              <div class="w-full md:flex relative md:min-h-[400px] h-auto">
                  <div class="md:w-7/12 flex flex-col w-full md:mr-[20px]">
                      <div>
                        <span class="font-bold">{{ $t('ta') }}</span>: {{ $t(training.target) }}
                      </div>
                      <br>
                      <div>
                        <span class="font-bold">{{ $t('pree') }}</span>: 
                        {{ $t(training.pre) }}
                      </div>
                      <br>
                      <div>
                        <span class="font-bold">{{ $t("eo") }}</span>:
                      </div>
                      <ul :class="training.style">
                          <li class="list-disc ml-[30px]">
                            {{ $t(training.edu[0]) }}
                          </li>
                          <li class="list-disc ml-[30px]">
                            {{ $t(training.edu[1]) }}
                          </li>
                          <li class="list-disc ml-[30px]">
                            {{ $t(training.edu[2]) }}
                          </li>
                          <li class="list-disc ml-[30px]">
                            {{ $t(training.edu[3]) }}
                          </li>
                          <li class="list-disc ml-[30px]">
                            {{ $t(training.edu[4]) }}
                          </li>
                          <li class="list-disc ml-[30px]">
                            {{ $t(training.edu[5]) }}
                          </li>
                          <li class="list-disc ml-[30px]">
                            {{ $t(training.edu[6]) }}
                          </li>
                      
                      
                      </ul>
                      <a href="../assets/Document/Document.pdf" download="Document.pdf" class="py-[5px] flex justify-center relative left-0 items-center text-center my-[20px] w-[100px] px-[10px] border-cyan-600 border rounded-[10px]">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.5 15.5H3.25C2.78587 15.5 2.34075 15.3156 2.01256 14.9874C1.68437 14.6592 1.5 14.2141 1.5 13.75V3.25C1.5 2.78587 1.68437 2.34075 2.01256 2.01256C2.34075 1.68437 2.78587 1.5 3.25 1.5H10.25M13.75 1.5V16.375M13.75 16.375L11.125 13.75M13.75 16.375L16.375 13.75" stroke="#0FA958" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{ $t('booklet') }}
                      </a>
                      
                  </div>
                  <div class="md:w-5/12 w-full relative md:ml-[20px]">
                      <img :src="training.image" alt="Image training" class="min-w-[100%] h-auto mt-[10px] md:mt-[0px] md:max-h-[100%]">
                  </div>
              </div>
              <div class="w-full flex justify-start items-center">
                
              </div>
              <div class="w-screen md:mt-[20px] mt-[10px] bg-gray-100 flex justify-center items-center">
                <div class="container max-w-[1024px] justify-center md:flex md:justify-between items-start">
                    <div class="md:w-[calc(100%/3)] w-full p-[15px] py-[25px]">
                        <span class="font-bold">{{ $t('pe') }}</span>:
                      <ul class="mt-[15px]">
                          <li class="list-disc ml-[30px] my-[10px]">
                            {{ $t(training.PA[0]) }}
                          </li>
                          <li class="list-disc ml-[30px] my-[10px]">
                            {{ $t(training.PA[1]) }}
                          </li>
                          <li class="list-disc ml-[30px] my-[10px]">
                            {{ $t(training.PA[2]) }}
                          </li>
                          <li class="list-disc ml-[30px] my-[10px]">
                            {{ $t(training.PA[3]) }}
                          </li>
                      
                      
                      </ul>
                    </div>
                    <div class="md:w-[calc(100%/3)] w-full p-[15px] py-[25px]">
                        <span class="font-bold">{{ $t('dod') }}</span>:
                      <ul class="mt-[15px]">
                          <li class="list-disc ml-[30px] my-[10px]">
                            <span class="font-bold mr-[10px]"> {{ $t('du') }}:</span> {{ $t(training.Duration[0]) }}
                          </li>
                          <li class="list-disc ml-[30px] my-[10px]">
                            <span class="font-bold mr-[10px]"> {{ $t('mo') }}:</span> {{ $t(training.Duration[1]) }}
                          </li>
                          <li class="list-disc ml-[30px] my-[10px]">
                            <span class="font-bold mr-[10px]"> {{ $t('tgs') }}:</span> {{ $t(training.Duration[2] )}}
                          </li>
                          <li class="list-disc ml-[30px] my-[10px]">
                            <span class="font-bold mr-[10px]"> {{ $t('mod') }}:</span> {{ $t(training.Duration[3]) }}
                          </li>
                      
                      
                      </ul>
                    </div>
                    <div class="md:w-[calc(100%/3)] w-full p-[15px] py-[25px]">
                        <span class="font-bold">{{ $t('per') }}</span>:
                      <ul class="mt-[15px]">
                          <li class="list-disc ml-[30px] my-[10px]">
                            {{ $t(training.PR[0]) }}
                          </li>
                          <li class="list-disc ml-[30px] my-[10px]">
                            {{ $t(training.PR[1]) }}
                          </li>
                          <li class="list-disc ml-[30px] my-[10px]">
                            {{ $t(training.PR[2]) }}
                          </li>
                          <li class="list-disc ml-[30px] my-[10px]">
                            {{ $t(training.PR[3]) }}
                          </li>
                          <li class="list-disc ml-[30px] my-[10px]" :class="training.PREstyle">
                            {{ $t(training.PR[4]) }}
                          </li>
                      
                      
                      </ul>
                    </div>
                </div>

              </div>
    </div>
    
  </template>
  
  <script setup lang="ts">
    const props = defineProps<{
        trainings: object,
    }>();

</script>
  
  <style>
  
  </style>