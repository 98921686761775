import Vue from 'vue'
import Vuex from 'vuex'

import { createStore } from 'vuex';

const store = createStore({
  state: {
    delta: undefined,
    contents: ''
  },
  mutations: {
    setDelta(state, payload) {
        state.delta = payload 
    },
    setContent(state, payload) {
        state.contents = payload 
    }
  },
  getters: {
    delta: ({delta}) => delta,
    contents: ({contents}) => contents
  },
});

export default store;
