<template>
  <div class="carousel w-full">
    <div class="swiper-container w-full">
      <div class="swiper-wrapper w-full py-[20px]">
        <div class="swiper-slide shadow-4xl  " v-for="(card, index) in cards" :key="index">
          <div class="bg-white h-[550px] shadow-md p-[10px] relative rounded-lg">
            <div class="w-[40px] flex justify-center items-center h-[40px] rounded-[50%] text-white bg-cyan-700">
              <span class="font-bold uppercase">{{ card.id }}</span>
            </div>
            <div class="w-full relative shadown h-[250px] overflow-hidden my-[10px]">
              <h2 class="text-xl my-[10px] font-semibold">
                <router-link :to="{ name: 'article', params: { id: card.id }}" class="hover:text-black hover:underline duration-300">{{ card.title }}</router-link>
              </h2>
              <p class="text-gray-800">
                <div v-html="card.content"></div>
              </p>
            </div>
            <div class="w-full absolute bottom-[80px] left-0 h-[150px] max-h-[150px] overflow-hidden flex justify-start items-start">
              <img :src="card.image" alt="" class="w-full">
            </div>
            <div class="absolute w-full left-0 right-0 p-[10px] text-black bg-gray-200 bottom-0 flex justify-between items-center">
              <div>
                <span class="uppercase text-sm font-bold">Article</span>
                <span class="font-bold uppercase text-gray-400 text-sm mx-[10px]">{{ card.source }}</span>
              </div>
              <div class="flex justify-center items-center">
                <p class="mx-[10px]">{{ card.likes.length }}</p>
                <div v-if="card.is_liked_by_current_user">
                  <i class="text-red-500 text-xl fa-solid fa-heart"></i>
                </div>
                <div v-else>
                  <i class="fa-regular fa-heart text-xl text-black"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/bundle';
import HeartIcon from './HeartIcon.vue';
import { useRouter } from 'vue-router';

const router = useRouter();


export default {
  props: {
    cards: Object,
  },
  components: {
    HeartIcon
  },
  data() {
    return {
      isLiked: false,
    };
  },
  mounted() {
    new Swiper('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: 20,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      effect: 'slide',
      keyboard: true,
      parallax: true,
      zoom: true,
      breakpoints: {
        868: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        530: {
          slidesPerView: 2,
          spaceBetween: 20,
        }
      }


    });

  },
  methods: {
    toggleLike() {
      this.isLiked = !this.isLiked;
    }
  },
};
</script>

<style scoped>
/* swiper.css */
@import 'swiper/swiper-bundle.css';
.carousel {
  /* Personnalisez les styles du carousel ici */
  overflow: hidden; /* Ajoutez cette ligne pour masquer le contenu qui dépasse */
}
.shadown::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  backdrop-filter: blur(10px); 
}

</style>
