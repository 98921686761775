<template>
    <div class="flex font-montserrat flex-col justify-center items-center">
        <HeaderComponent/>
        <div class="relative">
            <div id="top" class="lg:h-[80px] h-[50px]"></div>
            <carouselBranding :initial-slide="initialSlide" ></carouselBranding>
            <div class="absolute z-[1000000] text-left bg-black/50 px-[20px] md:px-[50px] text-white top-0 left-0 right-0 bottom-0 w-full items-center flex flex-col justify-center ">
                <h1 class="text-4xl md:text-6xl uppercase text-center font-bold opacity-100">Contact</h1>
            </div>
        </div>
        <div class="container max-w-[1024px]">
            <div class="mt-[20px] md:mt-[40px]">
                <h1 class="text-3xl font-bold text-center my-[20px]">{{ $t('contact_us') }}</h1>
                <div class="flex items-center md:justify-start justify-center flex-wrap">
                    <div class="flex justify-center items-center w-auto mx-[10px]">
                        <i class="fa-brands fa-whatsapp text-teal-600 mx-[10px] text-2xl"></i>
                        <a href="https://wa.me/+22943480141" class="text-xl ">Tel : +229 43 48 01 41</a>
                    </div>
                </div>
                <div class="md:flex flex-wrap justify-center items-center w-full">
                    <div class="md:w-7/12 w-full py-4 md:py-0">
                        <ContactForm/>
                    </div>
                    <div class="md:w-5/12 w-full h-full relative py-4 md:py-0">
                        <img src="../assets/img/BG.jpg" alt="" class="min-w-full min-h-full max-w-full max-h-full">
                    </div>
                </div>
            </div>
            <div class="flex items-center md:mt-[40px] mt-[20px] flex-wrap justify-center w-full relative">
                <div class="flex justify-center w-full m-[10px] items-center flex-col">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.2160642099784!2d2.426512875958983!3d6.366077993624056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10235594bf28d051%3A0x6fb8aeff9e296a51!2sEpitech%20Benin!5e0!3m2!1sen!2sbj!4v1710157101017!5m2!1sen!2sbj" class="h-[300px] w-[100%] max-w-[1000px]" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h1 class="my-[10px] text-center text-sm font-bold">Benin, Cotonou</h1>
                </div>
            </div>
            <div class="w-full my-[20px] flex flex-col items-center">
            <h1 class="uppercase underline text-2xl font-bold text-center my-[20px]">{{ $t('name_partners') }}</h1>
            <div class="max-w-full md:w-[900px] w-full flex items-center justify-center p-[10px]">
                <!--
                <button class="mx-[20px]" id="scroll-left" aria-label="Bouton de direction gauche"><i
                    class="fa-solid fa-angle-left"></i></button>
                -->
                <div class="parent flex justify-center items-center">
                    <a target="_blank" href="https://affarmers.com" class="flex md:mx-[20px] mx-[10px] flex-col items-center">
                        <div class="w-[100px] h-[100px] flex justify-center border border-gray-400 items-center rounded-[50%]  ">
                            <img src="../assets/img/AFF.jpg" class="rounded-[50%] " alt="">
                        </div>
                        <div class="mt-[5px]">
                            <h1>AFF</h1>
                        </div>
                    </a>
                    <a target="_blank" href="https://epitech.bj" class="flex md:mx-[20px] mx-[10px] flex-col items-center">
                        <div class="w-[100px] h-[100px] flex justify-center border border-gray-400 items-center relative rounded-[50%] ">
                            <img src="../assets/img/EPITECH.jpg" class=" h-[50%] w-[70%] " alt="">
                        </div>
                        <div class="mt-[5px]">
                            <h1>EPITECH</h1>
                        </div>
                    </a>
                </div>
                <!--
                <button class="mx-[20px]" id="scroll-right" aria-label="Bouton de direction droite"><i
                    class="fa-solid fa-angle-right"></i></button>
                    -->
            </div>
        </div>
        </div>
    
        <FooterComponent/>
    </div>
  
</template>

<script>
import ContactForm from '../components/ContactForm.vue'
import HeaderComponent from '../components/HeaderComponent.vue'
import carouselBranding from '../components/CarouselBranding.vue'
import FooterComponent from '@/components/FooterComponent.vue'
export default {
    data() {
    return {
      initialSlide: 4, 
    };
  },
    components: {
        HeaderComponent,
        FooterComponent,
        ContactForm,
        carouselBranding
    },
    beforeRouteEnter(to, from, next) {
        document.title = 'IronSecur | Contact us';
        next();
    },
    
}
</script>

<style>

</style>