<template>
  <div class="relative slide">
    <div class="carousel-indicators absolute bottom-0 flex bg-gray-100 md:h-24 h-10 w-full justify-center items-center">
      <!--
      <ol class="z-50 flex w-4/12 justify-center">
        <li v-for="(img, i) in images" :key="i" class="md:w-4 md:h-4 rounded-full cursor-pointer mx-2">
          <button @click="changeSlide(i)" :class="{
            'bg-white': active === i,
            'bg-gray-500': active !== i,
            'w-3 h-3 rounded-[50%]': true
          }"></button>
        </li>
      </ol>
      -->
    </div>
    <div class="carousel-inner relative overflow-hidden w-full">
      <div v-for="(img, i) in images" :id="`slide-${i}`" :key="i" :class="`${active === i ? 'active' : 'left-full'}`" class="carousel-item inset-0 relative w-full transform transition-all duration-500 ease-in-out">
        <img class="block w-full h-full" :src="img" :alt="`Carousel slide ${i + 1}`" />
      </div>
    </div>
  </div>
</template>

<script>
import slide1 from '@/assets/img/slide_branding_8.jpg'
import slide2 from '@/assets/img/slide_branding_5.jpg'
import slide3 from '@/assets/img/slide_branding_2.jpg'
import slide4 from '@/assets/img/slide_branding_3.jpg'
import slide5 from '@/assets/img/slide_branding_4.jpg'
import slide6 from '@/assets/img/slide_branding_6.jpg'
import slide7 from '@/assets/img/slide_branding_7.jpg'

export default {
  props: {
    initialSlide: {
      type: Number,
    },
    canSlide: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    images: [ slide1, slide2, slide3, slide4, slide5, slide6, slide7],
    active: 0
  }),
  mounted() {
    if(this.canSlide == true) {
      this.startAutoSlide(); 
    }
    this.active = this.initialSlide;
  },
  methods: {
    changeSlide(index) {
      this.active = index;
      clearInterval(this.intervalId);
    },
    startAutoSlide() {
      this.intervalId = setInterval(() => {
        this.active = (this.active + 1) % this.images.length;
      }, 5000);
    }
  }
}
</script>

<style>
.left-full {
  left: -100%;
}

.carousel-item {
  float: left;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin-right: -100%;
  backface-visibility: hidden;
}

.carousel-item.active {
  left: 0;
}

@media screen and (max-width: 768px) {
  .carousel-item {
    height: auto;
  }
}
</style>
