<template>
    <div class="w-full font-montserrat relative">
        <headerComponent/>
        <div class="relative w-full mb-[50px]">
            <div class="px-[10px] py-[20px] md:p-[20px] flex flex-col items-center relative bg-gray-50 min-h-screen top-[60px]">
                <h1 class="text-black font-bold py-[20px] text-center">Home / List of News</h1>
                <div class="bg-white w-[100%] md:w-[95%] relative p-[10px]">
                    <div class="text-black flex relative text-sm font-bold bg-white border border-gray-400 p-[10px] w-full">
                        <div class="p-[5px] flex items-center w-[25px]"><input type="checkbox" name="" id=""></div>
                        <div class="p-[5px] flex items-center w-[25px]"></div>
                        <form>
                            <div class="p-[5px] flex items-center relative">
                                <input type="search" name="search" class="border border-gray-700 pl-[30px] outline-none w-[250px] rounded-[5px] py-[5px] px-[10px] " placeholder="Search..." id="search" @input="handleSearch($event)">
                                <label type="submit" for="search" class="absolute left-[10px] text-black cursor-pointer top-[10px] text-xl"><ion-icon name="search-outline"></ion-icon></label>
                            </div>
                        </form>
                    </div>
                    <table class="w-full relative">
                        <thead class="w-full relative">
                            <tr class="text-black flex relative text-sm font-bold bg-gray-50 border border-gray-400 p-[10px] w-full">
                               <td class="p-[5px] flex w-[25px]"></td>
                               <td class="p-[5px] flex w-[25px]">ID</td>
                               <td class="p-[5px] md:w-[calc(100%/6)] w-[calc(100%/3)]  flex">Title</td>
                               <td class="p-[5px] md:w-[calc(100%/6)] w-[calc(100%/3)]  hidden">Content</td>
                               <td class="p-[5px] md:w-[calc(100%/6)] w-[calc(100%/3)]  flex">Image</td>
                               <td class="p-[5px] md:w-[calc(100%/6)] w-[calc(100%/3)]  md:flex hidden">Date</td>
                               <td class="p-[5px] md:w-[calc(100%/6)] w-[calc(100%/3)]  md:flex hidden"></td>
                            </tr>
                        </thead>
                        <tbody class="w-full relative">
                            <rowNews :news="filteredNews" />
                        </tbody>
                        
                    </table>
                    <div class="flex justify-center items-center mt-8 space-x-4">
                        <button
                          @click="loadPreviousPage"
                          :disabled="!hasPreviousPage"
                          :class="{ 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded': hasPreviousPage, 'bg-gray-300 text-gray-500 cursor-not-allowed py-2 px-4 rounded': !hasPreviousPage }"
                        >
                          Previous
                        </button>
                        <button
                          :disabled="true"
                          class="bg-gray-200 text-gray-700 font-bold py-2 px-4 rounded cursor-pointer"
                        >
                          {{ currentPage }} / {{ totalPageCount }}
                        </button>
                        <button
                          @click="loadNextPage"
                          :disabled="!hasNextPage"
                          :class="{ 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded': hasNextPage, 'bg-gray-300 text-gray-500 cursor-not-allowed py-2 px-4 rounded': !hasNextPage }"
                        >
                          Next
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="w-full border-t border-gray-400 py-[50px] flex justify-center items-center">
            <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
        </div>
    </div>
    
</template>

<script setup lang="ts">
import headerComponent from '../components/headerComponentAdmin.vue'
import rowNews from '../components/rowNews.vue'
import { ref, onMounted } from 'vue';
import axios from 'axios';
import axiosInstance from '@/plugins/axios';

const pageTitle = ref('List of news'); 
const news = ref<any[]>([]);
const filteredNews = ref<any[]>([]);


const allNews = ref<any[]>([]); 

// ...

// ... (votre code existant)

const fetchAllNews = async () => {
    try {
        let allNews: any[] = [];
        let nextPage = '/news/';

        while (nextPage) {
            const response = await axiosInstance.get(nextPage);

            if (response.status === 200 || response.status === 201 || response.status === 204) {
                const { results, next } = response.data;
                allNews = allNews.concat(results);
                nextPage = next;
            } else {
                nextPage = null;
            }
        }
        return allNews;
    } catch (error) {
        console.error('Erreur lors du chargement des news', error);
        return [];
    }
};

const handleSearch = async (event: any) => {
    const searchTerm = event.target.value.toLowerCase().trim();

    if (!searchTerm) {
        filteredNews.value = news.value;
    } else {
        const allNews = await fetchAllNews();

        if (allNews.length > 0) {
            filteredNews.value = allNews.filter(newsDetail => {
                return (
                    (newsDetail.title && newsDetail.title.toLowerCase().includes(searchTerm)) ||
                    (newsDetail.content && newsDetail.content.toLowerCase().includes(searchTerm))
                );
            });
        }
    }
};




onMounted(async () => {
  document.title = pageTitle.value;
  await fetchNews(); 
  try {
    const response = await axiosInstance.get('/news/');
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const fetchedNews = response.data.results;
      // Sort fetched news by date if needed
      fetchedNews.sort((a: any, b: any) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      news.value = fetchedNews;
      filteredNews.value = fetchedNews;
    }
  } catch (error) {
    console.error('Erreur lors du chargement des news', error);
  }

});

const currentPage = ref(1);
const pageSize = 6; 
const hasPreviousPage = ref(false);
const hasNextPage = ref(false);

const loadNews = async (page: number) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) return;

    const response = await axiosInstance.get(`/news/?page=${page}&page_size=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const { results, previous, next } = response.data;
      filteredNews.value = results;
      hasPreviousPage.value = !!previous;
      hasNextPage.value = !!next;
    }
  } catch (error) {
    console.error('Erreur lors du chargement des news', error);
  }
};


const loadPreviousPage = () => {
  if (hasPreviousPage.value) {
    currentPage.value--;
    loadNews(currentPage.value);
  }
};

const loadNextPage = () => {
  if (hasNextPage.value) {
    currentPage.value++;
    loadNews(currentPage.value);
  }
};

onMounted(() => {
  loadNews(currentPage.value);
});

const fetchNews = async () => {
    try {
        const response = await axiosInstance.get('/news/');
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            const fetchedNews = response.data.results;
            console.log(fetchedNews)
            fetchedNews.sort((a: any, b: any) => {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
            });
            news.value = fetchedNews;
            filteredNews.value = fetchedNews;
        }
    } catch (error) {
        console.error('Erreur lors du chargement des news', error);
    }
};

const totalPageCount = ref(0);

const fetchTotalPageCount = async () => {
  try {
    const response = await axiosInstance.get('/news/');
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const totalNews = response.data.count;
      totalPageCount.value = Math.ceil(totalNews / pageSize);
    }
  } catch (error) {
    console.error('Erreur lors du chargement du nombre total de pages', error);
  }
};

onMounted(() => {
  fetchTotalPageCount();
})



</script>


<style>

</style>