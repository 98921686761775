<template>
  <div class="flex font-montserrat justify-center items-center relative min-w-screen min-h-screen py-[50px] px-[10px] bg-gray-50">
    <div class="flex justify-center items-center z-30 flex-col fixed top-[40px] left-[50%] w-full translate-x-[-50%]">
      <div v-if="errorMessage" class="bg-red-600 border border-red-600 my-[10px] text-white text-center font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[550px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
      <div v-if="successMessage" class="bg-green-600 border border-green-600 my-[10px] text-white text-center font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ successMessage }}</div>
    </div>
    <div class="w-full flex flex-col justify-center items-center relative py-[50px] md:px-[20px] px-[10px] max-w-[600px] border-2 border-gray-300 h-full">
      <h1 class="text-1xl mb-[20px] text-center font-bold">Add a user</h1>
      <form class="flex flex-col justify-center items-center w-full relative max-w-[700px]" @submit.prevent="registerUser" ref="userForm">
        <div class="py-[10px] w-full px-[10px] relative">
            <label for="firstname" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Firstname</label>
            <input type="text" name="firstname" id="firstname" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent"  required placeholder="First Name" v-model="userData.firstname">
        </div>
        <div class="py-[10px] w-full px-[10px] relative">
            <label for="lastname" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Lastname</label>
            <input type="text" name="lastname" id="lastname" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent" required placeholder="Last Name" v-model="userData.lastname">
        </div>
        <div class="py-[10px] w-full px-[10px] relative">
            <label for="username" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Username</label>
            <input type="text" name="username" id="username" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent" required placeholder="Username" v-model="userData.username">
        </div>
        <div class="py-[10px] w-full px-[10px] relative">
            <label for="phone" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Number</label>
            <input type="text" name="phone" id="phone" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent" required placeholder="Number" v-model="userData.phone">
        </div>
        <div class="py-[10px] w-full px-[10px] relative">
            <label for="email" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Email</label>
            <input type="text" name="email" id="email" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent" required placeholder="ironsecur@gmail.com" v-model="userData.email">
        </div>
        <!--
        <div class="py-[10px] w-full px-[10px] relative">
            <label for="password" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Password</label>
            <input :type="showPassword ? 'text' : 'password'" v-model="userData.password" placeholder="Password" id="password" class="p-[10px] foc w-full rounded-[5px] border outline-none pt-[25px] bg-transparent">
            <i class="password-toggle" :class="showPassword ? 'fa fa-eye text-1xl absolute top-[45px] right-[25px]' : 'fa fa-eye-slash text-1xl absolute top-[45px] right-[25px]'" @click="showPassword = !showPassword" ></i>
            <div class="affiche">
              <ul class="text-xs text-gray-600 mt-[10px]">
                <li class="list-disc ml-[25px] my-0">Le mot de passe doit contenir au moins 8 caractères avec des majuscules,</li>
                <li class="list-disc ml-[25px] my-0">des minuscules, des chiffres et des caractères spéciaux.</li>
              </ul>
            </div>
        </div>
        <div class="py-[10px] w-full px-[10px] relative">
            <label for="confirmPassword" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Confirm Password</label>
            <input :type="cshowPassword ? 'text' : 'password'" v-model="userData.re_password" placeholder="Confirm Password" id="confirmPassword" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent">
            <i class="password-toggle" :class="showPassword ? 'fa fa-eye text-1xl absolute top-[45px] right-[25px]' : 'fa fa-eye-slash text-1xl absolute top-[45px] right-[25px]'" @click="showPassword = !showPassword" ></i>
        </div>
        -->
        <div class="py-[10px] w-full px-[10px] flex justify-end items-center relative">
          <button type="submit" :disabled="loading" class="py-[10px] min-w-[100px] px-[20px] rounded-[5px] text-sm font-bold text-white bg-cyan-500 hover:bg-cyan-600 duration-300">
                <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                <span v-else>Register</span>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="w-full border-t border-gray-400 py-[50px] bg-gray-50 flex justify-center items-center">
    <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
const pageTitle = ref('Register'); 
const showPassword = ref(false);
const cshowPassword = ref(false);
const successMessage = ref('');
import axiosInstance from '@/plugins/axios';

const router = useRouter();

onMounted(() => {
  document.title = pageTitle.value; 
});

const userData = ref({
  firstname: '',
  lastname: '',
  username: '',
  phone: '',
  email: '',
});

const userForm = ref(); 
const loading = ref(false);
const errorMessage = ref('');

const MIN_PASSWORD_LENGTH = 8;

const isPasswordValid = (password: any) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
};


const registerUser = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    //const { password, re_password } = userData.value;

    //if (password !== re_password) {
    //  errorMessage.value = 'Passwords do not match.';
    //  hideErrorMessageAfterDelay();
    //  return;
    //}
//
    //if (!isPasswordValid(password)) {
    //  errorMessage.value = 'Password should contain at least 8 characters with uppercase, lowercase, numbers, and special characters.';
    //  hideErrorMessageAfterDelay();
    //  return;
    //}

    const response = await axiosInstance.post('/auth/users/', userData.value, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      successMessage.value = `New user created successfully.`;
      hideSuccessMessageAfterDelay();
      userForm.value.reset();
      setTimeout(() => {
        router.push('/users');
      }, 5000);
    } else {
      console.error('Error creating user:', response.data);
      errorMessage.value = 'Error creating user.';
      hideErrorMessageAfterDelay();
    }
  } catch (error: any) {
    if (error.response) {

        console.error('Error response:', error.response.data);
      
        const errorData = error.response.data;
        const phoneError = errorData.phone ? errorData.phone[0] : '';
        const emailError = errorData.email ? errorData.email[0] : '';
        const passwordError = errorData.password ? errorData.password[0] : '';
        const rePasswordError = errorData.re_password ? errorData.re_password[0] : '';
      
        errorMessage.value = `Phone: ${phoneError}, Email: ${emailError}, Password: ${passwordError}, Re-enter Password: ${rePasswordError}`;
        hideErrorMessageAfterDelay();
      } else if (error.request) {
        console.error('No response received:', error.request);
        errorMessage.value = 'Erreur de connexion au serveur. Veuillez réessayer.';
        hideErrorMessageAfterDelay();
      } else {
        console.error('Unexpected error:', error.message);
        errorMessage.value = 'Une erreur inattendue s\'est produite. Veuillez réessayer.';
        hideErrorMessageAfterDelay();
      }
    }finally {
        loading.value = false;
      }
};

const hideSuccessMessageAfterDelay = () => {
  setTimeout(() => {
    successMessage.value = ''; 
  }, 5000);
};

const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 10000);
};


</script>

<style scoped>
.affiche {
  display: none;
}
.foc:focus ~ .affiche {
  display: block;
}

</style>