<template>
    <div class="w-full font-montserrat relative">
        <headerComponent/>
        <div v-if="errorMessage" class="bg-red-500 left-[50%] translate-x-[-50%] border border-red-500 text-white text-center font-bold flex justify-center items-center fixed top-[70px] z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
        <div class="relative mb-[50px] w-full">
            <div class="py-[20px] flex flex-col items-center relative bg-gray-50 min-h-screen top-[60px]">
                <h1 class="text-black font-bold py-[20px] text-center">Home / Profil</h1>
                <h1 class="text-2xl uppercase font-bold text-center text-black py-[20px]">Profile</h1>
                <div class=" block md:flex justify-center w-[90%] py-[10px]">
                    <div class="md:w-[200px] p-[10px] w-full md:h-[200px] bg-white md:mx-[10px] flex flex-col justify-center items-center">
                        <img src="../assets/img/icon.jpeg" alt=""  class="w-[120px] h-[120px] rounded-[50%] border border-black">
                        <h1 class="uppercase my-[10px]"> {{ updatedUser.firstname }} {{ updatedUser.lastname }} </h1>
                    </div>
                    <div class="w-full md:my-[0px] my-[20px] md:w-[calc(100%-240px)] relative bg-white md:mx-[10px] px-[10px] py-[50px]">
                        <linkProfile/>
                        <div class="md:py-[40px] md:px-[20px] px-[10px] py-[30px]">
                            <form @submit.prevent="updateUserProfile">
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">First Name</span>
                                    <div class="relative min-w-[50%] max-w-[55%]">
                                        <input type="text" v-model="updatedUser.firstname" class="border border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full" placeholder="Ex: Nassym">
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">Last Name</span>
                                    <div class="relative min-w-[50%] max-w-[55%]">
                                        <input type="text" v-model="updatedUser.lastname" class="border border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full" placeholder="Ex: ALASSANE">
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">About</span>
                                    <div class="relative min-w-[50%] max-w-[55%]">
                                        <textarea v-model="updatedUser.about" class="border h-[100px] border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full" placeholder="Passionné par l'it Passionné par l'it Passionné par l'it"> </textarea>
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">Job</span>
                                    <div class="relative min-w-[50%] max-w-[55%]">
                                        <input v-model="updatedUser.job" type="text" class="border border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full" placeholder="Analyst cybersécurité">
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">Phone</span>
                                    <div class="relative min-w-[50%] max-w-[55%]">
                                        <input v-model="updatedUser.phone" type="text" class="border border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full" placeholder="Ex: 66656422">
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">Email</span>
                                    <div class="relative min-w-[50%] max-w-[55%]">
                                        <input v-model="updatedUser.email" type="text" class="border border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full" placeholder="Ex: ironsecur@gmail.com">
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">Linkedin Profile</span>
                                    <div class="relative min-w-[50%] max-w-[55%]">
                                        <input v-model="updatedUser.linkedIn" type="text" class="border border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full" placeholder="https://www.linkedin.com/#">
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">Twitter Profile</span>
                                    <div class="relative min-w-[50%] max-w-[55%]">
                                        <input v-model="updatedUser.twitter" type="text" class="border border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full" placeholder="https://www.twitter.com/#">
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">Instagram Profile</span>
                                    <div class="relative min-w-[50%] max-w-[55%]">
                                        <input v-model="updatedUser.instagram" type="text" class="border border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full" placeholder="https://www.instagram.com/#">
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-between">
                                    <span class="text-cyan-600">Facebook Profile</span>
                                    <div class="relative min-w-[50%] max-w-[55%]">
                                        <input v-model="updatedUser.facebook" type="text" class="border border-gray-800 focus:border-cyan-500 text-black outline-none rounded-[5px] py-[5px] px-[10px] w-full" placeholder="https://www.facebook.com/#">
                                    </div>
                                </div>
                                <div class="flex py-[15px] items-center justify-center">
                                    <button type="submit" :disabled="loading" class=" uppercase cursor-pointer font-bold bg-cyan-500 text-white outline-none rounded-[5px] py-[5px] px-[30px] w-auto">
                                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                                        <span v-else>Save</span>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                    
                    
                </div>
            </div>
        </div>
        <div class="w-full border-t border-gray-400 py-[50px] flex justify-center items-center">
            <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
        </div>
    </div>
</template>


<script setup lang="ts">
import headerComponent from '../components/headerComponentAdmin.vue'
import linkProfile from '../components/linkProfile.vue' 
const pageTitle = ref('Profile Edit');
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import axiosInstance from '@/plugins/axios';

const router = useRouter();
    
const loading = ref(false);



onMounted(() => {
    document.title = pageTitle.value; 
});
const updatedUser = ref({});
const errorMessage = ref('');

const fetchUserProfile = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const response = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    updatedUser.value = { ...response.data }; 
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  fetchUserProfile();
});

const updateUserProfile = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    await axiosInstance.put('/auth/users/me/', updatedUser.value, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    errorMessage.value = '';

    router.push('/Profile');
  } catch (error: any) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.message) {
          errorMessage.value = error.response.data.message;
    } else {
          errorMessage.value = 'Une erreur s\'est produite lors de la mise à jour.';
    }
    
  } finally {
        loading.value = false; 
    }
};
</script>

<style>

</style>