<template>
    <div class="w-full relative font-montserrat">
        <headerComponent/>
        <div v-if="errorMessage" class="bg-red-600 border border-red-600 my-[10px] text-white text-center fixed md:top-[80px] top-[60px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
        <div class="relative pt-[70px] w-full mb-[50px]">
          <div class="flex justify-end items-center">
            <div v-if="isNewRoute">
              <button :disabled="loading1" class="text-sm font-bold rounded-[5px] min-w-[60px] p-[10px] border border-gray-400 m-[10px] bg-cyan-800 text-white shadow-lg" @click="saveMail">
                <i v-if="loading1" class="fas fa-spinner fa-spin"></i>
                <span v-else>Send mail</span>
              </button>
            </div>
          </div>
    
            <div class="w-full relative py-[10px] flex justify-center items-center">
                <div>
                 <input type="file" @change="handleFileChange" class="m-[10px] max-w-[calc(100%-52%)] p-[10px]">
                </div>    
                <input type="text" v-model="object"  class="m-[10px] w-full relative p-[10px] border border-gray-400 rounded-[5px] outline-none" placeholder="Enter mail object">
            </div>
            <QuillEditor v-model:content="content" content-type="html" theme="snow" toolbar="full" required="yes" :modules="modules"/>
        </div>
        <div class="w-full py-[20px] flex justify-center items-center">
            <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
        </div>
    </div>
</template>

<script setup lang="ts">
import headerComponent from '@/components/headerComponentAdmin.vue';
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { QuillEditor, Quill } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axiosInstance from '@/plugins/axios';

import BlotFormatter from "quill-blot-formatter/dist/BlotFormatter";


const modules = {
  name: "blotFormatter",
  module: BlotFormatter,
  options: {/* options */},
}

const loading = ref(false);
const loading1 = ref(false);
const loading2 = ref(false);
const router = useRouter();
const pageTitle = ref('Add an mail');

const content = ref('');
const object = ref('');
const selectedFile = ref(null);
const errorMessage = ref("");

const isUpdateRoute = router.currentRoute.value.path.includes("/AddAnMail/") && router.currentRoute.value.params.id;
const isNewRoute = router.currentRoute.value.path === "/AddAnMail";

const selectedMail = ref(null);



onMounted(() => {
    document.title = pageTitle.value;
});

const handleFileChange = (event: any) => {
  selectedFile.value = event.target.files[0];
};

const updateContent = (value: any) => {
  content.value = value;
};

let userId = ref(null);

const saveMail = async () => {
  try {
    loading1.value = true;
    const token = localStorage.getItem('token');
    if (!token) return;


    const userResponse = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    userId.value = userResponse.data.id;

    const formData = new FormData();
    formData.append('object', object.value);
    formData.append('content', content.value);

    if (selectedFile.value) {
      formData.append('stylesheet', selectedFile.value);
    };

    const response = await axiosInstance.post('/mailings/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    let newMailId ;

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const newMailId = response.data.id; 
    }
    router.push('/ListOfMails');
  } catch (error) {
    if(error.response && error.response.status && error.response.status == 400) {
      if(error.response.data && error.response.data.stylesheet) {
        errorMessage.value = "uploaded file: " + error.response.data.stylesheet[0]
      } else if(error.response.data && error.response.data.object) {
        errorMessage.value = "object: " + error.response.data.object[0]
      } else if(error.response.data && error.response.data.content) {
        errorMessage.value = "content: " + error.response.data.content[0]
      } else {
        errorMessage.value = "Unknow error"; 
      }
    } else {
      errorMessage.value = 'Erreur lors de la création ou du sauvegarde de l\'mail';
    }
    
    hideErrorMessageAfterDelay();
  } finally {
    loading1.value = false;
  }
};

const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 5000);
};

onMounted(() => {
  document.title = pageTitle.value;
  const MailId = router.currentRoute.value.params.id;

  if (MailId) {
    loadMailDetails(MailId);
    pageTitle.value = 'Edit an mail';
  }
});

const loadMailDetails = async (id: number) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) return;

    const response = await axiosInstance.get(`/mailings/${id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const mail = response.data;
    selectedMail.value = mail;
    object.value = mail.object;
    content.value = mail.content;
  } catch (error) {
    errorMessage.value = 'Erreur lors du chargement des détails de l\'mail';
    hideErrorMessageAfterDelay();
  }
};

</script>

<style>
    .ql-editor {
        height: 72vh;
    }

</style>