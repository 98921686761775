<template class="max-w-screen">
    <div class="flex font-montserrat flex-col justify-center items-center">
      <HeaderComponent/>
      <div v-if="Successmessage" class="bg-green-600 border border-green-600 my-[10px] text-white text-center font-bold top-[80px] absolute z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">
        {{ Successmessage }}
        <i class="fa-regular fa-face-sad-tear"></i>
      </div>
      <div v-if="Errormessage" class="bg-red-600 border border-red-600 my-[10px] text-white text-center font-bold top-[80px] absolute z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">
        {{ Errormessage }}
        <i class="fa-regular fa-face-sad-tear"></i>
      </div>
      <div class="relative">
          <div id="top" class="lg:h-[80px] h-[50px]"></div>
          <div class="container min-h-[500px] w-full relative flex justify-center items-center px-[10px]">
            <div class="border p-[20px] bg-blue-100">
              <h1 class="w-full text-center font-bold mb-[20px]">IronSecur / Désabonnement Newsletter</h1>
              <p class="text-center">Voulez-vous vraiment vous désabonner de notre newsletter ?</p>
              <button @click="unsubscribe" class="w-full text-center bg-cyan-500 text-white font-bold p-[10px] mt-[20px]">Se désabonner</button>
            </div>
          </div>
      </div>
      
      <FooterComponent/>
    </div>
  </template>
  
  <script setup lang="ts">
  import HeaderComponent from '../components/HeaderComponent.vue'
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import FooterComponent from '@/components/FooterComponent.vue';
  import axiosInstance from '@/plugins/axios';
  
  const router = useRouter();
  function goToContactPage() {
      router.push('/contacts')
  }
  
  const pageTitle = ref('Désabonnement à la newsletter'); 
  
  onMounted(() => {
      document.title = pageTitle.value; 
  });
  
const Successmessage = ref('');
const Errormessage = ref('');
const unsubscribe = async () => {
  try {
    const { id, email } = router.currentRoute.value.params;
    const response = await axiosInstance.delete(`/subscribers/${id}/?email=${email}`);
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      Successmessage.value = `Vous avez été désabonné avec succès de la newsletter.`;
      router.push('/');
    } else {
      Errormessage.value = `Une erreur s'est produite lors de la tentative de désabonnement.`;
    }
  } catch (error) {
    console.error('Erreur lors du désabonnement :', error);
    Errormessage.value = `Une erreur s'est produite lors de la tentative de désabonnement.`;
  }
};
  
  
  </script>
  
  <style>
  
  .parent::-webkit-scrollbar {
    width: 0%;
  }
  </style>