<template>
    <div class=" font-montserrat flex justify-center items-center relative min-w-screen min-h-screen py-[50px] px-[10px] bg-gray-50">
        <div class="w-full flex justify-center items-center flex-col relative py-[50px] md:px-[20px] px-[10px] max-w-[600px] border-2 border-gray-300 h-full">
            <h1 class="text-1xl mb-[20px] text-center font-bold">Connexion</h1>
            <form @submit.prevent="loginUser" class="flex flex-col justify-center items-center w-full relative max-w-[700px]">
                <div class="w-full relative px-[10px] flex justify-center items-center">
                  <div v-if="errorMessage" class="text-red-500 w-full mx-[20px] m-[10px] p-[10px] relative border rounded-[5px] text-center bg-red-100/20 ">{{ errorMessage }}</div>
                </div>
                <div class="py-[10px] w-full px-[10px] relative">
                    <label for="email" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Email</label>
                    <input type="text" v-model="loginData.email" name="email" id="email" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent" placeholder="E.x: ironsecur@gmail.com">
                </div>
                <div class="py-[10px] w-full px-[10px] relative">
                    <label for="password" class="text-sm font-bold left-[20px] text-black-500 absolute top-[12px]">Mot de passe</label>
                    <input  :type="showPassword ? 'text' : 'password'" v-model="loginData.password" name="password" id="password" class="p-[10px] w-full rounded-[5px] border outline-none pt-[25px] bg-transparent" placeholder="E.x: *********">
                    <i class="password-toggle" :class="showPassword ? 'fa fa-eye text-1xl absolute top-[45px] right-[25px]' : 'fa fa-eye-slash text-1xl absolute top-[45px] right-[25px]'" @click="showPassword = !showPassword" ></i>
                </div>
                <div class="py-[10px] w-full px-[10px] relative">
                    <button type="submit">
                        <span @click="goToResetPage()" class="font-bold text-sm text-black hover:underline duration-300 ">Forget password ?</span>
                    </button>
                </div>
                <div class="py-[10px] w-full px-[10px] flex justify-end items-center relative">
                  <button type="submit" :disabled="loading" class="py-[10px] min-w-[100px] px-[20px] rounded-[5px] text-sm font-bold text-white bg-cyan-500 hover:bg-cyan-600 duration-300">
                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                        <span v-else>Log in</span>
                  </button>
                </div>
            </form>
        </div>
        
    </div>
    <div class="w-full border-t border-gray-400 py-[50px] bg-gray-50 flex justify-center items-center">
        <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
    </div>
</template>

<script setup lang="ts">
    import { ref, onMounted } from 'vue';
    import axios from 'axios';
    const pageTitle = ref('Log in');
    const showPassword = ref(false);
    import { useRouter } from 'vue-router';
    import axiosInstance from '@/plugins/axios';

    
    const router = useRouter();

    function goToResetPage() {
      router.push('/resetPassword')
    }


    onMounted(() => {
        document.title = pageTitle.value;
    });

    const loading = ref(false);
    const errorMessage = ref("");
    const loginData = ref({
      email: '',
      password: '',
    });

    const loginUser = async () => {
      try {
        loading.value = true;
        const response = await axiosInstance.post('/auth/jwt/create/', loginData.value);
        const token = response.data.access;
        const errorMessage = ref(response.data);
        localStorage.setItem('token', token);
        errorMessage.value = response.data
        router.push('/dashboard');
      } catch (error) {
        console.error(error);
        errorMessage.value = 'Nom d\'utilisateur ou mot de passe incorrect';
      } finally {
        loading.value = false; 
      }
    };

    const refreshToken = async () => {
      try {
        const currentToken = localStorage.getItem('token');

        const response = await axiosInstance.post('/auth/jwt/refresh/', { refresh: currentToken });
        const newToken = response.data.access; 

        localStorage.setItem('token', newToken);
      } catch (error) {
        console.error(error);
      }
    };


    



</script>

<style>

</style>