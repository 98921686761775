<template>
  <div class="w-full md:p-[40px] p-[20px] px-[0px]" v-for="service in props.services">
                <div class="w-full px-[20px] border-l-[5px] border-blue-600 text-left ">
                    <h1 class="text-2xl font-bold">{{ $t(service.name) }}</h1>
                </div>
                <div class="my-[20px] md:my-[40px] w-full h-auto md:min-h-[400px] px-[10px] md:flex justify-between relative ">
                  <div class="md:w-5/12 relative">
                    <img :src="service.image" alt="" class="relative top-0 w-auto" >
                  </div>
                  <div class="p-[10px] md:p-[50px] md:py-[10px] md:w-7/12 text-left relative">
                    <p class="capitalize text-justify">
                      {{ $t(service.description) }}
                      <br>
                    </p>
                    <div class="w-full relative bg-gray-300 my-[20px] p-[20px] rounded-[5px] ">
                      <h1 class="font-bold">{{ $t('key') }}</h1>
                      <ul class="capitalize">
                        <li class="list-disc mx-[20px]"> {{ $t(service.key[0]) }} </li>
                        <li class="list-disc mx-[20px]"> {{ $t(service.key[1]) }} </li>
                        <li class="list-disc mx-[20px]"> {{ $t(service.key[2]) }} </li>
                        <li class="list-disc mx-[20px]"> {{ $t(service.key[3]) }} </li>
                        <li class="list-disc mx-[20px]"> {{ $t(service.key[4]) }} </li>
                      </ul>
                    </div>
                     <button @click="goTocontactPage()"  class="bg-cyan-500 py-[5px] text-white rounded-[5px] w-full bottom-[10px]">{{ $t("contact_us") }}</button>
                  </div>
            
                </div>
            </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  const router = useRouter()
  
  function goTocontactPage() {
      router.push('/contacts')
  }
    const props = defineProps<{
        services: object,
    }>();

</script>

<style>

</style>