<template>
    <!--
  <div class="fixed bottom-[20px] rounded-[10px] z-[100000000000] bg-white shadow-4xl py-[5px] flex justify-between items-center border border-gray-500 right-[20px]">
    <select class="outline-none p-[5px]" v-model="selectedLanguage" @change="changeLocale">
      <option class="border-none bg-white py-[5px] outline-none" value="en">
         English
      </option>
      <option class="border-none bg-white py-[5px] outline-none" value="fr">
        Français
      </option>
    </select>
  </div>
    -->
  <div translate="no">
    <router-view :key="$route.fullPath + $route.query.refresh"/>
  </div>
</template>

<style lang="scss">

</style>
<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t, locale } = useI18n();

    const supportedLanguages = ["fr", "en"];
    const selectedLanguage = ref(locale.value); 

    const changeLocale = () => {
      locale.value = selectedLanguage.value;
    };
    return {
      supportedLanguages,
      selectedLanguage,
      changeLocale,
    };
  },
};
</script>
