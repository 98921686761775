<template>
    <div class="w-full font-montserrat relative">
        <headerComponent/>
        <div class="relative w-full mb-[50px]">
            <div class="px-[10px] py-[20px] md:p-[20px] flex flex-col items-center relative bg-gray-50 min-h-screen top-[60px]">
                <h1 class="text-black font-bold py-[20px] text-center">Home / List of mails</h1>
                <div class="bg-white w-[100%] md:w-[95%] relative p-[10px]">
                    <div class="text-black flex relative text-sm font-bold bg-white border border-gray-400 p-[10px] w-full">
                        <div class="p-[5px] flex items-center w-[25px]"><input type="checkbox" name="" id=""></div>
                        <div class="p-[5px] flex items-center w-[25px]"></div>
                        <form>
                            <div class="p-[5px] flex items-cenWiter relative">
                                <input type="search" name="search" class="border border-gray-700 pl-[30px] outline-none w-[250px] rounded-[5px] py-[5px] px-[10px] " placeholder="Search..." id="search" @input="handleSearch($event)">
                                <label type="submit" for="search" class="absolute left-[10px] text-black cursor-pointer top-[10px] text-xl"><ion-icon name="search-outline"></ion-icon></label>
                            </div>
                        </form>
                    </div>
                    <table class="w-full relative">
                        <thead class="w-full relative">
                            <tr class="text-black flex relative text-sm font-bold bg-gray-50 border border-gray-400 p-[10px] w-full">
                               <td class="p-[5px] flex w-[50px]">ID</td>
                               <td class="p-[5px] flex flex-1">Object</td>
                               <td class="p-[5px] md:w-[calc(100%/4)] w-[calc(100%/3)]  flex">Sender</td>
                               <td class="p-[5px] md:w-[calc(100%/4)] w-[calc(100%/3)] flex">Date</td>
                            </tr>
                        </thead>
                        <tbody class="w-full relative">
                            <rowArticle :mails="filteredMails" />
                                <tr class="text-gray-600 flex relative text-sm bg-white border border-gray-400 p-[10px] w-full" v-for="mail in filteredMails">
                                  <td class="items-center p-[5px] flex w-[50px]">{{ mail.id }}</td>
                                  <td class="items-center flex-1 p-[5px] flex">
                                      <router-link :to="{ name: 'AddAnMailWith', params: { id: mail.id }}" class="hover:text-black hover:underline duration-300">{{ mail.object }}</router-link>
                                  </td>
                                  <td class="items-center md:w-[calc(100%/4)] w-[calc(100%/3)] p-[5px] flex overflow-x-auto flex-wrap">
                                      {{ mail.author_username }}
                                  </td>
                                   <td class="items-center md:w-[calc(100%/4)] w-[calc(100%/3)] p-[5px] flex overflow-x-auto flex-wrap">
                                      {{ formatDateTimeInEnglish(mail.date) }}
                                  </td>
                              </tr>
                        </tbody>
                        
                    </table>
                    <div class="flex justify-center items-center mt-8 space-x-4">
                        <button
                          @click="loadPreviousPage"
                          :disabled="!hasPreviousPage"
                          :class="{ 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded': hasPreviousPage, 'bg-gray-300 text-gray-500 cursor-not-allowed py-2 px-4 rounded': !hasPreviousPage }"
                        >
                          Previous
                        </button>
                        <button
                          :disabled="true"
                          class="bg-gray-200 text-gray-700 font-bold py-2 px-4 rounded cursor-pointer"
                        >
                          {{ currentPage }} / {{ totalPageCount }}
                        </button>
                        <button
                          @click="loadNextPage"
                          :disabled="!hasNextPage"
                          :class="{ 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded': hasNextPage, 'bg-gray-300 text-gray-500 cursor-not-allowed py-2 px-4 rounded': !hasNextPage }"
                        >
                          Next
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="w-full border-t border-gray-400 py-[50px] flex justify-center items-center">
            <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
        </div>
    </div>
    
</template>

<script setup lang="ts">
import headerComponent from '../components/headerComponentAdmin.vue'
import { ref, onMounted } from 'vue';
import axios from 'axios';
import axiosInstance from '@/plugins/axios';

const pageTitle = ref('List of mails'); 
const mails = ref<any[]>([]);
const filteredMails = ref<any[]>([]);


const allMails = ref<any[]>([]); 

// ...

// ... (votre code existant)

const fetchAllMails = async () => {
    try {
        let allMails: any[] = [];
        let nextPage = '/mailings/';

        while (nextPage) {
            const response = await axiosInstance.get(nextPage);

            if (response.status === 200 || response.status === 201 || response.status === 204) {
                const { results, next } = response.data;
                allMails = allMails.concat(results);
                nextPage = next;
            } else {
                nextPage = null;
            }
        }
        return allMails;
    } catch (error) {
        console.error('Erreur lors du chargement des mails', error);
        return [];
    }
};

const handleSearch = async (event: any) => {
    const searchTerm = event.target.value.toLowerCase().trim();

    if (!searchTerm) {
        filteredMails.value = mails.value;
    } else {
        const allMails = await fetchAllMails();

        if (allMails.length > 0) {
            filteredMails.value = allMails.filter(mail => {
                return (
                    (mail.object && mail.object.toLowerCase().includes(searchTerm)) ||
                    (mail.content && mail.content.toLowerCase().includes(searchTerm))
                );
            });
        }
    }
};




onMounted(async () => {
  document.title = pageTitle.value;
  await fetchMails(); 
  try {
    const response = await axiosInstance.get('/mailings/');
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const fetchedMails = response.data.results;
      // Sort fetched mails by date if needed
      fetchedMails.sort((a: any, b: any) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      mails.value = fetchedMails;
      filteredMails.value = fetchedMails;
    }
  } catch (error) {
    console.error('Erreur lors du chargement des mails', error);
  }

});

const currentPage = ref(1);
const pageSize = 8; 
const hasPreviousPage = ref(false);
const hasNextPage = ref(false);

const loadMails = async (page: number) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) return;

    const response = await axiosInstance.get(`/mailings/?page=${page}&page_size=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const { results, previous, next } = response.data;
      filteredMails.value = results;
      hasPreviousPage.value = !!previous;
      hasNextPage.value = !!next;
    }
  } catch (error) {
    console.error('Erreur lors du chargement des mails', error);
  }
};


const loadPreviousPage = () => {
  if (hasPreviousPage.value) {
    currentPage.value--;
    loadMails(currentPage.value);
  }
};

const loadNextPage = () => {
  if (hasNextPage.value) {
    currentPage.value++;
    loadMails(currentPage.value);
  }
};

onMounted(() => {
  loadMails(currentPage.value);
});

const fetchMails = async () => {
    try {
        const response = await axiosInstance.get('/mailings/');
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            const fetchedMails = response.data.results;
            console.log(fetchedMails)
            fetchedMails.sort((a: any, b: any) => {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
            });
            mails.value = fetchedMails;
            filteredMails.value = fetchedMails;
        }
    } catch (error) {
        console.error('Erreur lors du chargement des mails', error);
    }
};

const totalPageCount = ref(0);

const fetchTotalPageCount = async () => {
  try {
    const response = await axiosInstance.get('/mailings/');
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const totalMails = response.data.count;
      totalPageCount.value = Math.ceil(totalMails / pageSize);
    }
  } catch (error) {
    console.error('Erreur lors du chargement du nombre total de pages', error);
  }
};

onMounted(() => {
  fetchTotalPageCount();
})

const formatDateTimeInEnglish = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDateTime = new Date(dateTimeString).toLocaleString("fr-FR", options);
  return formattedDateTime;
}



</script>


<style>

</style>