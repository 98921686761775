<template>
  <div class=" min-w-[225px] max-w-[225px] max-h-[350px] m-[10px] h-[350px] shadow-4xl p-[5px] rounded-[30px] flex flex-col items-center justify-center" v-for="training in props.trainings">
    <img :src="training.image" alt="" class="w-[65px] h-[65px] rounded-[50%] p-[1px] border border-cyan-600 border-spacing-2 ">
    <a :href="training.link" class="text-cyan-500 text-center text-xl my-[10px]">{{ $t(training.title) }}</a>
    <p class="text-sm text-gray-700 text-center">
        {{ $t(training.content) }}
    </p>
  </div>
</template> 

<script setup lang="ts">
    const props = defineProps<{
        trainings: object,
    }>();

</script>

<style>


</style>