<template>
    <div class="flex font-montserrat flex-col justify-center items-center">
        <HeaderComponent/>
        <div class="relative">
            <div id="top" class="lg:h-[80px] h-[50px]"></div>
            <div class="max-w-full relative">
                <carouselBranding :initial-slide="initialSlide" ></carouselBranding>
            </div>
            <div class="absolute z-[1000000] text-left bg-black/50 px-[20px] md:px-[50px] text-white top-0 left-0 right-0 bottom-0 w-full flex flex-col justify-center ">
                <h1 class="text-4xl md:text-6xl uppercase text-center font-bold opacity-100">{{ $t('aboutus') }}</h1>
            </div>
        </div>


        <div id="founders" class="h-[10px] w-full"></div>
        <section class="my-[50px] py-[30px] flex justify-center items-center w-full relative">
            <div class="container px-[10px] flex justify-center items-center w-full relative">
                <div class="flex justify-center items-center flex-col w-full relative">
                    <div class="flex relative w-full items-center">
                        <h1 class="text-center md:text-2xl text-xl font-bold text-black mb-[30px]">{{ $t("founders") }}</h1>
                        <span class="md:border-b-4 border-b-2 ml-[15px] relative top-[-12px] border-black w-full"></span>
                    </div>
                    <div class="w-full m-[10px] my-[20px] overflow-hidden card md:flex border relative bg-white">
                        <div class="w-full relative p-[25px] pb-[50px] md:p-[50px]">
                            <div class="flex items-center mb-[10px]">
                                <div class="border w-[30px] bg-black">
                                    <img src="../assets/img/logo1.png" alt="">
                                </div>
                                <h1 class="mx-[10px] font-bold">Iron<span class="text-cyan-600">S</span>ecur</h1>
                            </div>
                            <h1 class="md:text-3xl text-2xl font-bold mb-[10px] text-black">Espoir TOMENOU</h1>
                            <h1 class="text-blue-950 font-semibold mb-[10px]">Directeur Général</h1>
                            <div class="text md:max-h-[300px] md:pr-[10px] md:overflow-y-auto">
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("founders11") }}
                                </p>
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("founders12") }}
                                </p>
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("founders13") }}
                                </p>
                            </div>
                            <div class="flex w-full px-[50px] left-0 absolute bottom-[10px] justify-end z-40 items-center text-black flex-wrap">
                                <a target="_blank" href="mailto:service.client@ironsecur.com" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-solid fa-envelope"></i></a>
                                <a target="_blank" href="tel:+22943480141" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-solid fa-phone"></i></a>
                                <a target="_blank" href="https://wa.me/22943480141" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-brands fa-whatsapp"></i></a>
                                <a target="_blank" href="https://www.linkedin.com/in/espoir-tomenou/" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-brands fa-linkedin"></i></a>
                            </div>
                        </div>
                        <div class="imgh w-full relative cursor-pointer md:max-w-[350px] overflow-hidden">
                            <div class="absolute flou bg-black/20 top-0 bottom-0 left-0 right-0 duration-300 ease-in-out w-full h-full"></div>
                            <img src="../assets/img/Espoir.jpg" alt="" class="w-full h-full duration-300 ease-in-out">
                        </div>
                    </div>
                    <div class="w-full m-[10px] my-[20px] overflow-hidden card md:flex border relative bg-white">
                        
                        <div class="w-full relative p-[25px] pb-[50px] md:p-[50px]">
                            <div class="flex items-center mb-[10px]">
                                <div class="border w-[30px] bg-black">
                                    <img src="../assets/img/logo1.png" alt="">
                                </div>
                                <h1 class="mx-[10px] font-bold">Iron<span class="text-cyan-600">S</span>ecur</h1>
                            </div>
                            <h1 class="md:text-3xl text-2xl font-bold mb-[10px] text-black">Nassym ALASSANE</h1>
                            <h1 class="text-blue-950 font-semibold mb-[10px]">Directeur des Opérations</h1>
                            <div class="text md:max-h-[248px] md:pr-[10px] md:overflow-y-auto">
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("founders21") }}
                                </p>
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("founders22") }}
                                </p>
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("founders23") }}
                                </p>
                            </div>
                            <div class="flex w-full px-[50px] left-0 absolute bottom-[10px] justify-end z-40 items-center text-black flex-wrap">
                                <a target="_blank" href="mailto:service.client@ironsecur.com" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-solid fa-envelope"></i></a>
                                <a target="_blank" href="tel:+22943480141" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-solid fa-phone"></i></a>
                                <a target="_blank" href="https://wa.me/22943480141" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-brands fa-whatsapp"></i></a>
                                <a target="_blank" href="https://www.linkedin.com/in/nassym-alassane/" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-brands fa-linkedin"></i></a>
                            </div>
                        </div>
                        <div class="imgh w-full relative cursor-pointer md:max-w-[350px] overflow-hidden">
                            <div class="absolute flou bg-black/20 top-0 bottom-0 left-0 right-0 duration-300 ease-in-out w-full h-full"></div>
                            <img src="../assets/img/Nassym.jpg" alt="" class="w-full h-full duration-300 ease-in-out">
                        </div>
                    </div>
                    <div class="w-full m-[10px] my-[20px] overflow-hidden card md:flex border relative bg-white">
                        <div class="w-full relative p-[25px] pb-[50px] md:p-[50px]">
                            <div class="flex items-center mb-[10px]">
                                <div class="border w-[30px] bg-black">
                                    <img src="../assets/img/logo1.png" alt="">
                                </div>
                                <h1 class="mx-[10px] font-bold">Iron<span class="text-cyan-600">S</span>ecur</h1>
                            </div>
                            <h1 class="md:text-3xl text-2xl font-bold mb-[10px] text-black">Olusegun Yayi </h1>
                            <h1 class="text-blue-950 font-semibold mb-[10px]">Directeur Technique</h1>
                            <div class="text md:max-h-[300px] md:pr-[10px] md:overflow-y-auto">
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("founders31") }}
                                </p>
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("founders32") }}
                                </p>
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("founders33") }}
                                </p>
                            </div>
                            <div class="flex w-full px-[50px] left-0 absolute bottom-[10px] justify-end z-40 items-center text-black flex-wrap">
                                <a href="mailto:service.client@ironsecur.com" target="_blank" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-solid fa-envelope"></i></a>
                                <a target="_blank" href="tel:+22943480141" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-solid fa-phone"></i></a>
                                <a target="_blank" href="https://wa.me/22943480141" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-brands fa-whatsapp"></i></a>
                                <a href="https://www.linkedin.com/in/segunyayi/" target="_blank" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-brands fa-linkedin"></i></a>
                            </div>
                        </div>
                        <div class="imgh w-full relative cursor-pointer md:max-w-[350px] overflow-hidden">
                            <div class="absolute flou bg-black/20 top-0 bottom-0 left-0 right-0 duration-300 ease-in-out w-full h-full"></div>
                            <img src="../assets/img/Segun.jpg" alt="" class="w-full h-full duration-300 ease-in-out">
                        </div>
                    </div>
                    <div class="w-full m-[10px] my-[20px] overflow-hidden card md:flex border relative bg-white">
                        
                        <div class="w-full relative p-[25px] pb-[50px] md:p-[50px]">
                            <div class="flex items-center mb-[10px]">
                                <div class="border w-[30px] bg-black">
                                    <img src="../assets/img/logo1.png" alt="">
                                </div>
                                <h1 class="mx-[10px] font-bold">Iron<span class="text-cyan-600">S</span>ecur</h1>
                            </div>
                            <h1 class="md:text-3xl text-2xl font-bold mb-[10px] text-black">Oloufunke Gloria Igue</h1>
                            <h1 class="text-blue-950 font-semibold mb-[10px]">Directrice Marketing</h1>
                            <div class="text md:max-h-[248px] md:pr-[10px] md:overflow-y-auto">
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("break") }}
                                </p>
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("founders41") }}
                                </p>
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("founders42") }}
                                </p>
                                <p class="text-sm text-black mb-[10px]">
                                    {{ $t("founders43") }}
                                </p>
                            </div>
                            <div class="flex w-full px-[50px] left-0 absolute bottom-[10px] justify-end z-40 items-center text-black flex-wrap">
                                <a ></a>
                                <a target="_blank" href="mailto:service.client@ironsecur.com" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-solid fa-envelope"></i></a>
                                <a target="_blank" href="tel:+22943480141" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-solid fa-phone"></i></a>
                                <a target="_blank" href="https://wa.me/22943480141" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-brands fa-whatsapp"></i></a>
                                <a target="_blank" href="https://www.linkedin.com/in/gloria-igue-51917147/" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-brands fa-linkedin"></i></a>
                            </div>
                        </div>
                        <div class="imgh w-full relative cursor-pointer md:max-w-[350px] overflow-hidden">
                            <div class="absolute flou bg-black/20 top-0 bottom-0 left-0 right-0 duration-300 ease-in-out w-full h-full"></div>
                            <img src="../assets/img/Gloria_IGUE.jpeg" alt="" class="w-full h-full duration-300 ease-in-out">
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        <div id="vision" class="h-[10px] w-full"></div>
        <section class="mt-[50px] pb-[20px] md:pb-0 bg-gray-200 flex justify-center items-center w-full relative">
            <div class="flex container w-full relative px-[10px] justify-center items-center">
                <div class="md:flex relative w-full ">
                    <div class="md:w-4/12 w-full p-[10px] flex justify-center flex-col items-center">
                        <img src="../assets/img/pngegg.png" alt="" class="w-full">
                    </div>
                    <div class="md:w-8/12 w-full p-[10px] flex justify-center flex-col items-center">
                        <h1 class="my-[10px] font-bold md:text-2xl text-xl text-black text-center">{{ $t("vision") }}</h1>
                        <p class="text-gray-700 font-semibold text-center">
                            {{ $t("vision_text") }}
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <div id="mission" class="h-[10px] bg-blue-100/70 w-full"></div>
        <section class=" py-[50px] pt-[100px] overflow-y-auto bg-blue-100/70 flex justify-center items-center w-full relative">
            <div class="flex container w-full relative px-[10px] overflow-y-auto justify-center items-center">
                <div class="md:flex relative w-full ">
                    <div class="md:w-7/12 w-full p-[10px]">
                        <h1 class="my-[10px] md:text-2xl text-xl font-bold text-black text-center">{{ $t("mission") }}</h1>
                        <p class="text-gray-700 font-semibold">
                            {{ $t("mission_text") }}
                        </p>
                    </div>
                    <div class="md:w-5/12 w-full flex justify-center flex-wrap relative z-50 items-center p-[10px]">
                        <img src="../assets/img/vision1.png" alt="" class="relative w-[calc(100%-60%)] border-4 rounded-[10px] m-[5px] top-[-25px] border-white z-10">
                        <img src="../assets/img/vision2.png" alt="" class="relative w-[calc(100%-60%)] border-4 rounded-[10px] m-[5px] border-white z-30 shad">
                        <img src="../assets/img/vision4.png" alt="" class="relative w-[calc(100%-60%)] border-4 rounded-[10px] m-[5px] top-[-25px] border-white z-30 shad">
                        <img src="../assets/img/vision5.png" alt="" class="relative w-[calc(100%-60%)] border-4 rounded-[10px] m-[5px] border-white z-10">
                        <img src="../assets/img/vision.png" alt="" class="relative w-[calc(100%-60%)] border-4 rounded-[10px] m-[5px] top-[-25px] border-white z-30 shad">
                        <img src="../assets/img/vision7.png" alt="" class="relative w-[calc(100%-60%)] border-4 rounded-[10px] m-[5px] border-white z-10">
                    </div>
                </div>
            </div>
        </section>
        <div id="val" class="h-[10px] w-full"></div>
        <section class="w-full relative my-[50px] py-[20px] flex justify-center items-center">
            <div class="container max-w-[1024px] px-[10px] flex flex-col justify-center items-center">
                <h1 class="text-center uppercase font-bold text-xl">Nos valeurs</h1>
                <div class="flex justify-center items-center flex-wrap">
                    <div class="w-[200px] h-[200px] bg-white shadow-4xl md:m-[20px] m-[10px] flex justify-center items-center flex-col rounded-[5px]">
                        <div class="w-[50px] h-[50px] rounded-[50%] flex justify-center items-center bg-cyan-500 text-white">
                            <i class="fa-solid fa-smile"></i>
                        </div>
                        <h1 class="text-sm text-center font-bold my-[10px]">Satisfaction cliente</h1>
                    </div>
                    <div class="w-[200px] h-[200px] bg-white shadow-4xl md:m-[20px] m-[10px] flex justify-center items-center flex-col rounded-[5px]">
                        <div class="w-[50px] h-[50px] rounded-[50%] flex justify-center items-center bg-cyan-500 text-white">
                            <i class="fa-solid fa-lock"></i>
                        </div>
                        <h1 class="text-sm text-center font-bold my-[10px]">Intégrité</h1>
                    </div>
                    <div class="w-[200px] h-[200px] bg-white shadow-4xl md:m-[20px] m-[10px] flex justify-center items-center flex-col rounded-[5px]">
                        <div class="w-[50px] h-[50px] rounded-[50%] flex justify-center items-center bg-cyan-500 text-white">
                            <i class="fa-solid fa-handshake"></i>
                        </div>
                        <h1 class="text-sm text-center font-bold my-[10px]">Transparence</h1>
                    </div>
                </div>
            </div>
        </section>
        <div id="direction" class="h-[10px] w-full"></div>
        <section class="mt-[50px] pb-[100px] w-full relative flex bg-gray-100 flex-col justify-center items-center">
            <div class="w-screen border-b-4 border-gray-900 relative flex justify-center">
                <span class="relative uppercase md:text-2xl text-xl text-black bg-gray-100 font-semibold p-[10px] text-center top-[25px]"> {{ $t("staff") }} </span>
            </div>
            <div class="container mt-[50px] flex justify-center items-center w-full relative px-[10px]">
                <div class="flex w-full relative justify-center items-center flex-wrap">
                    <div ref="windowDiv" class="staff w-[90%] h-[350px] cursor-pointer overflow-hidden max-w-[250px] m-[10px] rounded-[20px] border relative bg-white">
                        <img src="../assets/img/Ulrich.png" alt="" class="rounded-t-[15px] w-full h-[248px] bg-cover">
                        <div class="p-[10px] w-full relative">
                            <h1 class="text-sm font-bold text-center text-black">Full Stack Developper</h1>
                            <p class="text-gray-700 my-[10px] w-full text-center text-xs font-semibold">Ulrich Doevi</p>
                            <div class="flex w-full relative justify-center z-40 items-center text-black flex-wrap">
                                <a target="_blank" href="mailto:service.client@ironsecur.com" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-solid fa-envelope"></i></a>
                                <a target="_blank" href="tel:+22943480141" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-solid fa-phone"></i></a>
                                <a target="_blank" href="https://wa.me/+22943480141" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-brands fa-whatsapp"></i></a>
                                <a target="_blank" href="https://www.linkedin.com/in/damien-ulrich-doevi-396016239/" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-brands fa-linkedin"></i></a>
                            </div>
                        </div>
                        <div class="window absolute bottom-0 h-[350px] text-white font-semibold text-xs flex justify-center items-start w-full translate-y-[100%] p-[20px] ease-in-out duration-300 ">
                            <div class="max-h-[260px] oa overflow-auto">
                                <p class=" mb-[10px]">
                                    {{ $t("staff11") }}
                                </p>
                                <p>
                                    {{ $t("staff12") }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- <div ref="windowDiv" class="staff w-[90%] h-[350px] cursor-pointer overflow-hidden max-w-[250px] m-[10px] rounded-[20px] border relative bg-white">
                        <img src="../assets/img/Odile.png" alt="" class="rounded-t-[15px] w-full h-[248px]">
                        <div class="p-[10px] w-full relative">
                            <h1 class="text-sm font-bold text-center text-black">Community Manager</h1>
                            <p class="text-gray-700 my-[10px] w-full text-center text-xs font-semibold">Odile Lalou</p>
                            <div class="flex w-full relative justify-center z-40 items-center text-black flex-wrap">
                                <a target="_blank" href="mailto:service.client@ironsecur.com" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-solid fa-envelope"></i></a>
                                <a target="_blank" href="tel:+22943480141" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-solid fa-phone"></i></a>
                                <a target="_blank" href="https://wa.me/+22943480141" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-brands fa-whatsapp"></i></a>
                                <a target="_blank" href="https://www.linkedin.com/in/odile-lalou-511566214" class="my-[5px] mx-[10px] ml-[0px] text-sm"><i class="fa-brands fa-linkedin"></i></a>
                            </div>
                        </div>
                        <div class="window absolute bottom-0 h-[350px] text-white font-semibold text-xs flex justify-center items-start w-full translate-y-[100%] p-[20px] ease-in-out duration-300 ">
                            <div class="max-h-[260px] oa overflow-auto">
                                <p class="mb-[10px]">
                                    {{ $t("staff21") }}
                                </p>
                                <p>
                                    {{ $t("staff22") }}
                                </p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
    </div>

    <FooterComponent/>
</template>

<script setup lang="ts">
import HeaderComponent from '../components/HeaderComponent.vue'
import carouselBranding from '../components/CarouselBranding.vue'
import FooterComponent from '@/components/FooterComponent.vue';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const initialSlide = 6;
const pageTitle = ref('IronSecur | About us');

onMounted(() => {
    document.title = pageTitle.value;
});

const windowDiv = ref<HTMLElement | null>(null);

const toggleBodyClass = () => {
    if (windowDiv.value) {
        windowDiv.value.classList.toggle('open');
    }
};

</script>


<style lang="css" scoped>
.clip1 {
    clip-path: polygon(0 5%, 100% 0%, 100% 91%, 0% 100%);
}
.clip1::before {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
    background-color: rgba(255, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: -50px;

}
.fancy1 {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
    border-width: 4px 0px 4px 0px;
    border-style: dashed;
    border-color: black transparent red transparent;
}

.open .ouvrir {
    display: none;
}
.open .fermer {
    display: block;
}
.window {
    backdrop-filter: blur(40px);
    background-color: rgba(0, 0, 0, 0.7);
}
.staff {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.card {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.staff:hover .window {
    transform: translateY(0%);
}
.staff:hover a i {
    color: white;
}
.text::-webkit-scrollbar, .oa::-webkit-scrollbar {
    width: 0%;
}
.imgh:hover img {
    transform: scale(1.2);
    z-index: 100;
}
.flou {
    display: none;
}
.imgh:hover .flou {
    display: block;
    z-index: 1000;
}
</style>