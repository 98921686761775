<template>
    <div class="w-full relative font-montserrat">
        <headerComponent/>
        <div v-if="errorMessage" class="bg-red-600 border border-red-600 my-[10px] text-white text-center fixed md:top-[80px] top-[60px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
        <div class="relative pt-[70px] w-full mb-[50px]">
          <div class="flex justify-end items-center">
            <button v-if="isUpdateRoute" :disabled="loading2" class="text-sm font-bold rounded-[5px] p-[10px] min-w-[100px] border border-gray-400 m-[10px] shadow-lg" @click="updateArticle">
              <i v-if="loading2" class="fas fa-spinner fa-spin"></i>
              <span v-else>Mettre à jour</span>
            </button>
            <div v-else-if="isNewRoute">
              <button :disabled="loading1" class="text-sm font-bold rounded-[5px] min-w-[60px] p-[10px] border border-gray-400 m-[10px] bg-cyan-800 text-white shadow-lg" @click="saveArticle">
                <i v-if="loading1" class="fas fa-spinner fa-spin"></i>
                <span v-else>Save</span>
              </button>
              <button :disabled="loading" class="text-sm font-bold rounded-[5px] p-[10px] border border-gray-400 m-[10px] bg-blue-800 text-white shadow-lg min-w-[100px]" @click="publishArticle">
                <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                <span v-else>Publish</span>
              </button>
            </div>
          </div>
            <input type="text" v-model="title" class="w-full p-[20px] outline-none text-2xl border border-gray-400 font-bold text-black" placeholder="Enter article title">

            

            <div class="w-full relative py-[10px] flex justify-center items-center">
                <div class="w-[75px] h-[50px] relative overflow-hidden flex justify-center items-center">
                  <img :src="viewImage" alt="">
                </div>
                <input type="file" @change="handleImageChange" class="m-[10px] max-w-[calc(100%-52%)] p-[10px]" placeholder="La taille de l'image ne doit pas dépasser 2Mo">
                <input type="text" v-model="source"  class="m-[10px] w-full relative p-[10px] border max-w-[calc(100%-52%)] border-gray-400 rounded-[5px] outline-none" placeholder="Enter article source">
            </div>
            <QuillEditor v-model:content="content" content-type="html" theme="snow" toolbar="full" required="yes" :modules="modules"/>
        </div>
        <div class="w-full py-[20px] flex justify-center items-center">
            <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
        </div>
    </div>
</template>

<script setup lang="ts">
import headerComponent from '@/components/headerComponentAdmin.vue';
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { QuillEditor, Quill } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axiosInstance from '@/plugins/axios';

import BlotFormatter from "quill-blot-formatter/dist/BlotFormatter";


const modules = {
  name: "blotFormatter",
  module: BlotFormatter,
  options: {/* options */},
}

const loading = ref(false);
const loading1 = ref(false);
const loading2 = ref(false);
const router = useRouter();
const pageTitle = ref('Add an article');

const content = ref('');
const title = ref('');
const publishedArticles = ref([]);
const source = ref('');
const selectedImage = ref(null);
const errorMessage = ref("");
const viewImage = ref('')

const isUpdateRoute = router.currentRoute.value.path.includes("/AddAnArticle/") && router.currentRoute.value.params.id;
const isNewRoute = router.currentRoute.value.path === "/AddAnArticle";



onMounted(() => {
    document.title = pageTitle.value;
});

const handleImageChange = (event: any) => {
  selectedImage.value = event.target.files[0];
};

const updateContent = (value: any) => {
  content.value = value;
};

let userId = ref(null);

const saveArticle = async () => {
  try {
    loading1.value = true;
    const token = localStorage.getItem('token');
    if (!token) return;


    const userResponse = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    userId.value = userResponse.data.id;

    const formData = new FormData();
    formData.append('title', title.value);
    formData.append('content', content.value);
    if (selectedImage.value) {
      formData.append('image', selectedImage.value);
    };
    formData.append('source', source.value);
    if (userId.value) {
      formData.append('author_id', userId.value);
    };

    const response = await axiosInstance.post('/articles/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    let newArticleId ;

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const newArticleId = response.data.id; 
    }
    router.push('/ListeOfArticle');
  } catch (error) {
    errorMessage.value = 'Erreur lors de la création ou du sauvegarde de l\'article';
    hideErrorMessageAfterDelay();
  } finally {
    loading1.value = false;
  }
};
const publishArticle = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem('token');
    if (!token) return;


    const userResponse = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    userId.value = userResponse.data.id;

    const formData = new FormData();
    formData.append('title', title.value);
    formData.append('content', content.value);
    if (selectedImage.value) {
      formData.append('image', selectedImage.value);
    };
    formData.append('source', source.value);
    if (userId.value) {
      formData.append('author_id', userId.value);
    };

    const response = await axiosInstance.post('/articles/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    let lastArticleId ;

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      try {
        const response = await axiosInstance.get('/articles/');
        if (response.status === 200 || response.status === 201 || response.status === 204) {
          const articles = response.data.results;
          if (articles.length > 0) {
            const lastArticle = articles[0];
            lastArticleId = lastArticle.id;
          }
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des articles', error);
      }
      const newArticleId = lastArticleId;

      const publishResponse = await axiosInstance.put(`/articles/${newArticleId}/publish/`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (publishResponse.status === 204 || publishResponse.status === 201 || publishResponse.status === 2040 ) {
        console.log('Article publié avec succès:', newArticleId);

        title.value = '';
        content.value = '';
        source.value = '';
        selectedImage.value = null;
      } else {
        console.error('Erreur lors de la publication de l\'article');
      }
    }
    router.push('/ListeOfArticle');
  } catch (error) {
    errorMessage.value = 'Erreur lors de la création ou de la publication de l\'article';
    hideErrorMessageAfterDelay();
  } finally {
    loading.value = false;
  }
};
const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 5000);
};

onMounted(() => {
  document.title = pageTitle.value;
  const articleId = router.currentRoute.value.params.id;

  if (articleId) {
    loadArticleDetails(articleId);
    pageTitle.value = 'Update an article';
  }
});
const selectedArticle = ref(null);

const loadArticleDetails = async (id: number) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) return;

    const response = await axiosInstance.get(`/articles/${id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const article = response.data;
    selectedArticle.value = article;
    title.value = article.title;
    content.value = article.content;
    source.value = article.source;
    viewImage.value = article.image;
  } catch (error) {
    errorMessage.value = 'Erreur lors du chargement des détails de l\'article';
    hideErrorMessageAfterDelay();
  }
};

const updateArticle = async () => {
  try {
    loading2.value = true;
    const token = localStorage.getItem('token');
    if (!token) return;

    const userResponse = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    userId.value = userResponse.data.id;

    const response = await axiosInstance.patch(`/articles/${selectedArticle.value.id}/`, {
      title: title.value,
      content: content.value,
      source: source.value,
      image: selectedImage.value,
      author: userId.value
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      title.value = '';
      content.value = '';
      source.value = '';
      selectedImage.value = null;
      router.push('/ListeOfArticle');
    } else {
    }
  } catch (error) {
    errorMessage.value = 'Erreur lors de la mise à jour de l\'article: Vous n\'avez pas les autorisations nécessaires';
    hideErrorMessageAfterDelay();
  } finally {
    loading2.value = false;
  }
};


</script>

<style>
    .ql-editor {
        height: 72vh;
    }

</style>