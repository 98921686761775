<template id="en">
  <nav class=" fixed max-w-screen top-0 left-0 z-[10000000000] w-screen px-5 bg-white shadow lg:flex lg:items-center lg:justify-between">
    <div class="cursor-pointer flex justify-between items-center">
        <img src="../assets/img/logo.png" @click="goTohomePage()" alt="" class="lg:h-20 lg:min-w-[100px] h-14 lg:mx-3 z-50">
        <span class="text-3xl cursor-pointer mt-[10px] mx-2 lg:hidden block" @click="toggleMenu">
            <ion-icon :name="menuIcon"></ion-icon>
        </span>
    </div>
    <ul class="lg:flex md:items-center duration-300 ease-in-out lg:z-auto lg:static absolute bg-white w-full left-0 lg:w-auto lg:py-0 py-4 lg:pl-0 pl-7 lg:opacity-100"
        :class="{
          'top-[50px]': isMenuOpen,
          'opacity-100': isMenuOpen,
          'opacity-0': !isMenuOpen,
          'top-[-400px]': !isMenuOpen
        }">        <li class="mx-4 my-6 lg:my-0">
            <router-link to="/"  :class="{
                'text-xl hover:text-cyan-500 duration-500': true,
                'font-bold text-cyan-500': $route.path === '/'
            }">
                {{ $t('home') }}
            </router-link>
        </li>
        <div class="relative" @mouseover="isOpen = true" @mouseleave="isOpen = false">
            <div :class="{
                'text-xl hover:text-cyan-500 lg:text-center flex items-center duration-500 px-4 rounded': true,
                'font-bold text-cyan-500': $route.path === '/about',
            }">
              {{ $t('aboutus') }}
              <div v-if="isOpen">
                <i  class="fa-solid fa-chevron-up text-xs font-bold ml-[10px] duration-300"></i>
              </div>
              <div v-else>
                <i  class="fa-solid fa-chevron-down text-xs font-bold ml-[10px] duration-300"></i>
              </div>
            </div>
        
            <div v-if="isOpen" class=" bg-white pl-[20px] lg:pl-0 lg:py-2 md:w-[300px] lg:border lg:absolute lg:shadow-lg w-full z-10">
              <router-link to="/about#founders" @click="closeMenu" class="block px-4 py-2 text-gray-800 hover:bg-gray-200">{{ $t('founders') }}</router-link>
              <router-link to="/about#vision" @click="closeMenu" class="block px-4 py-2 text-gray-800 hover:bg-gray-200">{{ $t('vision') }}</router-link>
              <router-link to="/about#mission" @click="closeMenu" class="block px-4 py-2 text-gray-800 hover:bg-gray-200">{{ $t('mission') }}</router-link>
              <router-link to="/about#val" @click="closeMenu" class="block px-4 py-2 text-gray-800 hover:bg-gray-200">Nos valeurs</router-link>
              <router-link to="/about#direction" @click="closeMenu" class="block px-4 lg:py-2 text-gray-800 hover:bg-gray-200">{{ $t('staff') }}</router-link>
            </div>
        </div>
        <li class="mx-4 my-6 lg:my-0">
            <router-link to="/training" :class="{
                'text-xl hover:text-cyan-500 duration-500': true,
                'font-bold text-cyan-500': $route.path === '/training' || $route.path === '/training1' || $route.path === '/trainingFS',
            }">
                {{ $t('training') }}
            </router-link>
        </li>
        <div class="relative" @mouseover="isOpen1 = true" @mouseleave="isOpen1 = false">
            <div :class="{
                'text-xl hover:text-cyan-500 lg:text-center flex items-center backdrop: duration-500 px-4 rounded': true,
                'font-bold text-cyan-500': $route.path === '/services',
            }">
              Nos expertises 
              <div v-if="isOpen1">
                <i  class="fa-solid fa-chevron-up text-xs font-bold ml-[10px]"></i>
              </div>
              <div v-else>
                <i  class="fa-solid fa-chevron-down text-xs font-bold ml-[10px]"></i>
              </div>
            </div>
            <div v-if="isOpen1" class="lg:absolute lg:suis bg-white lg:border lg:min-w-[400px] min-w-full w-full px-[10px] pt-[10px] lg:pb-[10px] flex-col justify-center items-start flex lg:shadow-lg z-10">
                <router-link to="/services#services" @click="closeMenu" class="block px-4 py-2 text-gray-800 w-full hover:bg-gray-200">Nos services</router-link>
                <div class="accordion block px-4 py-2 relative text-gray-800 hover:bg-gray-200 w-full">
                    <div class="accordion-item relative w-full">
                        <button class="accordion-title duration-200 w-full flex justify-between items-center" @click="handlePack">
                            <span class="text-black text-left">Nos offres</span>
                            <div v-if="pack"><i class="fa-solid fa-minus but ml-[20px] duration-200"></i></div>
                            <div v-else><i class="fa-solid fa-plus but ml-[20px] duration-200"></i></div>
                        </button>
                        <ul class="accordion-content duration-200" v-if="pack">
                            <li class="list-disc ml-[20px] hover:text-cyan-500">
                                <router-link to="/services#trust" @click="closeMenu" class="py-2 hover:text-cyan-500 flex items-center text-gray-800 hover:bg-gray-200">
                                    Pack «<b>Trust</b>»
                                </router-link>
                            </li>
                            <li class="list-disc ml-[20px] hover:text-cyan-500">
                                <router-link to="/services#pick"  @click="closeMenu" class="py-2 hover:text-cyan-500 flex items-center text-gray-800 hover:bg-gray-200">
                                    Pack «<b>Pick & Choose</b>»
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <router-link to="/services#operons"  @click="closeMenu" class="block px-4 py-2 w-full text-gray-800 hover:bg-gray-200">Comment Opérons Nous ?</router-link>
            </div>
        </div>        
        <div class="relative mt-6 lg:mt-0" @mouseover="isOpen3 = true" @mouseleave="isOpen3 = false">
            <div :class="{
                'text-xl hover:text-cyan-500 lg:text-center flex items-center duration-500 px-4 rounded': true,
                'font-bold text-cyan-500': $route.path === '/news' || $route.path === '/articles',
            }">
              Nouveautés
              <div v-if="isOpen3">
                <i  class="fa-solid fa-chevron-up text-xs font-bold ml-[10px] duration-300"></i>
              </div>
              <div v-else>
                <i  class="fa-solid fa-chevron-down text-xs font-bold ml-[10px] duration-300"></i>
              </div>
            </div>
        
            <div v-if="isOpen3" class=" bg-white pl-[20px] lg:pl-0 lg:py-2 md:w-[300px] lg:border lg:absolute lg:shadow-lg w-full z-10">
                <router-link to="/news" @click="closeMenu" class="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                    {{ $t('news') }}
                </router-link>
              <router-link to="/articles" @click="closeMenu" class="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                {{ $t('articles') }}
              </router-link>
            </div>
        </div>
        <li class="mx-4 my-6 lg:my-0">
            <router-link to="/contacts" :class="{
                'text-xl hover:text-cyan-500 duration-500': true,
                'font-bold text-cyan-500': $route.path === '/contacts'
            }">
                {{ $t('contacts') }}
            </router-link>
        </li>
        <li class="mx-4 my-6 lg:my-0" v-if="!isUserLoggedIn()">
            <router-link to="/log" :class="{
                'text-xl px-[10px] py-[5px] bg-cyan-500 text-white border-2 border-cyan-500 rounded-[5px] duration-300': true,
            }">
              {{ $t('login') }}
            </router-link>
        </li>
        <li class="mx-4 my-6 lg:my-0" v-else>
            <router-link to="/dashboard" :class="{
                'text-xl px-[10px] py-[5px] bg-cyan-500 text-white border-2 border-cyan-500 rounded-[5px] duration-300': true,
            }">
              Dashboard
            </router-link>
        </li>
    </ul>
  </nav>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import { ref } from 'vue';

import { useRouter } from 'vue-router';
export default {
    data(){
        return {
            list: document.querySelector("ul"),
            isMenuOpen: false,
            pack: false
        }
    }, 
    methods: {
        Menu(e){
            const list = document.querySelector("ul");
            if(list){
                e.name === "menu" ? (e.name = 'close' , 
                list.classList.add('top-[50px]') , 
                list.classList.add('opacity-100')) : (e.name = 'menu' ,
                list.classList.remove('top-[50px]') ,
                list.classList.remove('opacity-100'))
        }
        },
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        handlePack() {
            this.pack = !this.pack;
        },closeMenu() {
            this.isMenuOpen = false;
            this.pack = false // Ferme le menu
        },
    },
    computed: {
        menuIcon() {
            return this.isMenuOpen ? "close" : "menu";
        },
    },
    setup() {
        const router = useRouter();
        const isOpen = ref(false);
        const isOpen1 = ref(false);
        const isOpen3 = ref(false);
        const isOpen12 = ref(false);

        function goTohomePage() {
          router.push('/');
        }

        function isUserLoggedIn() {
          const token = localStorage.getItem('token');
          return !!token; // Retourne vrai si le token existe, faux sinon
        }

        return {
          goTohomePage,
          isUserLoggedIn,
          isOpen,
          isOpen1,
          isOpen3,
          isOpen12,
        };
    },
}
</script>

<style scoped>
    .suis::before {
        content: "";
        width: 0px;
        height: 0px;
        border-width: 6px;
        border-style: solid;
        border-color: transparent transparent grey transparent;
        position: absolute;
        top: -12px;
        left: 50px;
    }
</style>