<template>
    <div class="w-full mb-[50px] h-full font-montserrat relative">
        <headerComponent/>
        <div class="relative w-full">
            <div class="p-[20px] relative bg-gray-50 min-h-screen top-[60px]">
                <h1 class="text-black font-bold py-[20px] md:py-[40px] text-center">Home / User's liste</h1>
                <ul  v-if="isSuperUser">
                  <li v-for="user in users" :key="user.id" class="w-full p-[10px] min-h-[50px] border flex justify-between items-center">
                    <span class="md:w-[calc(100%/3)]">{{ user.firstname }} {{ user.lastname }}</span>
                    <span class="text-left md:flex hidden w-full md:w-[calc(100%/3)] max-w-[400px]">{{ user.email }}</span>
                    <div class="md:w-[calc(100%/3)] flex justify-end">
                      <button v-if="user.is_active" @click="deactivateUser(user.id)" class="bg-red-500 text-white p-[10px]">Désactiver</button>
                      <button v-else @click="activateUser(user.id)" class="bg-green-500 text-white p-[10px]">Activer</button>
                    </div>
                  </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="w-full pb-[10px] border-t border-gray-400 pt-[50px] flex justify-center items-center">
        <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
    </div>
</template>

<script setup lang="ts">
import headerComponent from '../components/headerComponentAdmin.vue'
import { ref, onMounted } from 'vue';
import axiosInstance from '@/plugins/axios';

const pageTitle = ref("User's liste")
const errorMessage = ref('')
let isSuperUser = ref(false);
let user = ref({});
const users = ref([]);

onMounted(() => {
  document.title = pageTitle.value;
});

const fetchUserProfile = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const response = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    user.value = response.data;
    isSuperUser.value = response.data.is_staff;
  } catch (error) {
    console.error(error);
  }
};

const fetchAllUsers = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    let nextUrl = '/auth/users/';

    while (nextUrl) {
      const response = await axiosInstance.get(nextUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        users.value = [...users.value, ...response.data.results];
        nextUrl = response.data.next;
      } else {
        break; // Sortir de la boucle si la requête échoue
      }
    }
  } catch (error) {
    console.error('Erreur lors du chargement des utilisateurs', error);
  }
};

const activateUser = async (userId: any) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const userToActivate = users.value.find(user => user.id === userId);

    await axiosInstance.put(`/auth/users/${userId}/activate/`);

    window.location.reload();
    fetchAllUsers();
  } catch (error) {
    console.error('Erreur lors de l\'activation de l\'utilisateur', error);
  }
};

const deactivateUser = async (userId: any) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const userToDeactivate = users.value.find(user => user.id === userId);

    await axiosInstance.put(`/auth/users/${userId}/desactivate/`);

    // Mettez à jour la liste des utilisateurs après la désactivation
    window.location.reload();
    fetchAllUsers();
  } catch (error) {
    console.error('Erreur lors de la désactivation de l\'utilisateur', error);
  }
};

onMounted(() => {
  fetchUserProfile();
  fetchAllUsers();
});
</script>
<style>

</style>