<template class="max-w-screen">
    <div class="flex font-montserrat flex-col justify-center items-center">
      <HeaderComponent/>
      <div class="relative">
          <div class="lg:h-[80px] h-[50px]"></div>
          <carouselBranding :initial-slide="initialSlide" ></carouselBranding>
          <div class="absolute z-[1000000] text-left bg-black/50 px-[20px] md:px-[50px] text-white top-0 left-0 right-0 bottom-0 w-full flex items-center flex-col justify-center ">
              <h1 class="text-4xl md:text-6xl uppercase text-center font-bold opacity-100">Services</h1>
              
  
          </div>
      </div>
      <div class="container max-w-[1024px]">
        <div class="w-full relative flex justify-center items-center md:mt-[50px] mt-[20px]">
          <div class="container relative w-full md:mt-[10px] mt-[10px] md:mb-[50px] mb-[20px]">
              <h1 class="text-2xl my-[20px] font-bold text-center text-cyan-700 uppercase">
                  services
              </h1>
              <div class="w-full flex relative "> 
                <span @click="goToIndividualPage()"
                :class="{
                  'border-b-4 w-4/12 cursor-pointer md:min-w-[200px] px-[10px]': true, 
                  'border-cyan-700': $route.path === '/services'
              }">{{ $t('FI') }}</span>
                <span @click="goToCompaniePage()" :class="{
                  'border-b-4 w-4/12 cursor-pointer md:min-w-[200px] px-[10px]': true, 
                  'border-cyan-700': $route.path === '/servicesFC'
              }">{{ $t('FOC') }}</span>
              <span @click="goToMPMEPage()" :class="{
                  'border-b-4 w-4/12 cursor-pointer md:min-w-[200px] px-[10px]': true, 
                  'border-cyan-700': $route.path === '/servicesMPME'
              }">{{ $t('MPME') }}</span>
              </div>
              <One_service :services="services" />
          </div>
      </div>
      </div>
      <FooterComponent/>
    </div>
  </template>
  
  <script setup lang="ts">
  import HeaderComponent from '../components/HeaderComponent.vue'
  import carouselBranding from '../components/CarouselBranding.vue'
  import One_service from '../components/One_service.vue'
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
import FooterComponent from '@/components/FooterComponent.vue';
  const router = useRouter()
  const initialSlide = 2;
  
  function goToIndividualPage() {
      router.push('/services')
  }
  function goToCompaniePage() {
      router.push('/servicesFC')
  }
  function goToMPMEPage() {
      router.push('/servicesFC')
  }
  const pageTitle = ref('IronSecur | Nos services'); 
  
  onMounted(() => {
      document.title = pageTitle.value; 
  });
  
  const services = ref([
  {   
    name: 's17_name', 
    image: '/img/service17.jpg', 
    description: 's17_description', 
    key: ["s17_key1", "s17_key2", "s17_key3", "s17_key4", "s17_key5"] 

  },
    
  ]);
  
  </script>
  
  <style>
  
  </style>