<template>
    <div class="flex flex-col justify-center font-montserrat items-center">
        <HeaderComponent/>
        <div class="container max-w-[1024px]">
            <div class="w-full relative flex justify-center items-center md:mt-[50px] mt-[10px]">
        <div class="container max-w-[1024px] relative w-full my-[50px]">
            <div class="w-full p-[10px] relative md:flex ">
                <div class="relative w-full text-3xl text-left flex">
                    <div class="text-blue-800 mb-[10px]">
                        <p class="md:text-left text-center">{{ newsDetail.title }}</p>
                    </div>
                </div>
            </div>
            <div class="w-full overflow-hidden max-h-[300px] relative p-[10px]">
                <img :src="newsDetail.image" alt="" class="w-full max-h-[400px]">
            </div>
            <div class="w-full relative p-[10px] overflow-x-hidden max-w-screen h-auto">
                <div v-html="newsDetail.content" class="max-w-screen ql-editor h-auto"></div>
            </div>
        </div>
    </div>
        </div>
    <FooterComponent/>
    </div>
  
</template>


<script setup lang="ts">
import HeaderComponent from '../components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axiosInstance from '@/plugins/axios';
let isSuperUser = ref(false);

const router = useRouter();

const newsDetailId = router.currentRoute.value.params.id;

interface NewsDetail {
  id: number;
  title: string;
  content: string;
  image: string;
  date: string;
}


const newsDetail = ref<NewsDetail>({
  id: 0,
  title: '',
  content: '',
  image: '',
  date: '',
});


function extractTextFromHTML(html: any) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.innerText || "";
}

const textWithoutHTML = extractTextFromHTML(newsDetail.value.content);


const loadNewsDetails = async (id: any) => {
  try {
    const response = await axiosInstance.get(`/news/${id}/`);
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const { title, author, content, image, date, source, is_published, comments, likes, is_liked_by_current_user } = response.data;
      newsDetail.value = {
        id,
        title,
        content,
        image,
        date,
      };
    }
  } catch (error) {
    console.error('Erreur lors du chargement des détails du news', error);
  }
};



const pageTitle = ref('IronSecur | Nom news');
const id = ref(''); 
onMounted(() => {
  const newsDetailId = router.currentRoute.value.params.id;
  id.value = newsDetailId;
  loadNewsDetails(newsDetailId);
});

const Like = async () => {
  try {

    await axiosInstance.post(`/news/${newsDetailId}/likes/`);

    await loadNewsDetails(newsDetailId);

  } catch (error) {
    console.error('Error toggling like:', error);
  }
};

const latestComment = ref<Comment | null>(null);

interface Comment {
  username: string;
  content: string;
  id: number;
}

let user = ref({});

const fetchUserProfile = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const response = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    user.value = response.data;
    isSuperUser.value = response.data.is_staff;
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  fetchUserProfile();
});
</script>


<style>

</style>