<template>
        <div class=" cursor-pointer shadow-4xl w-[350px] md:w-[320px] max-w-[90%] m-[10px] " v-for="(newsDetail, index) in sortedNews" :key="index">
          <!-- Contenu de chaque carte -->
          <div class="bg-white h-[500px] shadow-md p-[10px] relative rounded-lg">
            <div class="w-[40px] flex justify-center items-center h-[40px] rounded-[50%] text-white bg-cyan-700">
              <span class="font-bold uppercase">{{ newsDetail.id }}</span>
            </div>
            <div class="w-full relative shadown h-[250px] overflow-hidden my-[10px]">
              <h2 class="text-xl my-[10px] font-semibold">
                <router-link :to="{ name: 'newsDetail', params: { id: newsDetail.id }}" class="hover:text-black hover:underline duration-300">{{ newsDetail.title }}</router-link>
              </h2>
              <p class="text-gray-800">
                <div v-html="newsDetail.content"></div>
              </p>
            </div>
            <div class="w-full absolute bottom-[30px] left-0 h-[150px] max-h-[150px] overflow-hidden flex justify-start items-start">
              <img :src="newsDetail.image" alt="" class="w-full h-full">
            </div>
            <div class="absolute w-full left-0 right-0 p-[10px] text-black bg-gray-200 bottom-0 flex justify-between items-center">
              <div>
                <!-- <span class="uppercase text-sm font-bold">Actualités</span> -->
                <!-- <span class="font-bold uppercase text-gray-400 text-sm mx-[10px]">{{ article.source }}</span> -->
              </div>
            </div>
          </div>
        </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import HeartIcon from './HeartIcon.vue';
import NewsDetail from '@/components/types/NewsDetail';
const props = defineProps<{
    news: NewsDetail[],
}>();

const sortedNews = ref<NewsDetail[]>([]);

watch(() => props.news, () => {
    sortedNews.value = props.news.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
});

</script>

<style>

</style>