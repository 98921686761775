<template class="max-w-screen">
    <div class="flex font-montserrat flex-col justify-center items-center">
    <HeaderComponent/>
    <div id="top" class="lg:h-[80px] h-[50px]"></div>
    <div class="w-full md:h-[400px] h-[300px] mb-[50px] relative flex justify-center items-center">
        <div class="absolute w-full h-full top-0 left-0 right-0">
            <img src="../assets/img/Conditions.jpg" alt="" class="w-full h-full object-cover">
        </div>
        <div class="absolute w-full h-full top-0 left-0 right-0 flex justify-center px-[20px] items-center bg-blue-500/20">
            <h1 class="md:text-5xl font-bold text-white text-2xl text-center">Conditions d'utilisation</h1>
        </div>
    </div>
    <div class="w-full container mb-[100px] px-[10px] max-w-[1024px]">
        <h1 class="text-blue-500 font-bold text-xl my-[20px]">Introduction</h1>
        <p class="text-gray-700">
            En utilisant le site Web d'IronSecur, <a href="https://ironsecur.com/" class="text-blue-500 hover:underline">https://ironsecur.com/</a>, vous acceptez les
            présentes conditions d'utilisation.
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]">Utilisation du site Web</h1>
        <p class="text-gray-700">
            Vous pouvez utiliser le site Web d'IronSecur pour :
        </p>
        <ul class="my-[10px] text-gray-700">
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                Obtenir des informations sur nos produits, services et formations.
            </li>
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                Nouscontacter pour demander des informations ou des devis.
            </li>
            <li class="list-disc my-[10px] ml-[20px] md:ml-[50px]">
                Télécharger des documents.
            </li>
        </ul>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]"> Propriété intellectuelle</h1>
        <p class="text-gray-700">
            Le contenu du site Web d'IronSecur est protégé par des droits de propriété
            intellectuelle. Vous ne pouvez pas copier, reproduire, modifier, distribuer ou diffuser
            le contenu du site Web sans notre autorisation écrite.
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]"> Données personnelles</h1>
        <p class="text-gray-700">
            Nous collectons et utilisons vos données personnelles conformément à notre
            <router-link to="/Politique" class="text-blue-500 hover:underline">politique de confidentialité</router-link>.
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]"> Liens vers d'autres sites Web</h1>
        <p class="text-gray-700">
            Le site Web d'IronSecur peut contenir des liens vers d'autres sites Web. Nous ne
            sommes pas responsables du contenu ou des pratiques de confidentialité de ces
            autres sites Web.
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]">  Modifications des conditions d'utilisation</h1>
        <p class="text-gray-700">
            Nous pouvons modifier les présentes conditions d'utilisation de temps à autre. La
            version la plus récente des conditions d'utilisation sera toujours disponible sur notre
            site Web.
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]">  Date d'entrée en vigueur</h1>
        <p class="text-gray-700">
            Ces conditions d'utilisation sont entrées en vigueur le 11 février 2024.
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]">  Contact</h1>
        <p class="text-gray-700">
            Si vous avez des questions ou des préoccupations concernant ces conditions
            d'utilisation, veuillez nous contacter à l'adresse suivante : <a href="mailto:service.client@ironsecur.com" class="text-blue-500 hover:underline">service.client@ironsecur.com</a>
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]"> Responsabilité</h1>
        <p class="text-gray-700">
            IronSecur ne peut être tenu responsable des dommages directs ou indirects, y
            compris les dommages matériels, les pertes de données ou de profits, résultant de
            l'utilisation de ce site Web ou de l'impossibilité de l'utiliser.
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]">  Loi applicable et juridiction</h1>
        <p class="text-gray-700">
            Les présentes conditions d'utilisation sont régies par la loi du Bénin. Tout litige relatif
            à ces conditions d'utilisation sera soumis à la juridiction exclusive des tribunaux du
            Bénin.
        </p>
        <h1 class="text-blue-500 font-bold text-xl my-[20px]"> Dispositions générales</h1>
        <p class="text-gray-700">
            Si une disposition des présentes conditions d'utilisation est jugée invalide ou
            inapplicable, elle sera supprimée des présentes conditions d'utilisation et les autres
            dispositions resteront en vigueur.
        </p>
    </div>
    <FooterComponent/>
    </div>
  </template>
  
  <script setup lang="ts">
  import HeaderComponent from '../components/HeaderComponent.vue'
  import FooterComponent from '@/components/FooterComponent.vue';
  import { ref, onMounted } from 'vue'
  
  const pageTitle = ref('IronSecur'); 
  
  onMounted(() => {
      document.title = pageTitle.value; 
  });
  
  </script>
  
  <style>
  
  .parent::-webkit-scrollbar {
    width: 0%;
  }
  </style>