<template class="max-w-screen">
    <div class="flex font-montserrat flex-col justify-center items-center">
      <HeaderComponent/>
      <div class="relative">
          <div class="lg:h-[80px] h-[50px]"></div>
          <carouselBranding :initial-slide="initialSlide" ></carouselBranding>
          <div class="absolute z-[1000000] text-left bg-black/50 px-[20px] md:px-[50px] text-white top-0 left-0 right-0 bottom-0 w-full flex items-center flex-col justify-center ">
              <h1 class="text-4xl md:text-6xl uppercase text-center font-bold opacity-100">{{ $t('training') }}</h1>
          </div>
      </div>
      <div class="container max-w-[1024px]">
        <div class="w-full relative flex justify-center items-center md:mt-[50px] mt-[20px]">
          <div class="container relative w-full md:my-[50px] my-[20px]">
              <h1 class="text-2xl my-[20px] font-bold text-center text-cyan-700 uppercase">
                {{ $t('training') }}
              </h1>
              <div class="w-full flex relative ">
                  <span @click="goToTrainingPage" :class="{
                  'border-b-4 w-4/12 cursor-pointer md:min-w-[200px] px-[10px]': true, 
                  ' border-cyan-700': $route.path === '/training'
              }">{{ $t('FD') }}</span>
                  <span @click="goToTrainingSPage" :class="{
                  'border-b-4 w-4/12 cursor-pointer md:min-w-[200px] px-[10px]': true, 
                  ' border-cyan-700': $route.path === '/trainingFS'
              }">{{ $t('FS') }}</span>
                  <span @click="goToTrainingCPage" :class="{
                  'border-b-4 w-4/12 cursor-pointer md:min-w-[200px] px-[10px]': true, 
                  ' border-cyan-700': $route.path === '/trainingFC'
              }">{{ $t('FC') }}</span>
              </div>
              <One_trainings :trainings="trainings" />
          </div>
      </div>
      </div>
      <FooterComponent/>
    </div>
  </template>
  
  <script setup lang="ts">
  import HeaderComponent from '../components/HeaderComponent.vue'
  import carouselBranding from '../components/CarouselBranding.vue'
  import One_trainings from '@/components/One_trainings.vue';
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
import FooterComponent from '@/components/FooterComponent.vue';
  const initialSlide = 1;
  const router = useRouter()
  
  function goToTrainingSPage() {
      router.push('/trainingFS')
  }
  function goToTrainingCPage() {
      router.push('/trainingFC')
  }
  function goToTrainingPage() {
      router.push('/training')
  }
  const pageTitle = ref('IronSecur | Training'); 
  
  onMounted(() => {
      document.title = pageTitle.value; 
  });
  
  const trainings = ref([
  
    {   nam: 't2_nam', 
        style: "md:leading-10",
        target: 't2_target', 
        pre: 't2_pre', 
        edu: ["t2_edu1", "t2_edu2", "t2_edu3", "t2_edu4", "t2_edu5", "t2_edu6", "t2_edu7"], 
        image: "/img/training2.jpg", 
        booklet: '#', 
        PA: ["t2_PA1", "t2_PA2", "t2_PA3", "t2_PA4"], 
        Duration: ["t2_Duration1", "t2_Duration2", "t2_Duration3", "t2_Duration4"], 
        PR: ["t2_PR1", "t2_PR2", "t2_PR3", "t2_PR4", "t2_PR5"] 
    },

    {   nam: 't3_nam', 
        target: 't3_target', 
        pre: 't3_pre', 
        edu: ["t3_edu1", "t3_edu2", "t3_edu3", "t3_edu4", "t3_edu5", "t3_edu6", "t3_edu7"], 
        image: "/img/training6.jpg", 
        booklet: '#', 
        PA: ["t3_PA1", "t3_PA2", "t3_PA3", "t3_PA4"], 
        Duration: ["t3_Duration1", "t3_Duration2", "t3_Duration3", "t3_Duration4"], 
        PR: ["t3_PR1", "t3_PR2", "t3_PR3", "t3_PR4", "t3_PR5"] 
    },

  ]);
  
  </script>
  
  <style>
  
  </style>