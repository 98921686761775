<template>
    <div class="flex font-montserrat flex-col justify-center items-center">
        <HeaderComponent/>
        <div v-if="successMessage" class="bg-green-600 border border-green-600 my-[10px] z-[1000000000000] text-white text-center fixed md:top-[80px] top-[60px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ successMessage }}</div>
        <div v-if="errorMessage" class="bg-red-600 border border-red-600 my-[10px] text-white text-center fixed md:top-[80px] top-[60px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-[1000000000000] rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
        <div class="relative">
            <div class="md:h-[80px] h-[50px]"></div>
            <carouselBranding :initial-slide="initialSlide" ></carouselBranding>
            <div class="absolute z-[1000000] text-left bg-black/50 px-[20px] md:px-[50px] text-white top-0 left-0 right-0 bottom-0 w-full flex items-center flex-col justify-center ">
                <div class="md:my-[50px] my-[20px]"><h1 class="text-4xl md:text-6xl font-bold uppercase text-center opacity-100">Articles</h1></div>
                    <form  @submit.prevent="subscribe" class="flex relative max-w-full items-center justify-center">
                        <div class="flex flex-col items-end justify-center">
                        <div class="border-r-4 max-w-[420px] md:mb-[20px] mb-[10px] md:text-xl text-sm border-cyan-500 text-right px-1">
                            <span>{{ $t('atitre1') }} </span>
                            <span>{{ $t('atitre2') }}</span>
                        </div>
                        
                        <input type="text" required  v-model="email" class="md:w-[400px] w-full text-black border border-gray-600 md:p-[10px] p-[5px] bg-white outline-none " placeholder="Entrer votre Email">
                        </div>
                        <div class="flex items-end h-full relative">
                            <button type="submit" :disabled="loading" class="border min-w-[100px] border-white bg-cyan-500 text-white md:p-[10px] p-[5px] rounded-[5px] md:ml-[10px] ml-[5px] ">
                                <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                                <span v-else>{{ $t('subscribe') }}</span>
                            </button>
                        </div>
                    </form>
            </div>
        </div>
        <div class="w-full my-[10px] px-[10px] flex justify-center relative items-center">
            <div class="flex w-full relative">
                <label for="A_recherche" class="absolute cursor-pointer p-[10px] top-0 right-0 text-cyan-600">
                    <i class="fa-solid fa-search"></i>
                </label>
                <input type="text" id="A_recherche" class="w-full p-[10px] border border-gray-500 outline-none focus:border-cyan-600" @input="handleSearch($event)" placeholder="Filtrer à partir du nom">
            </div>
        </div>
        <div class="container max-w-[1024px]">
            <div>
                <h1 class="text-2xl px-4 font-bold text-center my-[20px]">{{ $t('everyday') }}</h1>
                <div class="flex flex-wrap justify-center items-center w-full">
                    <One_article :articles="filteredArticles" />
                </div>
            </div>
            <div class="flex justify-center items-center my-8 space-x-4">
                <button
                  @click="loadPreviousPage"
                  :disabled="!hasPreviousPage"
                  :class="{ 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded': hasPreviousPage, 'bg-gray-300 text-gray-500 cursor-not-allowed py-2 px-4 rounded': !hasPreviousPage }"
                >
                  Previous
                </button>
                <button
                  :disabled="true"
                  class="bg-gray-200 text-gray-700 font-bold py-2 px-4 rounded cursor-pointer"
                >
                  {{ currentPage }} / {{ totalPageCount }}
                </button>
                <button
                  @click="loadNextPage"
                  :disabled="!hasNextPage"
                  :class="{ 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded': hasNextPage, 'bg-gray-300 text-gray-500 cursor-not-allowed py-2 px-4 rounded': !hasNextPage }"
                >
                  Next
                </button>
            </div>
        </div>
       
        <FooterComponent/>
    </div>
  
</template>

<script setup lang="ts">
import One_article from '../components/One_article.vue';
import HeaderComponent from '../components/HeaderComponent.vue'
import carouselBranding from '../components/CarouselBranding.vue'
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import FooterComponent from '@/components/FooterComponent.vue';
import axios from 'axios'
import axiosInstance from '@/plugins/axios';

const pageTitle = ref('IronSecur | Articles'); 
const initialSlide = 3;
const email = ref('');
const loading = ref(false);
const successMessage = ref('');
const errorMessage = ref('');

const articles = ref<any[]>([]);
const filteredArticles = ref<any[]>([]);



const subscribe = () => {
  loading.value = true;

  axiosInstance.post('/subscribers/', { email: email.value })
    .then(response => {
      loading.value = false;

      if (response.status === 200 || response.status === 201 || response.status === 204) {
        successMessage.value = 'Email envoyé avec succès !';
        hideSuccessMessageAfterDelay();
        email.value= '';
      } else {
        successMessage.value = '';
      }
    })
    .catch(error => {
      loading.value = false;
      console.error(error);
      if (error.response && error.response.status === 400 && error.response.data && error.response.data.email && error.response.data.email[0] === 'This email adress has already used') {
          successMessage.value = 'Cet email est déjà enregistré.';
          hideSuccessMessageAfterDelay();
          email.value = '';
      } else {
          errorMessage.value = 'Une erreur s\'est produite. Veuillez réessayer.';
          hideErrorMessageAfterDelay();
      }
  });
};

const hideSuccessMessageAfterDelay = () => {
  setTimeout(() => {
    successMessage.value = ''; 
  }, 5000);
};

const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 5000);
};


onMounted(() => {
    document.title = pageTitle.value; 
});



// ... (votre code existant)

const fetchAllArticles = async () => {
    try {
        let allArticles: any[] = [];
        let nextPage = '/articles/';

        while (nextPage) {
            const response = await axiosInstance.get(nextPage);

            if (response.status === 200 || response.status === 201 || response.status === 204) {
                const { results, next } = response.data;
                allArticles = allArticles.concat(results);
                nextPage = next;
            } else {
                nextPage = null;
            }
        }

        return allArticles;
    } catch (error) {
        console.error('Erreur lors du chargement des articles', error);
        return [];
    }
};

const handleSearch = async (event: any) => {
    const searchTerm = event.target.value.toLowerCase().trim();

    if (!searchTerm) {
        filteredArticles.value = articles.value;
    } else {
        const allArticles = await fetchAllArticles();

        if (allArticles.length > 0) {
            filteredArticles.value = allArticles.filter(article => {
                return (
                    (article.title && article.title.toLowerCase().includes(searchTerm)) ||
                    (article.content && article.content.toLowerCase().includes(searchTerm))
                );
            });
        }
    }
};


 

onMounted(async () => {
  document.title = pageTitle.value;
  await fetchArticles(); 
  try {
    const response = await axiosInstance.get('/articles/');
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const fetchedArticles = response.data.results;
      // Sort fetched articles by date if needed
      fetchedArticles.sort((a: any, b: any) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      articles.value = fetchedArticles;
      filteredArticles.value = fetchedArticles;
    }
  } catch (error) {
    console.error('Erreur lors du chargement des articles', error);
  }
});

const currentPage = ref(1);
const pageSize = 6; 
const hasPreviousPage = ref(false);
const hasNextPage = ref(false);

const loadArticles = async (page: number) => {
  try {
    const response = await axiosInstance.get(`/articles/?page=${page}&page_size=${pageSize}`);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const { results, previous, next } = response.data;
      results.sort((a: any, b: any) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      filteredArticles.value = results;
      hasPreviousPage.value = !!previous;
      hasNextPage.value = !!next;
    }
  } catch (error) {
    console.error('Erreur lors du chargement des articles', error);
  }
};


const loadPreviousPage = () => {
  if (hasPreviousPage.value) {
    currentPage.value--;
    loadArticles(currentPage.value);
  }
};

const loadNextPage = () => {
  if (hasNextPage.value) {
    currentPage.value++;
    loadArticles(currentPage.value);
  }
};

onMounted(() => {
  loadArticles(currentPage.value);
});

const fetchArticles = async () => {
    try {
        const response = await axiosInstance.get('/articles/');
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            const fetchedArticles = response.data.results;
            
            // Filtrer les articles avec is_published à true
            const publishedArticles = fetchedArticles.filter(article => article.is_published);

            // Trier les articles par date
            publishedArticles.sort((a, b) => new Date(b.date) - new Date(a.date));

            articles.value = publishedArticles;
            filteredArticles.value = publishedArticles;
        }
    } catch (error) {
        console.error('Erreur lors du chargement des articles', error);
    }
};


const totalPageCount = ref(0);

const fetchTotalPageCount = async () => {
  try {
    const response = await axiosInstance.get('/articles/');
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const totalArticles = response.data.count;
      totalPageCount.value = Math.ceil(totalArticles / pageSize);
    }
  } catch (error) {
    console.error('Erreur lors du chargement du nombre total de pages', error);
  }
};

onMounted(() => {
  fetchTotalPageCount();
})

</script>

<style>

</style>