<template class="max-w-screen">
  <div class="flex font-montserrat flex-col justify-center items-center">
    <HeaderComponent/>
    <div class="relative">
        <div id="top" class="lg:h-[80px] h-[50px]"></div>
        <div class="max-w-full relative">
            <carouselBranding :initial-slide="initialSlide" ></carouselBranding>
        </div>
        <div class="absolute z-[1000000] text-left bg-black/50 px-[20px] md:px-[50px] text-white top-0 left-0 right-0 bottom-0 w-full flex flex-col justify-center ">
            <h1 class="text-4xl font-bold md:text-6xl flex opacity-100">Iron<span class="text-cyan-400">S</span>ecur,</h1>
            <p class="my-[20px] md:text-2xl opacity-100 max-w-[650px]">
                {{ $t('branding') }}
            </p>

        </div>
    </div>
    <div id="about" class="h-[10px] w-full"></div>
    <div class=" container max-w-[1024px] w-full md:my-[40px] duration-200 my-[10px] relative md:h-[500px] h-auto md:flex justify-center items-center p-[10px]">
        <div class="flex justify-center relative md:pr-[20px] items-center md:w-6/12">
            <img src="../assets/img/photo_affaires.jpg" alt="" class="md:max-h-[400px] ">
        </div>
        <div class="p-[10px] md:w-6/12 flex flex-col text-left ">
            <div class="my-[20px]">
                <h1 class="text-black text-2xl font-semibold uppercase">{{ $t('who') }}</h1>
            </div>
            <div>
                <p class="text-justify">
                    {{ $t('about_text') }}
                </p>
            </div>
            <div class="py-[20px]">
                <button @click="goToContactPage()" class="py-[5px] px-[10px] bg-cyan-500 capitalize text-white rounded-[5px]">{{ $t('contact_us') }}</button>
            </div>
        </div>
    </div>
    <div class="w-full flex flex-col items-center">
        <h1 class="uppercase underline text-2xl font-bold text-center my-[20px]">{{ $t('name_service') }}</h1>
        <div class=" w-full md:w-[900px] flex items-center justify-between md:px-[10px] px-0 p-[10px]">
            <button class="md:mx-[20px] mx-[10px]" id="scroll-left" aria-label="Bouton de direction gauche"><i
                class="fa-solid fa-arrow-left"></i></button>
            <div class="parent overflow-x-auto p-[10px] pl-0 flex justify-start items-center">
                <One_training :trainings="trainings" />
            </div>
            <button class="md:mx-[20px] mx-[10px]" id="scroll-right" aria-label="Bouton de direction droite"><i
                class="fa-solid fa-arrow-right"></i></button>
        </div>
    </div>
    <div class="container max-w-[1024px] relative">
        <div class="w-full flex flex-col justify-center items-center relative">
            <h1 class="uppercase underline text-2xl font-bold text-center my-[20px]">{{ $t('name_article') }}</h1>
            
            <div class="md:max-w-[calc(100%-100px)] max-w-[calc(100%-20px)]  relative">
                <Carousel :cards="filteredArticles"/>
            </div>
        </div>
        
        
        <div class="w-full my-[20px] flex flex-col items-center">
            <h1 class="uppercase underline text-2xl font-bold text-center my-[20px]">{{ $t('name_partners') }}</h1>
            <div class="max-w-full md:w-[900px] w-full flex items-center justify-center p-[10px]">
                <!--
                <button class="mx-[20px]" id="scroll-left" aria-label="Bouton de direction gauche"><i
                    class="fa-solid fa-angle-left"></i></button>
                -->
                <div class="parent flex justify-center items-center">
                    <a target="_blank" href="https://affarmers.com" class="flex md:mx-[20px] mx-[10px] flex-col items-center">
                        <div class="w-[100px] h-[100px] flex justify-center border border-gray-400 items-center rounded-[50%]  ">
                            <img src="../assets/img/AFF.jpg" class="rounded-[50%] " alt="">
                        </div>
                        <div class="mt-[5px]">
                            <h1>AFF</h1>
                        </div>
                    </a>
                    <a target="_blank" href="https://epitech.bj" class="flex md:mx-[20px] mx-[10px] flex-col items-center">
                        <div class="w-[100px] h-[100px] flex justify-center border border-gray-400 items-center relative rounded-[50%] ">
                            <img src="../assets/img/EPITECH.jpg" class=" h-[50%] w-[70%] " alt="">
                        </div>
                        <div class="mt-[5px]">
                            <h1>EPITECH</h1>
                        </div>
                    </a>
                    <a target="_blank" href="https://www.comptia.org/certifications" class="flex md:mx-[20px] mx-[10px] flex-col items-center">
                        <div class="w-[100px] h-[100px] flex justify-center border border-gray-400 items-center relative rounded-[50%] ">
                            <img src="../assets/img/CompTIA.svg" class=" h-[50%] w-[70%] " alt="">
                        </div>
                        <div class="mt-[5px]">
                            <h1>CompTIA</h1>
                        </div>
                    </a>
                </div>
                <!--
                <button class="mx-[20px]" id="scroll-right" aria-label="Bouton de direction droite"><i
                    class="fa-solid fa-angle-right"></i></button>
                    -->
            </div>
        </div>
    </div>
    
    <FooterComponent/>
  </div>
</template>

<script setup lang="ts">
import HeaderComponent from '../components/HeaderComponent.vue'
import One_training from '@/components/One_training.vue';
import carouselBranding from '../components/CarouselBranding.vue'
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Carousel from '../components/carousel.vue'
import FooterComponent from '@/components/FooterComponent.vue';
import axiosInstance from '@/plugins/axios';

const router = useRouter();
const initialSlide = 0;
function goToContactPage() {
    router.push('/contacts')
}

const pageTitle = ref('IronSecur'); 

onMounted(() => {
    document.title = pageTitle.value; 
});


const trainings = ref([
  { link: '/training-cybersecurity', image: '/img/training.jpg', title: 'service_name1', content: "service_des1" },
  
  { link: '/services#servicesList', image: '/img/service2.jpg', title: ' Définition des exigences de sécurité', content: " Identifier les exigences de sécurité nécessaires pour votre application." },
  
  { link: '/services#servicesList', image: '/img/training2.jpg', title: ' Threat Modeling (Modélisation de la menace)', content: " Identifier et quantifier les risques associés à votre application web/mobile ou desktop." },
  
  { link: '/services#servicesList', image: '/img/Audit.jpg', title: ' Audit du code source de l’application', content: " Analyse approfondie du code source de votre application pour identifier les problèmes potentiels." },
  
  { link: '/services#servicesList', image: '/img/Illustration.png', title: ' Création des cas de test de sécurité', content: " Création de scénarios de test pour évaluer la robustesse de votre application face aux menaces potentielles" },
  
  { link: '/services#servicesList', image: '/img/foot.jpg', title: '  Footprinting (énumération) des vulnérabilités potentielles des conteneurs', content: " Identifier les vulnérabilités potentielles dans les conteneurs utilisés par votre application,comme Docker et Vagrant,etc." },
  
  { link: '/services#servicesList', image: '/img/analyse.jpg', title: '  Analyse dynamique de la sécurité de l’application (DAST)', content: " Analyse dynamique de la sécurité de votre application pendant son exécution." },
  
  { link: '/services#servicesList', image: '/img/mise.jpg', title: '  Mise en place et configuration des outils de sécurité applicatifs', content: " Pare-feu ● WAF ● DAST/IAST/SAST,etc." },
  
  { link: '/services#servicesList', image: '/img/test.png', title: '  Tests d\'intrusion', content: " Évaluation de la résilience de vos applications web, mobiles et desktop face aux attaques." },
  
  { link: '/services#servicesList', image: '/img/gestion.jpg', title: '  Gestion des vulnérabilités', content: "  Gestion des vulnérabilités que vous pourriez rencontrer afin de les anticiper et les prévenir." },
  
  { link: '/services#servicesList', image: '/img/surveillance.jpg', title: '  Surveillance des systèmes d\'information', content: "  Mise en place des alertes en temps réel pour réagir rapidement à toute anomalie." },
  
  { link: '/services#servicesList', image: '/img/audit.avif', title: '  Audit de maturité cybernétique', content: " Évaluation des procédures de votre entreprise, le niveau de sécurité de vos infrastructures logicielles et déterminons votre niveau de maturité actuel." },
]);


import axios from 'axios'
const card = ref<any[]>([]);
const filteredArticles = ref<any[]>([]);

onMounted(async () => {
    document.title = pageTitle.value;
    await fetchArticles();
});

const fetchArticles = async () => {
    try {
        const response = await axiosInstance.get('/articles/');
        if (response.status === 200) {
            // Filtrer les articles avec is_published à true dès la première requête
            const articles = response.data.results.filter(article => article.is_published);
            card.value = articles;
            filteredArticles.value = articles;
        }
    } catch (error) {
        console.error('Erreur lors du chargement des articles', error);
    }
};

const loadArticles = async (page: number) => {
    try {
        const response = await axiosInstance.get(`/articles/?page=${page}&page_size=${pageSize}`);

        if (response.status === 200) {
            const { results, previous, next } = response.data;
            // Filtrer les articles avec is_published à true lors du chargement des pages suivantes
            const publishedArticles = results.filter(article => article.is_published);
            filteredArticles.value = publishedArticles;
        }
    } catch (error) {
        console.error('Erreur lors du chargement des articles', error);
    }
};

const currentPage = ref(1);
const pageSize = 6;
const hasPreviousPage = ref(false);
const hasNextPage = ref(false);

onMounted(() => {
    loadArticles(currentPage.value);
});


</script>

<style>

.parent::-webkit-scrollbar {
  width: 0%;
}
</style>