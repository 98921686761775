<template>
    <main class="relative md:min-h-screen min-h-[80vh] w-full mb-8">
        <section class="relative lg:h-[500px] md:h-[400px] h-[300px] w-full flex flex-col justify-center">
            <img class="absolute object-cover w-full h-full block z-0" src="../assets/img/training_registration.jpg" alt="">
            <div class="absolute h-full w-full z-10" style="background-color: rgba(0, 0, 0, 0.7)">&nbsp;</div>
            <div class="w-full relative p-auto z-20">
                <h1 class="lg:text-4xl md:text-2xl text-xl font-extrabold text-center text-white mb-6">Prêt à plonger dans le monde passionnant de la cybersécurité ?</h1>
                <p class="text-center text-white md:text-xl lg:lg">Complétez le formulaire ci-dessous pour accéder à tous les détails sur notre formation <span class="text-primary font-bold">Introduction à la Cybersécurité</span>.</p>
            </div>
        </section>
        <section class="relative lg:-mt-16 md:-mt-12 -mt-8 z-30 lg:w-3/4 md:w-5/6 w-[90%] mx-auto md:p-6 p-4 shadow-lg bg-white">
            <form @submit.prevent="registerTrainingProspect" class="m-auto lg:p-8 md:p-6 p-4 border-primary">
                <h1 class="text-primary lg:text-3xl md:text-2xl text-xl font-bold text-center mb-8">Enregistrez vous</h1>
                <div class="w-full mb-8 grid grid-cols-2 gap-4 justify-start">
                    <div class="col-span-full">
                        <label class="text-sm font-bold text-black-500 block mb-2" for="fullnameInput">Nom Complet</label>
                        <input v-model="prospectRegistrationData.fullname" class="rounded-[5px] border border-slate-300 outline-none bg-transparent w-full py-4 px-[10px]" type="text" placeholder="John Doe" required id="fullnameInput">
                    </div>
                    <div class="lg:col-span-1 col-span-full">
                        <label class="text-sm font-bold text-black-500 block mb-2" for="emailInput">Email</label>
                        <input v-model="prospectRegistrationData.email" class="rounded-[5px] border border-slate-300 outline-none bg-transparent w-full py-4 px-[10px]" type="email" placeholder="johndoe@gmail.com" required id="emailInput">
                    </div>
                    <div class="lg:col-span-1 col-span-full">
                        <label class="text-sm font-bold text-black-500 block mb-2" for="phoneNumberInput">Téléphone</label>
                        <div class="flex flex-row w-full">
                            <select v-model="prospectRegistrationData.phone_number_code" class="rounded-[5px] border border-r-transparent rounded-tr-none rounded-br-none border-slate-300 outline-none bg-transparent w-auto py-4 px-[10px]">
                                <option value="+229">{{ isoToEmoji('BJ') }} +229</option>
                                <option value="+226">{{ isoToEmoji('BF') }} +226</option>
                                <option value="+238">{{ isoToEmoji('CV') }} +238</option>
                                <option value="+225">{{ isoToEmoji('CI') }} +225</option>
                                <option value="+220">{{ isoToEmoji('GM') }} +220</option>
                                <option value="+233">{{ isoToEmoji('GH') }} +233</option>
                                <option value="+224">{{ isoToEmoji('GN') }} +224</option>
                                <option value="+245">{{ isoToEmoji('GW') }} +245</option>
                                <option value="+231">{{ isoToEmoji('LR') }} +231</option>
                                <option value="+223">{{ isoToEmoji('ML') }} +223</option>
                                <option value="+227">{{ isoToEmoji('NE') }} +227</option>
                                <option value="+234">{{ isoToEmoji('NG') }} +234</option>
                                <option value="+221">{{ isoToEmoji('SN') }} +221</option>
                                <option value="+232">{{ isoToEmoji('SL') }} +232</option>
                                <option value="+228">{{ isoToEmoji('TG') }} +228</option>

                            </select>
                            <input v-model="prospectRegistrationData.phone_number" class="box-border rounded-[5px] border rounded-tl-none rounded-bl-none border-slate-300 outline-none bg-transparent grow py-4 px-[10px] w-[40px]" type="tel" pattern="^\d{8,15}$" placeholder="99999999" required id="phoneNumberInput">
                        </div>
                    </div>
                </div>
                <button type="submit" :disabled="loading" class="py-[12px] md:w-[200px] w-auto px-[30px] rounded-[5px] text-md font-bold text-white bg-primary hover:bg-cyan-600 duration-300 block mx-auto">
                    <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                    <span v-else>Enregistrer</span>
                </button>
            </form>
        </section>
    </main>
    <FooterComponent/>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import axios from 'axios';
import axiosInstance from '@/plugins/axios';
import FooterComponent from '@/components/FooterComponent.vue';


const router = useRouter();

const loading = ref(false);

const prospectRegistrationData = ref({
    fullname: "",
    email: "",
    phone_number: "",
    phone_number_code: "+229",
})

const isoToEmoji = (code) => {
  return code
    .split('')
    .map(letter => letter.charCodeAt(0) % 32 + 0x1F1E5)
    .map(emojiCode => String.fromCodePoint(emojiCode))
    .join('')
}

const registerTrainingProspect = async () => {
    try {
        loading.value = true;
        prospectRegistrationData.value.phone_number = prospectRegistrationData.value.phone_number_code + prospectRegistrationData.value.phone_number
        const response = await axiosInstance.post('/training-prospects/', prospectRegistrationData.value);
    } finally {
        setTimeout(() => {
            router.push('/training-cybersecurity');
        }, 1000);

        loading.value = false; 
    }

};

</script>

<style scoped>
    .text-primary {
        color: #00b7b7;
    }

    .border-primary {
        border: 0.5px solid #00b7b7;
    }

    .bg-primary {
        background-color: #00b7b7 !important;
    }
</style>