
const isAuthenticated = () => {
  // Vérifie si l'utilisateur est connecté en fonction de ta logique d'authentification
  const token = localStorage.getItem('token');
  return !!token; // Retourne true si l'utilisateur est connecté, sinon false
};

const authGuard = (
  to: any,
  from: any,
  next: any
) => {
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    // Vérifie si la route nécessite une authentification
    if (!isAuthenticated()) {
      // Redirige vers la page de connexion si l'utilisateur n'est pas connecté
      next('/log');
    } else {
      // Autorise l'accès à la route
      next();
    }
  } else {
    // Si la route ne nécessite pas d'authentification, autorise l'accès direct
    next();
  }
};

export default authGuard;
