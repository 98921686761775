<template>
    <div class="fixed z-50 top-0 left-0 right-0 w-full h-[60px] bg-gray-800 text-white flex justify-between items-center px-[20px]">
        <div class="relative flex justify-center items-center">
            <ion-icon name="menu" @click="Menu()" class="text-3xl cursor-pointer font-bold"></ion-icon>
            <h1 class="text-3xl mx-[10px] md:mx-[20px]">IronSecur</h1>
        </div>
        <div class="relative flex justify-center items-center">
            <button  @click="logout" class="p-[10px] bg-cyan-500 rounded-[5px] cursor-pointer text-sm text-white uppercase">Log out</button>
        </div>
    </div>
    <div class="coter z-40 translate-x-[-280px] side p-[20px] pr-[50px] duration-300 ease-in-out overflow-y-auto fixed top-[60px] bg-gray-700 flex flex-col bottom-0 left-0 w-[280px] ">
        <div class="w-full flex flex-col justify-center items-center">
            <img src="../assets/img/icon.jpeg" alt="" class="w-[100px] h-[100px] rounded-[50%] border">
            <h1 class="uppercase pt-[5px]  text-white font-bold"> {{ user.firstname }} {{ user.lastname }} </h1>
        </div>
        <div class="py-[15px] relative w-full">
            <span @click="goToDashboardPage" class="text-white font-bold cursor-pointer flex py-[15px] items-center">
                <ion-icon name="grid" class="font-bold text-xl text-black"></ion-icon>
                <h1 class="px-[10px]">Dashboard</h1>
            </span>
            <span class="text-white font-bold  relative w-full flex flex-col pt-[15px]">
                <div class="flex items-center w-full relative">
                    <ion-icon name="file-tray-full" class="font-bold text-xl text-black"></ion-icon>
                    <h1 class="px-[10px] cursor-pointer">Manage article</h1>
                    <i class="fa-solid fa-angle-down hidden text-sm  text-black m-[5px]"></i>
                    <i class="fa-solid fa-angle-right text-sm text-black m-[5px]"></i>
                </div>
                <div class="flex flex-col relative items-end w-full py-[10px]">
                    <span @click="goToListeOfArticlePage" class="cursor-pointer">List of articles</span>
                    <span @click="goToAddAnArticlePage" class="my-[5px] cursor-pointer">Add an article</span>
                </div>
            </span>
            <span class="text-white font-bold  relative w-full flex flex-col pt-[15px]">
                <div class="flex items-center w-full relative">
                    <ion-icon name="file-tray-full" class="font-bold text-xl text-black"></ion-icon>
                    <h1 class="px-[10px] cursor-pointer">Manage News</h1>
                    <i class="fa-solid fa-angle-down hidden text-sm  text-black m-[5px]"></i>
                    <i class="fa-solid fa-angle-right text-sm text-black m-[5px]"></i>
                </div>
                <div class="flex flex-col relative items-end w-full py-[10px]">
                    <span @click="goToListOfNewsPage" class="cursor-pointer">List of news</span>
                    <span @click="goToAddAnNewsPage" class="my-[5px] cursor-pointer">Add an news</span>
                </div>
            </span>
            <span class="text-white font-bold  relative w-full flex flex-col pt-[15px]">
                <div class="flex items-center w-full relative">
                    <ion-icon name="file-tray-full" class="font-bold text-xl text-black"></ion-icon>
                    <h1 class="px-[10px] cursor-pointer">Mailing system</h1>
                    <i class="fa-solid fa-angle-down hidden text-sm  text-black m-[5px]"></i>
                    <i class="fa-solid fa-angle-right text-sm text-black m-[5px]"></i>
                </div>
                <div class="flex flex-col relative items-end w-full my-[10px]">
                    <span @click="goToListOfMailPage" class="cursor-pointer">List of mails</span>
                    <span @click="goToAddAnMailPage" class="my-[5px] cursor-pointer">send an mail</span>
                </div>
            </span>
            <span @click="goToImportSubscribersPage" class="text-white cursor-pointer font-bold flex my-[15px] items-center">
                <i class="fa-solid fa-file-excel font-bold text-xl text-black"></i>
                <h1 class="px-[10px]">Import Subscribers</h1>
            </span>
            <span @click="goToProfilePage" class="text-white cursor-pointer font-bold flex my-[15px] items-center">
                <ion-icon name="person" class="font-bold text-xl text-black"></ion-icon>
                <h1 class="px-[10px]">Profil</h1>
            </span>
            <span v-if="isSuperUser" @click="goToRegisterPage" class="text-white cursor-pointer font-bold flex my-[15px] items-center">
                <ion-icon name="bag-add" class="font-bold text-xl text-black"></ion-icon>
                <h1 class="px-[10px]">Register</h1>
            </span>
            <span v-if="isSuperUser" @click="goToUsersPage" class="text-white cursor-pointer font-bold flex my-[15px] items-center">
                <i class="fa-solid fa-users font-bold text-xl text-black"></i>
                <h1 class="px-[10px]">User's liste</h1>
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import axiosInstance from '@/plugins/axios';
let isSuperUser = ref(false);

const router = useRouter()

function goToRegisterPage() {
    router.push('/Register')
}
function goToUsersPage() {
    router.push('/users')
}
function goToDashboardPage() {
    router.push('/dashboard')
}

function goToListeOfArticlePage() {
    router.push('/ListeOfArticle')
}
function goToAddAnArticlePage() {
    router.push('/AddAnArticle')
}

function goToListOfNewsPage() {
    router.push('/ListeOfNews')
}
function goToAddAnNewsPage() {
    router.push('/AddAnNews')
}

function goToListOfMailPage() {
    router.push('/ListOfMails')
}
function goToAddAnMailPage() {
    router.push('/AddAnMail')
}

function goToImportSubscribersPage() {
    router.push('/ImportSubscribers')
}


function goToProfilePage() {
    router.push('/Profile')
}

const list = ref<HTMLElement | null>(null);
const iconName = ref("menu"); 
onMounted(() => {
    list.value = document.querySelector(".coter");
});

const Menu = () => {
    if (list.value) {
        if (iconName.value === "menu") {
            iconName.value = 'close'; 
            list.value.classList.remove('translate-x-[-280px]');
            list.value.classList.add('translate-x-0');
        } else {
            iconName.value = 'menu';
            list.value.classList.add('translate-x-[-280px]');
            list.value.classList.remove('translate-x-0');
        }
    }
};
const logout = () => {
    localStorage.clear();
    router.push('/');
};

let user = ref({
});

const fetchUserProfile = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const response = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    user.value = response.data;
    isSuperUser.value = response.data.is_staff;
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  fetchUserProfile();
});
</script>

<style>
    .coter::-webkit-scrollbar {
        width: 0%;
    }
</style>