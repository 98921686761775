import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  legacy: false,
  locale: 'fr',
  messages: {
    fr: {
      home: 'Accueil',
      training: "Formation",
      services: "Services",
      articles: "Articles",
      news: "Nos actualités",
      contacts: "Contacts",
      login: "Connexion",
      branding: "Faire de l'Afrique l'enclume d'un cyberespace sécuritaire durable ",
      read: "En savoir plus sur nous",
      name_article: "Nos articles",
      article_text: "Un des vrais problèmes de sécurité est la propreté du code de nos frères développeurs. Entre les actions pour avoir un livrable satisfaisant le client et les difficultés de réalisation de l'application, il n'y a plus de temps à consacrer à la propreté du code écrit. L'ignorance des bonnes pratiques en est aussi la raison. IronSecur a été créé principalement pour répondre à cette problématique.",
      see: "Voir plus",
      name_service: "Nos services",
      service_name1: "Formation",
      service_name2: "Pentest",
      service_name3: "Développement web",
      service_name4: "Audit",
      service_des1: "Formation des développeurs à de meilleures pratiques et enseignement du piratage éthique aux étudiants",
      service_des2: "Test de pentest pour chaque produit viable en cours de développement",
      service_des3: "Développement d'applications web sécurisées",
      service_des4: "Évaluation minutieuse pour renforcer la sécurité numérique et détecter les vulnérabilités critiques.",
      CTA: "AURIEZ-VOUS BESOIN D'UN SERVICE POUR VOTRE ORGANISATION ?",
      contact_us: "Contactez-nous",
      about_us: "A propos de nous", 
      about_text: "IronSecur, une entreprise spécialisée en cybersécurité basée au Bénin, se consacre à fournir des solutions pratiques pour résoudre les défis liés à la sécurité des applications. Nous offrons des services tels que l'AppSec (Application Security) et des formations techniques pour renforcer la sécurité des systèmes d’information.",
      name_partners: "NOS PARTENAIRES",
      new: "Nouveautés",
      link: "Liens utiles",
      get: "Nous contacter",
      subscribe: "souscrire",
      private: "Politique de confidentialité",
      follow: "SUIVEZ-NOUS",
      adresse: "Cotonou, bâtiment à quatre étages en face de l’église Saint-Michel, espace PIE",
      who: "Qui sommes-nous ?",
      copy: "2023 North Carolina. Tous droits réservés.",
      lang: "Français",
      term: "Conditions d'utilisation",
      atitre1: "Laissez-nous votre adresse e-mail afin que nous ",
      atitre2: "puissions vous tenir au courant des nouveaux articles.",
      everyday: "Chaque jour est l'occasion d'apprendre un nouveau code",
      news_title: "Suivez nos dernières actualités et évolutions",
      seea: "Voir autre",
      c_name: "NOM",
      c_email: "ADRESSE EMAIL",
      c_phone: "TÉLÉPHONE",
      c_date: "DATE D'ACHÈVEMENT DEMANDÉE",
      c_quantity: "QUANTITÉ",
      c_project: "NOM DE L'ENTREPRISE/DU PROJET",
      c_description: "DESCRIPTION",
      c_agree: "VOUS ACCEPTEZ NOTRE POLITIQUE DE CONFIDENTIALITÉ",
      c_send: "ENVOYER UN MESSAGE",
      FD: "Pour les développeurs",
      FS: "Pour les étudiants",
      FC: "Pour les entreprises",
      ta: "Public cible",
      pree: "Conditions préalables",
      eo: "Objectifs pédagogiques",
      pe: "Approche pédagogique",
      dod: "Durée et détails organisationnels",
      du: "Durée",
      mo: "Mobilité",
      tgs: "Taille du groupe de formation",
      mod: "Modalité",
      per: "Ressources pédagogiques",
      booklet: "Livret",
      t1_nam: 'Former les développeurs aux meilleures pratiques en matière de code', 
      t1_target: 'Développeurs, techniciens, opérateurs.', 
      t1_pre: 'Aucun prérequis spécifique en matière de programmation, mais une expérience de base dans le développement de logiciels serait bénéfique.', 
      t1_edu1: "Comprendre l'importance des meilleures pratiques en matière de code dans le développement de logiciels.",
      t1_edu2: "Apprendre les principes fondamentaux des meilleures pratiques en matière de code.",
      t1_edu3: "Acquérir des compétences pour écrire un code lisible, maintenable et évolutif.",
      t1_edu4: "Découvrir des outils et des techniques pour assurer la qualité du code.",
      t1_edu5: "Comprendre les concepts de modularité, de réutilisation et de testabilité du code.",
      t1_edu6: "Explorer les meilleures pratiques en matière de gestion des erreurs et des exceptions.",
      t1_edu7: "Apprendre des techniques efficaces de documentation du code.",
      t1_PA1: "La formation consistera en une combinaison de présentations théoriques et d'exercices pratiques.",
      t1_PA2: "Des exemples concrets et des études de cas issus du développement de logiciels seront utilisés pour illustrer les concepts.",
      t1_PA3: "Les participants auront l'occasion d'appliquer les meilleures pratiques en matière de code par le biais d'exercices et de projets.",
      t1_PA4: "Les discussions et le partage d'expériences seront encouragés afin de faciliter l'apprentissage collaboratif.",
      t1_Duration1: "3 mois (à ajuster en fonction de vos besoins spécifiques)",
      t1_Duration2: "en ligne, dans nos locaux",
      t1_Duration3: "De 1 à 10 participants maximum par groupe pour garantir une attention personnalisée.",
      t1_Duration4: "323 $ sans ajustement spécifique du temps",
      t1_PR1: "Des supports de cours et des présentations pour transmettre les connaissances théoriques.",
      t1_PR2: "Exercices pratiques pour appliquer les meilleures pratiques en matière de code.",
      t1_PR3: "Des études de cas réels pour comprendre les défis et les solutions dans des contextes concrets.",
      t1_PR4: "Outils de développement et environnements de programmation pour la pratique du codage.",
      t1_PR5: "Documentation complémentaire et ressources en ligne pour approfondir les connaissances.",
      t2_nam: 'Formation au piratage informatique', 
      t2_target: 'Étudiants, apprenants.', 
      t2_pre: 'Aucun prérequis spécifique en matière de programmation.', 
      t2_edu1: "Comprendre le rôle du piratage éthique dans la cybersécurité.",
      t2_edu2: "Acquérir une compréhension fondamentale des principes de la cybersécurité.",
      t2_edu3: "Apprendre à analyser les réseaux et les systèmes pour identifier les cibles potentielles.",
      t2_edu4: "Explorer les techniques éthiques d'exploitation des vulnérabilités. Mettre en œuvre des contre-mesures pour améliorer la sécurité du système.",
      t2_edu5: "Examiner les aspects juridiques et éthiques liés au piratage informatique.",
      t2_edu6: "Apprendre les contraintes légales liées aux tests d'intrusion à l'intérieur et à l'extérieur de votre système",
      t2_edu7: "Préparer les certifications en cybersécurité telles que CEH et OSCP, etc.",
      t2_PA1: "La formation comprendra un mélange de présentations théoriques et d'exercices pratiques.",
      t2_PA2: "Des exemples du monde réel et des études de cas dans le domaine du piratage éthique seront utilisés pour illustrer les concepts.",
      t2_PA3: "Les participants auront la possibilité d'appliquer des techniques de piratage éthique par le biais d'exercices et de projets.",
      t2_PA4: "Les discussions et le partage d'expériences seront encouragés afin de faciliter l'apprentissage collaboratif.",
      t2_Duration1: "5 semaines (à ajuster en fonction de vos besoins spécifiques)",
      t2_Duration2: "en ligne, dans nos locaux",
      t2_Duration3: "De 1 à 10 participants maximum par groupe pour garantir une attention personnalisée.",
      t2_Duration4: "113 $ sans aménagement spécifique du temps de travail",
      t2_PR1: "Un site web dédié proposant de nombreux défis de piratage éthique.",
      t2_PR2: "Supports de cours et présentations pour les connaissances théoriques.",
      t2_PR3: "Exercices pratiques pour appliquer les techniques de piratage éthique.",
      t2_PR4: "Des études de cas réels pour comprendre les défis et les solutions dans des contextes concrets.",
      t2_PR5: "Des outils de piratage éthique et des environnements de programmation pour la pratique, complétés par des ressources en ligne supplémentaires pour un apprentissage plus approfondi.",
      t3_nam: 'Bootcamp pour les certifications en cybersécurité', 
      t3_target: 'Étudiants, apprenants.', 
      t3_pre: 'Aucune certification préalable spécifique n\'est requise, mais les participants doivent choisir des certifications qui correspondent à leurs connaissances actuelles et à leurs objectifs de carrière.', 
      t3_edu1: "Offrir des options personnalisées pour les certifications en cybersécurité, y compris PECB 27001 LI/LA, eJPT, OSCP et PSM I.",
      t3_edu2: "Adapter la formation et la préparation à la certification au parcours de certification choisi par chaque participant. Approfondir la compréhension des normes ISO 27001 pour tous les participants.",
      t3_edu3: "Fournir une formation pratique sur les tests de pénétration et l'exploitation des vulnérabilités pour les certifications eJPT et OSCP, si elles sont choisies.",
      t3_edu4: "Enseigner les principes de gestion Agile avec la certification PSM I pour tous les participants.",
      t3_edu5: "Renforcer les compétences de tous les participants en matière de sécurité des systèmes, de gestion des risques et de conformité.",
      t3_edu6: "Préparer tous les participants à réussir les examens de certification de leur choix. Veiller à ce que les participants restent informés des dernières tendances et des meilleures pratiques en matière de cybersécurité, quel que soit le parcours de certification qu'ils ont choisi.",
      t3_edu7: "NB : Nous nous préparons à obtenir la certification mais nous ne sommes pas un centre de composition pour le PECB.",
      t3_PA1: "Choisissez votre parcours de certification. Bénéficiez d'une formation personnalisée.",
      t3_PA2: "Construire une base de connaissances fondamentales. Pratiquer des travaux pratiques pour acquérir des compétences concrètes.",
      t3_PA3: "Apprendre Agile et Scrum pour la gestion de projet.Bénéficier d'un mentorat pour la préparation de l'examen.",
      t3_PA4: "Restez informé grâce aux ressources d'apprentissage permanent.",
      t3_Duration1: "3 ou 6 mois et plus (à ajuster en fonction de vos besoins spécifiques)",
      t3_Duration2: "en ligne, dans nos locaux ou dans les locaux de l'entreprise",
      t3_Duration3: "De 1 à 10 participants maximum par groupe pour garantir une attention personnalisée.",
      t3_Duration4: "dépend du certificat",
      t3_PR1: "Matériel de certification : Des ressources personnalisées pour chaque certification, y compris des exercices pratiques.",
      t3_PR2: "Plateformes d'apprentissage en ligne : Plateformes d'apprentissage en ligne avec contenu interactif. Laboratoires pratiques : Exercices pratiques et simulations pour une expérience concrète.",
      t3_PR3: "Formation Agile :Ressources pour apprendre Agile et Scrum.Mentorat :Conseils de mentors expérimentés.",
      t3_PR4: "Apprentissage continu :Accès à du matériel et à des communautés mis à jour.  Préparation à l'examen :Ressources pour la préparation aux examens de certification.",
      t3_PR5: "Matériel de référence :Matériel d'étude et références supplémentaires.",
      t4_nam: 'Gouvernance, risque et conformité Formation', 
      t4_target: 'Cadres supérieurs, membres de conseils d\'administration, gestionnaires, professionnels de la gouvernance, auditeurs internes et externes, et toute personne intéressée par la gouvernance d\'entreprise.', 
      t4_pre: 'Expérience professionnelle pertinente dans le domaine de la gestion d\'entreprise, compréhension de base des principes et des opérations commerciales.', 
      t4_edu1: "Acquérir une compréhension approfondie des concepts de gouvernance, de risque et de conformité (GRC) dans le contexte de l'entreprise.",
      t4_edu2: "Apprendre à évaluer les risques opérationnels, financiers et juridiques auxquels une organisation peut être confrontée.",
      t4_edu3: "Acquérir des compétences pour améliorer la gouvernance d'entreprise, y compris la prise de décision au sein du conseil d'administration.",
      t4_edu4: "Élaborer des stratégies de gestion des risques, y compris l'identification, la prévention et la réponse aux menaces potentielles.",
      t4_edu5: "Mettre en œuvre des pratiques et des politiques visant à améliorer la conformité de l'organisation et à minimiser les risques de non-conformité.",
      t4_edu6: "Développer des compétences en matière de communication afin de rendre compte efficacement des questions de GRC aux parties prenantes internes et externes.",
      t4_edu7: "Comprendre les réglementations, les lois et les normes applicables et élaborer des stratégies pour garantir la conformité.",
      t4_PA1: "La formation consistera en une combinaison de présentations théoriques et d'exercices pratiques.",
      t4_PA2: "Des exemples concrets et des études de cas de gouvernance d'entreprise seront utilisés pour illustrer les concepts.",
      t4_PA3: "Les participants auront l'occasion d'appliquer les principes de la bonne gouvernance par le biais d'exercices et de projets.",
      t4_PA4: "Les discussions et le partage d'expériences seront encouragés afin de faciliter l'apprentissage collaboratif.",
      t4_Duration1: "X jours (à ajuster en fonction de vos besoins spécifiques)",
      t4_Duration2: "en ligne, dans nos locaux ou dans les locaux de l'entreprise",
      t4_Duration3: "De 1 à 10 participants maximum par groupe pour garantir une attention personnalisée.",
      t4_Duration4: "dépend du contrat avec l'entreprise",
      t4_PR1: "Des supports de cours et des présentations pour transmettre les connaissances théoriques.",
      t4_PR2: "Documentation sur les normes ISO 27001 et 27005",
      t4_PR3: "Des études de cas réels pour comprendre les défis et les solutions dans des contextes concrets.",
      t4_PR4: "Documentation complémentaire et ressources en ligne pour approfondir les connaissances.",
      t4_PR5: "...",
      t5_nam: 'Bootcamp pour les certifications en cybersécurité', 
      t5_target: 'Gestionnaire de risques informatiques, auditeur interne informatique, Pentester, ingénieur réseau et systèmes', 
      t5_pre: 'Aucune certification préalable spécifique n\'est requise, mais les participants doivent choisir des certifications qui correspondent à leurs connaissances actuelles et à leurs objectifs de carrière.', 
      t5_edu1: "Offrir des options personnalisées pour les certifications en cybersécurité, y compris PECB 27001 LI/LA, eJPT, OSCP et PSM I. Adapter la formation et la préparation à la certification à la voie de certification choisie par chaque participant.",
      t5_edu2: "Approfondir la compréhension des normes ISO 27001 pour tous les participants.",
      t5_edu3: "Fournir une formation pratique sur les tests de pénétration et l'exploitation des vulnérabilités pour les certifications eJPT et OSCP, si elles sont choisies.",
      t5_edu4: "Enseigner les principes de gestion Agile avec la certification PSM I pour tous les participants.",
      t5_edu5: "Renforcer les compétences de tous les participants en matière de sécurité des systèmes, de gestion des risques et de conformité.",
      t5_edu6: "Préparer tous les participants à réussir les examens de certification de leur choix.",
      t5_edu7: "Veiller à ce que les participants restent au fait des dernières tendances et des meilleures pratiques en matière de cybersécurité, quel que soit le parcours de certification qu'ils ont choisi.",
      t5_PA1: "Choisissez votre parcours de certification. Bénéficiez d'une formation personnalisée.",
      t5_PA2: "Construire une base de connaissances fondamentales.Pratiquer des travaux pratiques pour acquérir des compétences concrètes.",
      t5_PA3: "Apprendre Agile et Scrum pour la gestion de projet.Bénéficier d'un mentorat pour la préparation de l'examen.",
      t5_PA4: "Restez informé grâce aux ressources d'apprentissage permanent.",
      t5_Duration1: "3 mois et plus (à ajuster en fonction de vos besoins spécifiques)",
      t5_Duration2: "en ligne, dans nos locaux ou dans les locaux de l'entreprise",
      t5_Duration3: "De 1 à 10 participants maximum par groupe pour garantir une attention personnalisée.",
      t5_Duration4: "dépend du contrat avec l'entreprise",
      t5_PR1: "Matériel de certification : Des ressources personnalisées pour chaque certification, y compris des exercices pratiques.",
      t5_PR2: "Plateformes d'apprentissage en ligne :Plateformes d'apprentissage en ligne avec contenu interactif. Laboratoires pratiques : Exercices pratiques et simulations pour une expérience concrète.",
      t5_PR3: "Formation Agile :Ressources pour apprendre Agile et Scrum.Mentorat :Conseils de mentors expérimentés.",
      t5_PR4: "Apprentissage continu :Accès à du matériel et à des communautés mis à jour.Préparation à l'examen :Ressources pour la préparation aux examens de certification.",
      t5_PR5: "Matériel de référence :Matériel d'étude et références supplémentaires.",
      t6_nam: 'Maintien des compétences de l\'équipe de cybersécurité', 
      t6_target: 'Gestionnaire de risques informatiques, auditeur interne informatique, Pentester, ingénieur réseau et systèmes', 
      t6_pre: 'Expérience en cybersécurité, connaissances de base en cybersécurité, rôle dans le domaine de la cybersécurité ou de la sécurité des réseaux', 
      t6_edu1: "Assurer le maintien des compétences des membres de l'équipe de cybersécurité.",
      t6_edu2: "Permettre l'adaptation aux nouvelles menaces et méthodes d'attaque en matière de cybersécurité.",
      t6_edu3: "Améliorer les compétences en matière de réponse aux incidents et d'atténuation de leurs effets.",
      t6_edu4: "Développer des techniques avancées de cybersécurité.",
      t6_edu5: "Favoriser un travail d'équipe et une communication efficaces.",
      t6_edu6: "Se tenir au courant des exigences en matière de conformité et de réglementation.",
      t6_edu7: "Promouvoir une culture d'apprentissage et d'amélioration continus au sein de l'équipe.",
      t6_PA1: "La formation consistera en une combinaison de présentations théoriques et d'exercices pratiques.",
      t6_PA2: "Des exemples concrets et des études de cas issus du développement de logiciels seront utilisés pour illustrer les concepts.",
      t6_PA3: "Les participants auront l'occasion d'appliquer les techniques de piratage à travers des exercices et des projets.",
      t6_PA4: "Les discussions et le partage d'expériences seront encouragés afin de faciliter l'apprentissage collaboratif.",
      t6_Duration1: "X jours (à ajuster en fonction de vos besoins spécifiques)",
      t6_Duration2: "en ligne, dans nos locaux ou dans les locaux de l'entreprise",
      t6_Duration3: "De 1 à 10 participants maximum par groupe pour garantir une attention personnalisée.",
      t6_Duration4: "dépend du contrat avec l'entreprise",
      t6_PR1: "Un site web dédié proposant de nombreux défis de piratage éthique.",
      t6_PR2: "Supports de cours et présentations pour les connaissances théoriques.",
      t6_PR3: "Exercices pratiques pour appliquer les techniques de piratage éthique. Documentation sur les normes ISO 27001 et 27005",
      t6_PR4: "Des études de cas réels pour comprendre les défis et les solutions dans des contextes concrets.",
      t6_PR5: "Des outils de piratage éthique et des environnements de programmation pour la pratique, complétés par des ressources en ligne supplémentaires pour un apprentissage plus approfondi.",
      t7_nam: 'Sensibilisation, formation et maintien des connaissances pour l\'équipe de développement', 
      t7_target: 'Les membres de l\'équipe de développement, y compris les développeurs de logiciels, les ingénieurs et les fonctions connexes responsables du développement de logiciels.', 
      t7_pre: 'Familiarité avec les langages de programmation et les outils de développement.', 
      t7_edu1: "Sensibiliser l'équipe de développement à la cybersécurité.",
      t7_edu2: "Offrir des possibilités de formation et d'amélioration des compétences.",
      t7_edu3: "Former l'équipe aux meilleures pratiques en matière de sécurité.",
      t7_edu4: "Permettre l'identification et l'atténuation des risques.",
      t7_edu5: "Veiller au respect des exigences et des normes en matière de cybersécurité.",
      t7_edu6: "Promouvoir la rétention permanente des connaissances et l'apprentissage continu.",
      t7_edu7: "Favoriser la collaboration entre l'équipe de développement et les professionnels de la cybersécurité.",
      t7_PA1: "Ateliers interactifs de sensibilisation à la cybersécurité avec des exemples concrets.",
      t7_PA2: "Une formation pratique et concrète pour appliquer le codage sécurisé et relever les défis en matière de sécurité.",
      t7_PA3: "Encourager l'apprentissage collaboratif par le biais de sessions régulières de partage des connaissances.",
      t7_PA4: "Mettre l'accent sur la formation continue avec des cours en ligne, des webinaires et la participation à la communauté de l'industrie pour rester à jour.",
      t7_Duration1: "X jours (à ajuster en fonction de vos besoins spécifiques)",
      t7_Duration2: "en ligne, dans nos locaux ou dans les locaux de l'entreprise",
      t7_Duration3: "De 1 à 10 participants maximum par groupe pour garantir une attention personnalisée.",
      t7_Duration4: "dépend du contrat avec l'entreprise",
      t7_PR1: "Des supports de cours et des présentations pour transmettre les connaissances théoriques.",
      t7_PR2: "Exercices pratiques pour appliquer les meilleures pratiques en matière de code.",
      t7_PR3: "Des études de cas réels pour comprendre les défis et les solutions dans des contextes concrets.",
      t7_PR4: "Outils de développement et environnements de programmation pour la pratique du codage.",
      t7_PR5: "Documentation complémentaire et ressources en ligne pour approfondir les connaissances.",
      key: "Caractéristiques principales",
      s1_name: "Développement d'applications web, mobiles et de bureau",
      s1_description: "Notre service de développement d'applications web, mobiles et de bureau est conçu pour répondre à vos besoins en matière de création d'applications sécurisées et performantes. Nous mettons l'accent sur la sécurité, en veillant à ce que toutes les applications que nous développons intègrent des mesures de protection robustes pour sauvegarder vos données et la sécurité des utilisateurs.",
      s1_key1: "Développement multiplateforme",
      s1_key2: "Conception centrée sur l'utilisateur",
      s1_key3: "Développement agile",
      s1_key4: "Des mesures de sécurité robustes",
      s1_key5: "Maintenance et mises à jour",
      s2_name: "Conseils et mentorat pour votre carrière dans la cybersécurité",
      s2_description: "Nous fournissons des conseils personnalisés en fonction de vos aptitudes naturelles et des compétences que vous pourriez acquérir à court, moyen et long terme. Notre service vous met en relation avec un mentor et des experts en la matière, et vous offre également la possibilité de participer à notre Graduate program s'il correspond à vos aspirations professionnelles",
      s2_key1: "Conseils personnalisés en matière de compétences",
      s2_key2: "Mentorat et relations avec les experts",
      s2_key3: "Développement des compétences à court, moyen et long terme",
      s2_key4: "Possibilités de programmes de formation",
      s2_key5: "Soutien à l'orientation professionnelle",
      s3_name: "Développement et vente de solutions de cybersécurité",
      s3_description: "Nous sommes spécialisés dans la création et l'offre de solutions de cybersécurité de pointe pour protéger vos actifs numériques. Notre service englobe à la fois le développement et la vente de ces solutions, garantissant ainsi la sécurité des données et des systèmes de votre organisation",
      s3_key1: "Des solutions de pointe",
      s3_key2: "Paquets personnalisés",
      s3_key3: "Support technique d'experts",
      s3_key4: "Mises à jour régulières",
      s3_key5: "Évolutivité",
      s4_name: "Conseils sur la sécurisation des actifs",
      s4_description: "Conseils pour le déploiement et la mise en œuvre d'un réseau sécurisé ou d'une infrastructure cloud ainsi que pour le développement sécurisé d'une application web, mobile ou de bureau",
      s4_key1: "Conseils pour le déploiement d'un réseau sécurisé et de l'informatique en nuage",
      s4_key2: "Conseils pour le développement d'applications sécurisées",
      s4_key3: "Consultation d'experts pour la sécurité des réseaux et de l'informatique en nuage",
      s4_key4: "Meilleures pratiques en matière de sécurité des applications",
      s4_key5: "Solutions et stratégies de sécurité sur mesure",
      s5_name: "Suivi du code à tous les stades du développement",
      s5_description: "Notre service de développement donne la priorité à la sécurité tout au long du processus. Un aspect essentiel est le suivi du code, qui garantit que chaque modification apportée à la base de code de votre projet est soigneusement contrôlée et enregistrée. Cette approche méticuleuse renforce considérablement la sécurité et la fiabilité de votre logiciel.",
      s5_key1: "Tests de sécurité continus",
      s5_key2: "Analyse de la source du code",
      s5_key3: "Détection proactive des vulnérabilités",
      s5_key4: "Approche collaborative",
      s5_key5: "Protection renforcée des applications",
      s6_name: "Test de pentest de chaque produit viable en cours de développement (SAST, DAST, IAST)",
      s6_description: "Nous effectuons des évaluations de sécurité complètes sur chaque produit livrable au cours du processus de développement, en utilisant une combinaison de tests statiques de sécurité des applications (SAST), de tests dynamiques de sécurité des applications (DAST) et de tests interactifs de sécurité des applications (IAST). En résumé, notre approche DevSecOps met l'accent sur la sécurité en intégrant ces évaluations à chaque étape du cycle de développement.",
      s6_key1: "Évaluations de sécurité",
      s6_key2: "Approche DevSecOps",
      s6_key3: "Tests continus",
      s6_key4: "Atténuation des risques",
      s6_key5: "Sécurité globale",
      s7_name: "Enquête numérique",
      s7_description: "Notre service d'investigation numérique consiste en une exploration et une compréhension approfondies des aspects numériques de votre entreprise. Cela nous permet de détecter, de prévenir et de résoudre les incidents de sécurité, contribuant ainsi à la protection de vos données et de votre réputation.",
      s7_key1: "Enquête numérique complète",
      s7_key2: "Détection et prévention des incidents",
      s7_key3: "Analyse criminalistique numérique",
      s7_key4: "Préservation de l'intégrité des données",
      s7_key5: "Rapports de sécurité et recommandations",
      s8_name: "Réponse post-incident",
      s8_description: "Notre service \"Post-Incident Response\" est spécialement conçu pour gérer les situations d'incidents de sécurité. Nous intervenons après un incident pour comprendre ce qui s'est passé, évaluer les dommages et prendre des mesures pour rétablir la sécurité et prévenir de futurs incidents.",
      s8_key1: "Évaluation complète de l'incident",
      s8_key2: "Identification des causes profondes",
      s8_key3: "Restauration de la sécurité",
      s8_key4: "Recommandations pour la prévention des incidents à l'avenir",
      s8_key5: "Rapports post-incidents et analyse des enseignements tirés",
      s9_name: "Développement d'applications web, mobiles et de bureau sécurisées",
      s9_description: "Notre service de développement d'applications web, mobiles et de bureau est conçu pour répondre à vos besoins en matière de création d'applications sécurisées et performantes. Nous mettons l'accent sur la sécurité, en veillant à ce que toutes les applications que nous développons intègrent des mesures de protection robustes pour sauvegarder vos données et la sécurité des utilisateurs.",
      s9_key1: "Développement multiplateforme",
      s9_key2: "Conception centrée sur l'utilisateur",
      s9_key3: "Développement agile",
      s9_key4: "Des mesures de sécurité robustes",
      s9_key5: "Maintenance et mises à jour",
      s10_name: "Conseiller en sécurité",
      s10_description: "Notre service de conseil en sécurité a pour mission de vous fournir des conseils d'experts pour renforcer la sécurité de vos systèmes et de vos données. Nos conseillers en sécurité vous aideront à identifier, prévenir et gérer les menaces potentielles.",
      s10_key1: "Consultation d'experts en sécurité",
      s10_key2: "Évaluation de la sécurité",
      s10_key3: "Planification stratégique de la sécurité",
      s10_key4: "Gestion des menaces et des vulnérabilités",
      s10_key5: "Recommandations de sécurité personnalisées",
      s11_name: "Audit informatique, gouvernance, risque et conformité (grc)",
      s11_description: "Notre service d'audit informatique et de conformité aux risques de gouvernance (GRC) est une approche rigoureuse et systématique visant à garantir l'intégrité, la sécurité et la conformité de l'infrastructure et des opérations informatiques de votre organisation. Nous réalisons des audits approfondis, évaluons les risques et mettons en œuvre des stratégies de gouvernance afin de protéger vos données, de maintenir la conformité réglementaire et d'améliorer l'efficacité opérationnelle globale.",
      s11_key1: "Audits informatiques approfondis",
      s11_key2: "Évaluation et atténuation des risques",
      s11_key3: "Validation de la conformité réglementaire",
      s11_key4: "Mise en œuvre du cadre de gouvernance",
      s11_key5: "Rapports détaillés sur la sécurité et la conformité",
      s12_name: "Tester toutes les solutions informatiques qu'ils utilisent",
      s12_description: "Nous proposons des tests de pénétration complets pour toutes les solutions informatiques utilisées par votre organisation. Notre service de pentest évalue rigoureusement la sécurité de vos systèmes afin d'identifier les vulnérabilités et d'assurer une défense solide contre les menaces potentielles.",
      s12_key1: "Test de pénétration complet des solutions informatiques",
      s12_key2: "Identification et évaluation de la vulnérabilité",
      s12_key3: "Simulation de menaces dans le monde réel",
      s12_key4: "Recommandations de sécurité personnalisées",
      s12_key5: "Soutien et surveillance continus de la sécurité",
      s13_name: "Gestion des vulnérabilités (contrôle des actifs, évaluation des vulnérabilités et rapports)",
      s13_description: "La gestion des vulnérabilités est un processus continu, proactif et souvent automatisé qui garantit la sûreté et la sécurité de vos systèmes informatiques, réseaux et applications d'entreprise. Elle est conçue pour protéger votre organisation contre les cyberattaques et les violations de données en identifiant et en traitant les vulnérabilités potentielles de manière systématique et efficace.",
      s13_key1: "Contrôle précis des actifs",
      s13_key2: "Évaluations approfondies de la vulnérabilité",
      s13_key3: "Identification des menaces en temps réel",
      s13_key4: "Rapports détaillés sur les risques",
      s13_key5: "Conseils et soutien en matière de remédiation",
      s14_name: "Développement et vente de solutions de cybersécurité",
      s14_description: "Nous sommes spécialisés dans la création et l'offre de solutions de cybersécurité de pointe pour protéger vos actifs numériques. Notre service comprend à la fois le développement et la vente de ces solutions, garantissant ainsi la sécurité des données et des systèmes de votre organisation.",
      s14_key1: "Des solutions de pointe",
      s14_key2: "Paquets personnalisés",
      s14_key3: "Support technique d'experts",
      s14_key4: "Mises à jour régulières",
      s14_key5: "Évolutivité",
      s15_name: "Assistance juridique en matière de cybersécurité",
      s15_description: "Notre service d'assistance juridique en matière de cybersécurité fait partie de notre engagement à soutenir tout projet lié aux technologies de l'information. Nous offrons des conseils d'experts sur les normes de l'industrie et les lois en vigueur afin d'assurer la réussite de votre projet. Ce service est conçu pour fournir des conseils juridiques complets afin de vous aider à rester en conformité avec les réglementations pertinentes, à protéger les données sensibles et à atténuer les risques juridiques tout au long du cycle de vie de votre projet.",
      s15_key1: "Analyse de la conformité juridique",
      s15_key2: "Protection des données et de la vie privée",
      s15_key3: "Élaboration de la politique de cybersécurité",
      s15_key4: "Planification de la réponse aux incidents",
      s15_key5: "Soutien et représentation en cas de litige",
      s16_name: "Sensibilisation à la cybersécurité",
      s16_description: "Notre service de \"sensibilisation à la cybersécurité\" vise à former le personnel de votre organisation et à lui donner les moyens d'acquérir des connaissances et des bonnes pratiques en matière de cybersécurité. Nous proposons des formations pour les employés, des ateliers et des programmes de sensibilisation à l'hameçonnage. En outre, nous organisons des conférences sur des sujets liés à la cybersécurité à l'intention de publics spécifiques. Dans le cadre de notre engagement en faveur de la sensibilisation à la cybersécurité, nous entreprenons chaque année des tournées nationales, qui débutent au Bénin et s'étendent à plusieurs villes et villages d'Afrique, dans le but de sensibiliser les individus à l'importance de la cybersécurité et de la protection des actifs numériques.",
      s16_key1: "Formation des employés et ateliers",
      s16_key2: "Programmes de sensibilisation à l'hameçonnage",
      s16_key3: "Guide des meilleures pratiques en matière de sécurité",
      s16_key4: "Conférences ciblées",
      s16_key5: "Tournées de sensibilisation à l'échelle nationale",
      s17_name: "Élever le niveau de maturité en matière de cybersécurité de zéro à héros",
      s17_description: "Notre mission est d'élever la maturité de votre organisation en matière de cybersécurité du niveau zéro à celui de héros, en mettant l'accent sur le soutien aux PME. Nous proposons ce service à des coûts réduits, définis en collaboration avec votre entreprise. Ce service est conçu pour aider les PME dans tous les aspects, depuis le développement des plateformes numériques nécessaires à la numérisation des processus internes jusqu'à l'assurance de la sécurité physique et logique de votre infrastructure.",
      s17_key1: "Évaluation initiale de la maturité",
      s17_key2: "Feuille de route de maturité sur mesure",
      s17_key3: "Mise en œuvre du cadre de sécurité",
      s17_key4: "Surveillance permanente des menaces et réaction",
      s17_key5: "Stratégies d'amélioration continue",
      FI: "Pour les particuliers",
      FOC: "Pour les entreprises",
      MPME: "MPME",
      SDE: "Sociétés de développement de logiciels",
      EVA: "Entreprises victimes d'attaques",
      ALL: "Toutes les entreprises de tous les autres secteurs",
      article1_identification: "Cybersécurité",
      article1_title: "L'Importance de la Sensibilisation à la Cybersécurité en Entreprise",
      article1_description: "Cet article explore l'importance cruciale de la sensibilisation à la cybersécurité au sein des entreprises, mettant en lumière les risques potentiels liés aux cybermenaces et comment les employés peuvent contribuer à renforcer la sécurité en ligne.",
      article2_identification: "Menaces",
      article2_title: "Les Tendances en Matière de Menaces en Cybersécurité pour 2023",
      article2_description: "Découvrez les tendances émergentes en matière de menaces en cybersécurité qui façonnent le paysage de la sécurité numérique en 2023. De nouvelles attaques et vulnérabilités à surveiller.",
      article3_identification: "IA",
      article3_title: "Le Rôle Crucial de l'Intelligence Artificielle dans la Cybersécurité",
      article3_description: "Cet article explore comment l'intelligence artificielle (IA) est devenue un élément clé dans la défense contre les menaces en cybersécurité. Découvrez comment l'IA est utilisée pour détecter et contrer les attaques cybernétiques.",
      article4_identification: "Internet des Objets",
      article4_title: "La Cybersécurité dans l'Internet des Objets (IoT) : Défis et Solutions",
      article4_description: "L'Internet des Objets (IoT) est en croissance constante, mais il comporte également des défis de sécurité. Cet article examine les défis liés à la cybersécurité dans le domaine de l'IoT et présente des solutions pour les surmonter.",
      article5_identification: "Cryptomonnaie",
      article5_title: "La Cryptomonnaie et la Cybersécurité : Les Enjeux de la Sécurité Financière",
      article5_description: "La cryptomonnaie a suscité un intérêt croissant, mais elle est également exposée à des risques de sécurité. Cet article explore les enjeux de la sécurité financière liés à la cryptomonnaie et comment les investisseurs peuvent se protéger.",
      article6_identification: "Santé",
      article6_title: "La Cybersécurité dans le Monde de la Santé : Enjeux et Mesures Préventives",
      article6_description: "Cet article se penche sur les défis spécifiques en matière de cybersécurité dans le secteur de la santé, où la protection des données médicales sensibles est cruciale. Découvrez comment les professionnels de la santé et les prestataires de services médicaux peuvent renforcer la sécurité des informations médicales des patients.",
      aboutus: "A propos",
      founders: "Fondateurs",
      vision: "Vision",
      mission: "Mission",
      staff: "Direction",
      founders11: "La mission personnelle d'Espoir est d'aider à influencer le développement futur des personnes et des organisations en vivant une vie centrée sur Dieu autour des principes d'intégrité, d'excellence, de leadership serviteur et de fiabilité. ",
      founders12: "Espoir est certifié CompTIA Security + et AWS Cloud Practitioner. Il est également diplômé de l'Université de Caroline du Nord à Charlotte, avec une licence en informatique et une maîtrise en sécurité et confidentialité de l'information. Au cours de sa carrière, Espoir a contribué de manière significative à la croissance de plusieurs entreprises. ",
      founders13: "L'expertise d'Espoir comprend le développement de logiciels, Scrum Master et la cybersécurité. Espoir est également fondateur et président d'African Future Farmers, une organisation à but non lucratif basée aux États-Unis.",
      founders21: "Jeune avant-gardiste de la cybersécurité, je suis accompagnateur pédagogique à EPITECH pour toutes les missions relatives à la sécurité des systèmes d’information. Je suis titulaire d'un baccalauréat en installation et maintenance informatique, d’une licence professionnelle en sécurité informatique obtenue à l’Institut de Formation et de Recherche en Informatique de l’université d’Abomey-Calavi, ainsi que d’un master en architecture système réseau/cloud et sécurité à EPITECH. En plus de mes activités pédagogiques, j'ai une expérience significative en stratégie et management des systèmes d’information, ayant occupé le poste de responsable du pôle sécurité au sein d'une institution financière, et exercé des rôles parallèles tels que pentester, développeur et auditeur.",
      founders22: 'Je suis certifié "Professional Scrum Master (PSM I)", et mon objectif est de fusionner mes compétences techniques, managériales et juridiques pour devenir un pilier essentiel de la cybersécurité. Ma vision entrepreneuriale consiste à révolutionner la cybersécurité tout en innovant dans la création d\'emplois en Afrique, adoptant des approches novatrices par rapport aux méthodes traditionnelles.',
      founders23: "",
      founders31: "En tant que leader en cyber sécurité avec plus de 15 ans d'expérience, je suis passionné par l'alignement de la sécurité avec la stratégie d'entreprise et expérimenté dans la communication claire à tous les niveaux de l'organisation, y compris le conseil d'administration.",
      founders32: "J'ai occupé des postes de direction dans des entreprises globales, notamment JPMorgan Chase, Accenture Consulting, Texas Capital et l'Université de Floride, avec une expérience éprouvée dans le développement, l'exécution et la maturation de stratégies de sécurité qui atténuent efficacement les cyber risques et fournissent des solutions de sécurité de classe mondiale dans une variété de secteurs allant des services financiers, du pétrole et du gaz, aux sciences de la vie et à l'enseignement supérieur.",
      founders33: "Je suis certifié CISSP, CCNA, GICSP et CISA, et j'ai obtenu un Master of Science en Sécurité de l'Information en 2008 à l'université Nova Southeastern, qui est un Centre d'Excellence Académique en Cybersécurité reconnu par la National Security Agency des États-Unis.",
      break: "Je suis une entrepreneure dynamique.",
      founders41: "Diplômée de l'école  Supérieure de Commerce de Rennes en 2008, j’ai eu  une solide carrière en tant que Manager des Duty Free Shop de Cotonou pendant une décennie, développant ainsi des compétences remarquables en gestion et en marketing.",
      founders42: "Passionnée d'entrepreneuriat, mon parcours reflète ma détermination à exceller dans le domaine du développement d'affaires. Ma motivation personnelle réside dans l'aspiration constante à devenir une meilleure version de moi-même, m'incitant ainsi à me former continuellement. En 2022, j'ai obtenu une licence en responsable projet Webmarketing et communication digitale, élargissant ainsi mes horizons.",
      founders43: "Mon engagement envers le développement personnel m'a conduite à embrasser le rôle de coach carrière, où je partage mon savoir-faire avec les jeunes talents. En tant que Directrice Marketing  d'Iron Secur, je mets en œuvre mes compétences marketing pour contribuer au succès de l'entreprise. Ma philosophie repose sur la conviction que l'apprentissage continu et l'évolution personnelle sont les clés de la réussite professionnelle.",
      staff11: "Je suis un développeur web diplômé d'une licence en Génie Logiciel. J'aime relever les défis techniques et je suis passionné par la résolution de problèmes complexes à travers des solutions innovantes. J'ai une soif d'apprendre constante et j'aime travailler sur des projets stimulants pour mettre en pratique mes compétences et en développer de nouvelles.",
      staff12: "Mon objectif ultime est de jouer un rôle essentiel dans l’avancement du secteur numérique au Bénin, en apportant mon expertise dans le développement de systèmes automatisés, et en contribuant activement à la formation des prochains leaders technologiques.",
      staff21: "J’ai fait mes études à l’Institut de Formation et de Recherche en Informatique (IFRI), spécialisé en Internet et Multimédia où je suis finissante cette année . j'ai débuté ma carrière en tant que social media manager , où j'ai appris les bases de la gestion des réseaux sociaux et de l'engagement de la communauté. Ce qui me permet de développer des stratégies de communication pour accroître l'interaction et la visibilité des marques sur les plateformes sociales.",
      staff22: "",
      mission_text: "Un des vrais problèmes de sécurité est la propreté du code écrit par les développeurs. Entre les efforts nécessaires pour produire un livrable qui satisfasse le client et les difficultés liées à la mise en œuvre de l'application, il reste peu de temps à consacrer à la propreté du code écrit. La méconnaissance des bonnes pratiques constitue une autre raison sous-jacente. IronSecur a été spécifiquement conçu pour aborder cette problématique en proposant des solutions telles que la formation, les tests d’intrusion, l'audit de maturité cybernétique et bien d’autres, intervenant ainsi à toutes les étapes du cycle de développement du produit. ",
      vision_text:  "Faire de l'Afrique l’enclume d’un cyberespace sécuritaire durable.",
      
    },
    en: {
      home: 'Home',
      training: "Training",
      services: "Services",
      articles: "Articles",
      news: "Our news",
      contacts: "Contacts",
      login: "Login",
      branding: "Making Benin/ Africa, the anvil of a sustainable security space",
      read: "Read more about us",
      name_article: "Our articles",
      article_text: "One of the real security problems is the cleanliness of our developer brother’s code. Between the actions to have a deliverable satisfying the customer and the difficulties of realization of the application, there is no more time to devote to the cleanliness of the written code. Ignorance of good practices is also the reason. IronSecur was created mainly to address this issue.",
      see: "See more",
      name_service: "Our services",
      service_name1: "Training",
      service_name2: "Pentest",
      service_name3: "Web development",
      service_name4: "Audit",
      service_des1: "Training of developers for better practices and and teaching ethical hacking to students",
      service_des2: "Pentesting every viable product in development",
      service_des3: "Secure web application development",
      service_des4: "Careful assessment to strengthen digital security and detect critical vulnerabilities.",
      CTA: "WOULD YOU NEED A SERVICE FOR YOUR ORGANIZATION?",
      contact_us: "Contact us",
      about_us: "About us", 
      about_text: "One of the real security problems is the cleanliness of the code written by our developer brothers. Between the efforts required to produce a deliverable that satisfies the customer and the difficulties involved in implementing the application, there's no time left to devote to the cleanliness of written code. Ignorance of best practices is another reason. IronSecur was created primarily to respond to this problem through training, penetration testing, code auditing and GRC.",
      name_partners: "OUR PARTNERS",
      new: "NEWS LETTER",
      link: "USEFULL LINKS",
      get: "GET IN TOUCH",
      subscribe: "subscribe",
      private: "Privacy policy",
      follow: "FOLLOW US",
      adresse: "Cotonou, Cadjehoun, Patte d'oie, white building on the right from the Saint-Louis pharmacy",
      who: "Who are we ?",
      copy: "2023 North Carolina. All Rights Reserved.",
      lang: "English",
      term: "Terms of use",
      atitre1: "Leave us your e-mail address so that we can keep you ",
      atitre2: "informed of new articles",
      everyday: "Everyday is a chance to learn new code",
      news_title: "Follow our latest news and developments",
      seea: "See Another",
      c_name: "NAME",
      c_email: "EMAIL ADDRESS",
      c_phone: "PHONE",
      c_date: "REQUESTED COMPLETION DATE",
      c_quantity: "QUANTITY",
      c_project: "COMPANY/PROJECT NAME",
      c_description: "DESCRIPTION",
      c_agree: "YOU AGREE TO OUR FRIENDLY PRIVACY POLICY",
      c_send: "SEND MESSAGE",
      FD: "For developers",
      FS: "For students and leaners",
      FC: "For Companies",
      ta: "Target audience",
      pree: "Prerequisites",
      eo: "Educational objectives",
      pe: "Pedagogical Approch",
      dod: "Duration and Organizational Details",
      du: "Duration",
      mo: "Mobility",
      tgs: "Training group size",
      mod: "Modality",
      per: "Pedagogical Resources",
      booklet: "Booklet",
      t1_nam: 'Train Developers in Code Best Practice', 
      t1_target: 'Developers, Technicians, Operators.', 
      t1_pre: 'No specific programming prerequisites, but basic experience in software development would be beneficial.', 
      t1_edu1: "Understand the importance of code best practices in software development.",
      t1_edu2: "Learn the fundamental principles of code best practices.",
      t1_edu3: "Acquire skills to write readable, maintainable, and scalable code.",
      t1_edu4: "Discover tools and techniques to ensure code quality.",
      t1_edu5: "Understand the concepts of modularity, reusability, and testability in code.",
      t1_edu6: "Explore best practices for error and exception handling.",
      t1_edu7: "Learn effective code documentation techniques.",
      t1_PA1: "The training will consist of a combination of theoretical presentations and hands-on exercises.",
      t1_PA2: "Real-world examples and case studies from software development will be used to illustrate the concepts.",
      t1_PA3: "Participants will have the opportunity to apply code best practices through exercises and projects.",
      t1_PA4: "Discussions and experience sharing will be encouraged to facilitate collaborative learning.",
      t1_Duration1: "3 Months (to be adjusted based on your specific needs)",
      t1_Duration2: "online, on our premises",
      t1_Duration3: "From 1 to a maximum of 10 participants per group to ensure personalized attention",
      t1_Duration4: "$323 without specific time adjustment",
      t1_PR1: "Course materials and presentations to deliver theoretical knowledge.",
      t1_PR2: "Practical exercises to apply code best practices.",
      t1_PR3: "Real-world case studies to understand challenges and solutions in concrete contexts.",
      t1_PR4: "Development tools and programming environments for hands-on coding practice.",
      t1_PR5: "Supplementary documentation and online resources for further knowledge enhancement.",
      t2_nam: 'Ethical Hacking Training', 
      t2_target: 'Students, Learners.', 
      t2_pre: 'No specific programming prerequisites.', 
      t2_edu1: "Understand the role of ethical hacking in cybersecurity.",
      t2_edu2: "Gain a foundational understanding of cybersecurity principles.",
      t2_edu3: "Learn how to scan networks and systems to identify potential targets.",
      t2_edu4: "Explore ethical techniques for exploiting vulnerabilities. Implement countermeasures to enhance system security.",
      t2_edu5: "Examine legal and ethical aspects related to ethical hacking.",
      t2_edu6: "Learn the legal constraints related to intrusion testing inside and outside your system",
      t2_edu7: "Prepare for cybersecurity certifications such as CEH and OSCP, etc...",
      t2_PA1: "The training will include a blend of theoretical presentations and practical hands-on exercises.",
      t2_PA2: "Real-world examples and case studies from the field of ethical hacking will be used to illustrate the concepts.Real-world examples and case studies from the field of ethical hacking will be used to illustrate the concepts.",
      t2_PA3: "Participants will be given the chance to apply ethical hacking techniques through exercises and projects.",
      t2_PA4: "Discussions and experience sharing will be encouraged to facilitate collaborative learning.",
      t2_Duration1: "5 Weeks (to be adjusted based on your specific needs)",
      t2_Duration2: "online, on our premises",
      t2_Duration3: "From 1 to a maximum of 10 participants per group to ensure personalized attention",
      t2_Duration4: "$113 without specific time adjustment",
      t2_PR1: "A dedicated website offering numerous ethical hacking challenges.",
      t2_PR2: "Course materials and presentations for theoretical knowledge.",
      t2_PR3: "Practical exercises to apply ethical hacking techniques.",
      t2_PR4: "Real-world case studies to understand challenges and solutions in concrete contexts.",
      t2_PR5: "Ethical hacking tools and programming environments for hands-on practice, supplemented with additional online resources for further learning.",
      t3_nam: 'Bootcamp for Cybersecurity certifications', 
      t3_target: 'Students, Learners.', 
      t3_pre: 'No specific prior certifications are required, but participants should choose certifications that align with their existing knowledge and career goals.', 
      t3_edu1: "Offer personalized options for cybersecurity certifications, including PECB 27001 LI/LA, eJPT, OSCP, and PSM I.",
      t3_edu2: "Tailor training and certification preparation to each participant's chosen certification path. Deepen understanding of ISO 27001 standards for all participants.",
      t3_edu3: "Provide practical training on penetration testing and vulnerability exploitation for eJPT and OSCP certifications, if chosen.",
      t3_edu4: "Teach Agile management principles with the PSM I certification for all participants.",
      t3_edu5: "Strengthen skills in system security, risk management, and compliance for all participants.",
      t3_edu6: "Prepare all participants for successful completion of their chosen certification exams. Ensure that participants stay updated on the latest trends and best practices in cybersecurity, regardless of their chosen certification path.",
      t3_edu7: "NB: We prepare to obtain certification but we are not a composition center for the PECB",
      t3_PA1: "Choose your certification path. Get personalized training.",
      t3_PA2: "Build a foundational knowledge base. Practice hands-on labs for practical skills.",
      t3_PA3: "Learn Agile and Scrum for project management. Receive mentoring for exam preparation.",
      t3_PA4: "Stay updated with ongoing learning resources.",
      t3_Duration1: "3 or 6 Month and plus (to be adjusted based on your specific needs)",
      t3_Duration2: "online, on our premises or on company premises",
      t3_Duration3: "From 1 to a maximum of 10 participants per group to ensure personalized attention",
      t3_Duration4: "depends on the Certificate",
      t3_PR1: "Certification Materials: Tailored resources for each certification, including practice materials.",
      t3_PR2: "Online Learning Platforms: E-learning platforms with interactive content. Hands-On Labs: Practical exercises and simulations for hands-on experience.",
      t3_PR3: "Agile Training: Resources for learning Agile and Scrum. Mentorship: Guidance from experienced mentors.",
      t3_PR4: "Continuous Learning: Access to updated materials and communities.  Exam Preparation: Resources for certification exam readiness.",
      t3_PR5: "Reference Materials: Additional study materials and references.",
      t4_nam: 'Governance risk and compliance Training', 
      t4_target: 'Senior executives, board members, managers, governance professionals, internal and external auditors, and anyone with an interest in corporate governance', 
      t4_pre: 'Relevant professional experience in the field of business management, Basic understanding of business principles and operations.', 
      t4_edu1: "Gain a deep understanding of Governance, Risk, and Compliance (GRC) concepts within the business context.",
      t4_edu2: "Learn to assess operational, financial, and legal risks an organization may face.",
      t4_edu3: "Acquire skills to enhance corporate governance, including decision-making within the board of directors.",
      t4_edu4: "Develop strategies to manage risks, including the identification, prevention, and response to potential threats.",
      t4_edu5: "Implement practices and policies to improve organizational compliance and minimize non-compliance risks.",
      t4_edu6: "Develop communication skills to effectively report GRC issues to both internal and external stakeholders.",
      t4_edu7: "Understand applicable regulations, laws, and standards and develop strategies to ensure compliance.",
      t4_PA1: "The training will consist of a combination of theoretical presentations and hands-on exercises.",
      t4_PA2: "Real-world examples and case studies from  corporate governance will be used to illustrate the concepts.",
      t4_PA3: "Participants will have the opportunity to apply  the principles of good governance through exercises and projects.",
      t4_PA4: "Discussions and experience sharing will be encouraged to facilitate collaborative learning.",
      t4_Duration1: "X days (to be adjusted based on your specific needs)",
      t4_Duration2: "online, on our premises or on company premises",
      t4_Duration3: "From 1 to a maximum of 10 participants per group to ensure personalized attention",
      t4_Duration4: "depends on the contract with the company",
      t4_PR1: "Course materials and presentations to deliver theoretical knowledge.",
      t4_PR2: "Documentation on ISO 27001 and 27005 standards",
      t4_PR3: "Real-world case studies to understand challenges and solutions in concrete contexts.",
      t4_PR4: "Supplementary documentation and online resources for further knowledge enhancement.",
      t4_PR5: "...",
      t5_nam: 'Bootcamp for Cybersecurity certifications', 
      t5_target: 'IT risk manager, IT internal auditor, Pentester, network and systems engineer', 
      t5_pre: 'No specific prior certifications are required, but participants should choose certifications that align with their existing knowledge and career goals.', 
      t5_edu1: "Offer personalized options for cybersecurity certifications, including PECB 27001 LI/LA, eJPT, OSCP, and PSM I. Tailor training and certification preparation to each participant's chosen certification path.",
      t5_edu2: "Deepen understanding of ISO 27001 standards for all participants.",
      t5_edu3: "Provide practical training on penetration testing and vulnerability exploitation for eJPT and OSCP certifications, if chosen.",
      t5_edu4: "Teach Agile management principles with the PSM I certification for all participants.",
      t5_edu5: "Strengthen skills in system security, risk management, and compliance for all participants.",
      t5_edu6: "Prepare all participants for successful completion of their chosen certification exams.",
      t5_edu7: "Ensure that participants stay updated on the latest trends and best practices in cybersecurity, regardless of their chosen certification path.",
      t5_PA1: "Choose your certification path. Get personalized training.",
      t5_PA2: "Build a foundational knowledge base. Practice hands-on labs for practical skills.",
      t5_PA3: "Learn Agile and Scrum for project management. Receive mentoring for exam preparation.",
      t5_PA4: "Stay updated with ongoing learning resources.",
      t5_Duration1: "3 Month and plus (to be adjusted based on your specific needs)",
      t5_Duration2: "online, on our premises or on company premises",
      t5_Duration3: "From 1 to a maximum of 10 participants per group to ensure personalized attention",
      t5_Duration4: "depends on the contract with the company",
      t5_PR1: "Certification Materials: Tailored resources for each certification, including practice materials.",
      t5_PR2: "Online Learning Platforms: E-learning platforms with interactive content. Hands-On Labs: Practical exercises and simulations for hands-on experience.",
      t5_PR3: "Agile Training: Resources for learning Agile and Scrum. Mentorship: Guidance from experienced mentors.",
      t5_PR4: "Continuous Learning: Access to updated materials and communities. Exam Preparation: Resources for certification exam readiness.",
      t5_PR5: "Reference Materials: Additional study materials and references.",
      t6_nam: 'Maintenance of cybersecurity team skills', 
      t6_target: 'IT risk manager, IT internal auditor, Pentester, network and systems engineer', 
      t6_pre: 'Cybersecurity experience, Basic cybersecurity knowledge, Role in the field of cybersecurity or network security', 
      t6_edu1: "Ensure skill retention among cybersecurity team members.",
      t6_edu2: "Enable adaptation to new cybersecurity threats and attack methods.",
      t6_edu3: "Improve proficiency in incident response and mitigation.",
      t6_edu4: "Develop advanced cybersecurity techniques.",
      t6_edu5: "Foster effective teamwork and communication.",
      t6_edu6: "Stay updated on compliance and regulatory requirements.",
      t6_edu7: "Promote a culture of continual learning and improvement within the team",
      t6_PA1: "The training will consist of a combination of theoretical presentations and hands-on exercises.",
      t6_PA2: "Real-world examples and case studies from software development will be used to illustrate the concepts.",
      t6_PA3: "Participants will have the opportunity to apply Hacking techniques through exercises and projects.",
      t6_PA4: "Discussions and experience sharing will be encouraged to facilitate collaborative learning.",
      t6_Duration1: "X days (to be adjusted based on your specific needs)",
      t6_Duration2: "online, on our premises or on company premises",
      t6_Duration3: "From 1 to a maximum of 10 participants per group to ensure personalized attention",
      t6_Duration4: "depends on the contract with the company",
      t6_PR1: "A dedicated website offering numerous ethical hacking challenges.",
      t6_PR2: "Course materials and presentations for theoretical knowledge.",
      t6_PR3: "Practical exercises to apply ethical hacking techniques. Documentation on ISO 27001 and 27005 standards",
      t6_PR4: "Real-world case studies to understand challenges and solutions in concrete contexts.",
      t6_PR5: "Ethical hacking tools and programming environments for hands-on practice, supplemented with additional online resources for further learning.",
      t7_nam: 'Awareness, training, and knowledge retention for the development team', 
      t7_target: 'Development team members, including software developers, engineers, and related roles responsible for software development.', 
      t7_pre: 'Familiarity with programming languages and development tools.', 
      t7_edu1: "Increase cybersecurity awareness among the development team.",
      t7_edu2: "Provide training and skill enhancement opportunities.",
      t7_edu3: "Educate the team on security best practices.",
      t7_edu4: "Enable risk identification and mitigation.",
      t7_edu5: "Ensure compliance with cybersecurity requirements and standards.",
      t7_edu6: "Promote ongoing knowledge retention and continuous learning.",
      t7_edu7: "Foster collaboration between the development team and cybersecurity professionals.",
      t7_PA1: "Interactive workshops for cybersecurity awareness with real-world examples.",
      t7_PA2: "Practical, hands-on training to apply secure coding and handle security challenges.",
      t7_PA3: "Encourage collaborative learning through regular knowledge-sharing sessions.",
      t7_PA4: "Emphasize ongoing education with online courses, webinars, and industry community participation for staying updated.",
      t7_Duration1: "X days (to be adjusted based on your specific needs)",
      t7_Duration2: "online, on our premises or on company premises",
      t7_Duration3: "From 1 to a maximum of 10 participants per group to ensure personalized attention",
      t7_Duration4: "depends on the contract with the company",
      t7_PR1: "Course materials and presentations to deliver theoretical knowledge.",
      t7_PR2: "Practical exercises to apply code best practices.",
      t7_PR3: "Real-world case studies to understand challenges and solutions in concrete contexts.",
      t7_PR4: "Development tools and programming environments for hands-on coding practice.",
      t7_PR5: "Supplementary documentation and online resources for further knowledge enhancement.",
      key: "Key features",
      s1_name: "web, mobile and desktop application development",
      s1_description: "Our web, mobile, and desktop application development service is designed to meet your needs for creating secure and high-performing applications. We place a strong emphasis on security, ensuring that all applications we develop incorporate robust protective measures to safeguard your data and user safety.",
      s1_key1: "Cross-Platform Development",
      s1_key2: "User-Centric Design",
      s1_key3: "Agile Development",
      s1_key4: "Robust Security Measures",
      s1_key5: "Maintenance and Updates",
      s2_name: "Advice and mentoring for your cybersecurity career",
      s2_description: "We provide personalized guidance based on your natural abilities and the skills you could acquire in the short, medium, and long term. Our service connects you with a mentor and subject matter experts, and also offers opportunities to participate in our Graduate program if it aligns with your career aspirations.",
      s2_key1: "Personalized Skills Guidance",
      s2_key2: "Mentorship and Expert Connections",
      s2_key3: "Short, Medium, and Long-Term Skill Development",
      s2_key4: "Training Program Opportunities",
      s2_key5: "Career-Aligned Support",
      s3_name: "Development and sale of cybersecurity solutions",
      s3_description: "We specialize in creating and offering state-of-the-art cybersecurity solutions to protect your digital assets. Our service encompasses both the development and sale of these solutions, ensuring your organization\'s data and systems remain secure.",
      s3_key1: "Cutting-Edge Solutions",
      s3_key2: "Customized Packages",
      s3_key3: "Expert Technical Support",
      s3_key4: "Regular Updates",
      s3_key5: "Scalability",
      s4_name: "Advice on securing assets",
      s4_description: "Advice for the deployment and implementation of a secure network or cloud infrastructure as well as for the secure development of a web, mobile or desktop application",
      s4_key1: "Secure Network and Cloud Deployment Guidance",
      s4_key2: "Secure Application Development Advice",
      s4_key3: "Expert Consultation for Network and Cloud Security",
      s4_key4: "Application Security Best Practices",
      s4_key5: "Tailored Security Solutions and Strategies",
      s5_name: "Code tracking during all stages of development",
      s5_description: "Our development service prioritizes security throughout the entire process. One essential aspect is code tracking, ensuring that every change made to your project\'s codebase is carefully monitored and recorded. This meticulous approach significantly enhances the security and reliability of your software.",
      s5_key1: "Continuous security testing",
      s5_key2: "Code source analysis",
      s5_key3: "Proactive vulnerability detection",
      s5_key4: "Collaborative approach",
      s5_key5: "Enhanced application protection",
      s6_name: "Pentesting every viable product in development (SAST, DAST, IAST)",
      s6_description: "We conduct comprehensive security assessments on every deliverable product during the development process, utilizing a combination of Static Application Security Testing (SAST), Dynamic Application Security Testing (DAST), and Interactive Application Security Testing (IAST). In summary, our approach to DevSecOps places a particular emphasis on security by integrating these assessments at every stage of the development cycle.",
      s6_key1: "Security Assessments",
      s6_key2: "DevSecOps Approach",
      s6_key3: "Continuous Testing",
      s6_key4: "Risk Mitigation",
      s6_key5: "Comprehensive Security",
      s7_name: "Digital Investigation",
      s7_description: "Our Digital Investigation service is all about a thorough exploration and understanding of the digital aspects of your business. This enables us to detect, prevent, and resolve security incidents, contributing to the protection of your data and reputation.",
      s7_key1: "Comprehensive Digital Investigation",
      s7_key2: "Incident Detection and Prevention",
      s7_key3: "Digital Forensic Analysis",
      s7_key4: "Data Integrity Preservation",
      s7_key5: "Security Reports and Recommendations",
      s8_name: "Post-incident response",
      s8_description: "Our \"Post-Incident Response\" service is specifically designed to handle security incident situations. We step in after an incident to understand what happened, assess the damage, and take actions to restore security and prevent future incidents.",
      s8_key1: "Comprehensive Incident Assessment",
      s8_key2: "Root Cause Identification",
      s8_key3: "Security Restoration",
      s8_key4: "Recommendations for Future Incident Prevention",
      s8_key5: "Post-Incident Reports and Lessons Learned Analysis",
      s9_name: "Secure web, mobile and desktop application development",
      s9_description: "Our web, mobile, and desktop application development service is designed to meet your needs for creating secure and high-performing applications. We place a strong emphasis on security, ensuring that all applications we develop incorporate robust protective measures to safeguard your data and user safety.",
      s9_key1: "Cross-Platform Development",
      s9_key2: "User-Centric Design",
      s9_key3: "Agile Development",
      s9_key4: "Robust Security Measures",
      s9_key5: "Maintenance and Updates",
      s10_name: "Security Advisor",
      s10_description: "Our security advisory service is dedicated to providing you with expert guidance to fortify the security of your systems and data. Our security advisors will assist you in the identification, prevention, and management of potential threats.",
      s10_key1: "Expert Security Consultation",
      s10_key2: "Security Assessment",
      s10_key3: "Strategic Security Planning",
      s10_key4: "Threat and Vulnerability Management",
      s10_key5: "Personalized Security Recommendations",
      s11_name: "IT Audit, governance risk compliance (grc)",
      s11_description: "Our IT Audit and Governance Risk Compliance (GRC) service is a rigorous and systematic approach to ensure the integrity, security, and compliance of your organization\'s IT infrastructure and operations. We conduct extensive audits, evaluate risks, and implement governance strategies to protect your data, maintain regulatory compliance, and enhance overall operational efficiency.",
      s11_key1: "In-Depth IT Audits",
      s11_key2: "Risk Assessment and Mitigation",
      s11_key3: "Regulatory Compliance Validation",
      s11_key4: "Governance Framework Implementation",
      s11_key5: "Detailed Security and Compliance Reporting",
      s12_name: "Pentest all the IT solutions they use",
      s12_description: "We offer comprehensive penetration testing for all IT solutions your organization utilizes. Our pentest service rigorously evaluates the security of your systems to identify vulnerabilities and ensure a robust defense against potential threats.",
      s12_key1: "Comprehensive IT Solution Penetration Testing",
      s12_key2: "Vulnerability Identification and Assessment",
      s12_key3: "Real-World Threat Simulation",
      s12_key4: "Customized Security Recommendations",
      s12_key5: "Ongoing Security Support and Monitoring",
      s13_name: "Vulnerability Management (Asset control, vulnerability assessment and report) ",
      s13_description: "Vulnerability management is a continuous, proactive, and often automated process that ensures the safety and security of your computer systems, networks, and enterprise applications. It is designed to protect your organization from cyberattacks and data breaches by identifying and addressing potential vulnerabilities in a systematic and efficient manner.",
      s13_key1: "Precise Asset Control",
      s13_key2: "Thorough Vulnerability Assessments",
      s13_key3: "Real-time Threat Identification",
      s13_key4: "Detailed Risk Reports",
      s13_key5: "Remediation Guidance and Support",
      s14_name: "Development and sale of cybersecurity solutions",
      s14_description: "We specialize in creating and offering state-of-the-art cybersecurity solutions to protect your digital assets. Our service encompasses both the development and sale of these solutions, ensuring your organization\'s data and systems remain secure.",
      s14_key1: "Cutting-Edge Solutions",
      s14_key2: "Customized Packages",
      s14_key3: "Expert Technical Support",
      s14_key4: "Regular Updates",
      s14_key5: "Scalability",
      s15_name: "Cybersecurity legal assistance",
      s15_description: "Our Cybersecurity Legal Assistance service is part of our commitment to support any IT-related project. We offer expert guidance on industry standards and current laws to ensure the successful execution of your project. This service is designed to provide comprehensive legal counsel to help you remain compliant with relevant regulations, protect sensitive data, and mitigate legal risks throughout your project\'s lifecycle.",
      s15_key1: "Legal Compliance Analysis",
      s15_key2: "Data Protection and Privacy Guidance",
      s15_key3: "Cybersecurity Policy Development",
      s15_key4: "Incident Response Planning",
      s15_key5: "Litigation Support and Representation",
      s16_name: "Cybersecurity Awareness",
      s16_description: 'Our "Cybersecurity Awareness" service focuses on educating and empowering your organization\'s personnel with cybersecurity knowledge and best practices. We offer employee training, workshops, and phishing awareness programs. In addition, we conduct conferences on cybersecurity topics for specific audiences. As part of our dedication to cybersecurity awareness, we annually embark on nationwide tours, starting in Benin and extending to various African cities and villages, with the goal of educating individuals on the importance of cybersecurity and digital asset protection.',
      s16_key1: "Employee Training and Workshops",
      s16_key2: "Phishing Awareness Programs",
      s16_key3: "Security Best Practices Guidance",
      s16_key4: "Targeted Conferences",
      s16_key5: "Nationwide Outreach Tours",
      s17_name: "Raising the level of cybersecurity maturity from Zero to Hero",
      s17_description: "Our mission is to elevate your organization\'s cybersecurity maturity from ground zero to hero status, with a special focus on supporting SMEs. We offer this service at reduced costs, collaboratively defined with your company. This service is designed to assist SMEs in every aspect, from developing the necessary digital platforms for internal process digitalization to ensuring the physical and logical security of your infrastructure.",
      s17_key1: "Initial Maturity Assessment",
      s17_key2: "Tailored Maturity Roadmap",
      s17_key3: "Security Framework Implementation",
      s17_key4: "Ongoing Threat Monitoring and Response",
      s17_key5: "Continuous Improvement Strategies",
      FI: "For Individuals",
      FOC: "For Companies",
      MPME: "MPME",
      SDE: "Software development companies",
      EVA: "Companies victims of attacks",
      ALL: "All companies in any other sector",
      article1_identification: "Cybersécurité",
      article1_title: "The Importance of Cybersecurity Awareness in the Workplace",
      article1_description: "This article explores the crucial importance of cybersecurity awareness within companies, highlighting the potential risks associated with cyberthreats and how employees can help strengthen online security.",
      article2_identification: "Menaces",
      article2_title: "Cybersecurity Threat Trends for 2023",
      article2_description: "Discover the emerging cybersecurity threat trends shaping the digital security landscape in 2023. New attacks and vulnerabilities to watch out for.",
      article3_identification: "AI",
      article3_title: "The Crucial Role of Artificial Intelligence in Cybersecurity",
      article3_description: "This article explores how artificial intelligence (AI) has become a key element in the defense against cybersecurity threats. Find out how AI is being used to detect and counter cyber attacks.",
      article4_identification: "Internet of Things",
      article4_title: "Cybersecurity in the Internet of Things (IoT): Challenges and Solutions",
      article4_description: "The Internet of Things (IoT) is growing steadily, but it also brings security challenges. This article examines cybersecurity challenges in the IoT field and presents solutions for overcoming them.",
      article5_identification: "Cryptocurrency",
      article5_title: "Cryptocurrency and Cybersecurity: Financial Security Challenges",
      article5_description: "Cryptocurrency has attracted growing interest, but it's also exposed to security risks. This article explores the financial security issues associated with cryptocurrency and how investors can protect themselves.",
      article6_identification: "Health",
      article6_title: "Cybersecurity in the Healthcare World: Challenges and Preventive Measures",
      article6_description: "This article looks at specific cybersecurity challenges in the healthcare sector, where the protection of sensitive medical data is crucial. Find out how healthcare professionals and medical service providers can strengthen the security of patients' medical information.",
      aboutus: "About us",
      founders: "Founders",
      vision: "Vision",
      mission: "Mission",
      staff: "STAFF Direction",
      founders11: "My personal mission is to help influence the future development of people and organizations by living a God-centered life around the principles of integrity, excellence, servant leadership and trustworthiness.",
      founders12: "I am currently a cybersecurity analyst. I earned a bachelor's degree in computer science and a master's degree in information security and privacy, both from the University of North Carolina at Charlotte. As a cybersecurity analyst, my role is to help guide an organization's ability to operate securely.",
      founders13: "I believe it's my responsibility as a cybersecurity professional to equip organizations and individuals with the knowledge they need to outsmart cyber threats.",
      founders21: "As a risk management and ISMS consultant, I hold a DT in computer installation from LTI-P/N, a professional degree in information security from UAC-IFRI, and a work-study master in  system architecture, networking/cloud, and security from EPITECH. I've undertaken significant projects for the Ministry of Living Environment and Transport, developing security master plans and information system security policies. My roles as a pentester at ASIN and as a security manager in a financial institution have further fortified my skill set.",
      founders22: "Simultaneously, I engage in development across various technologies, work as a designer, teach at EPITECH, and lead IT projects following SCRUM methodology. A source of pride for me is my involvement in the digital transformation through the PROPME project with GIZ, where I identified suitable CRM tools and assessed the maturity level of SMEs.",
      founders23: "My aim is to integrate my technical, managerial, and legal expertise to become a pivotal figure in cybersecurity. Currently studying private law at UNCHK and certified in PSM, I intend to complement my knowledge with certifications such as ISO 27001, CEH and OSCP. My entrepreneurial vision is to revolutionize cybersecurity while innovating in job creation in Africa, using new approaches distinct from traditional methods.",
      founders31: "As a cyber security leader with more than 15 years of experience, I am passionate about aligning security with business strategy and seasoned in clearly communicating at all levels of the organization including the board.",
      founders32: "I have held senior roles at global companies including JPMorgan Chase, Accenture Consulting, Texas Capital, University of Florida with a proven track record of developing, executing and maturing security strategies that effectively mitigate risks and deliver world-class security solutions across a variety of industries ranging from financial services, oil and gas, life sciences, and higher education. ",
      founders33: "I am CISSP, CCNA, GICSP, and CISA certified and obtained a Master of Science in Information Assurance in 2008 from Nova Southeastern University which is a US National Security Agency’s National Center of Academic Excellence in Cybersecurity.",
      break: "A dynamic entrepreneur.",
      founders41: "My journey began with my graduation from Rennes ‘s Business School in 2008, paving the way for a decade-long career as the Manager of Duty Free Shops in Cotonou, where I honed my skills in management and marketing.",
      founders42: "My entrepreneurial spirit and unwavering determination led me to embrace the world of business. Committed to personal growth, I found my passion in coaching, guiding emerging talents as they navigate their career paths. Alongside this, my continuous pursuit of knowledge and self-improvement led me to obtain a Bachelor degree in Web Marketing and Digital Communication Project Management in 2022.",
      founders43: "Beyond the professional realm, I firmly believe in the philosophy of continuous growth, understanding that ongoing learning is the key to success.",
      staff11: "I am a web developer with a degree in Software Engineering. I enjoy taking on technical challenges and am passionate about solving complex problems through innovative solutions. I have a constant thirst for learning and I enjoy working on challenging projects to practice my skills and develop new ones.",
      staff12: "My ultimate goal is to play an essential role in the advancement of the digital sector in Benin, by providing my expertise in the development of automated systems, and by actively contributing to the training of the next technological leaders.",
      staff21: "I studied at the Institut de Formation et de Recherche en Informatique (IFRI), specializing in Internet and Multimedia, where I'm graduating this year. I began my career as a social media manager, where I learned the basics of social network management and community engagement. This enables me to develop communication strategies to increase brand interaction and visibility on social platforms.",
      staff22: "",
      mission_text: "One of the real security problems is the cleanliness of the code written by our developer brothers. Between the efforts required to produce a deliverable that satisfies the customer and the difficulties involved in implementing the application, there's no time left to devote to the cleanliness of written code. Ignorance of best practices is another reason. IronSecur was created primarily to respond to this problem through training, penetration testing, code auditing and GRC.",
      vision_text:  "Make Benin/Africa the anvil of a secure cyberspace. In simpler terms, to resolve the security failures of the tools we've been using since their inception, and thus reduce by more than 90% the possibility of attacks targeting our local resources.",
      
    },
  },
});

export default i18n;
