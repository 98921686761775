<template>
        <div class=" cursor-pointer shadow-4xl w-[350px] md:w-[320px] max-w-[90%] m-[10px] " v-for="(article, index) in sortedPublishedArticles" :key="index">
          <!-- Contenu de chaque carte -->
          <div class="bg-white h-[550px] shadow-md p-[10px] relative rounded-lg">
            <div class="w-[40px] flex justify-center items-center h-[40px] rounded-[50%] text-white bg-cyan-700">
              <span class="font-bold uppercase">{{ article.id }}</span>
            </div>
            <div class="w-full relative shadown h-[250px] overflow-hidden my-[10px]">
              <h2 class="text-xl my-[10px] font-semibold">
                <router-link :to="{ name: 'article', params: { id: article.id }}" class="hover:text-black hover:underline duration-300">{{ article.title }}</router-link>
              </h2>
              <p class="text-gray-800">
                <div v-html="article.content"></div>
              </p>
            </div>
            <div class="w-full absolute bottom-[80px] left-0 h-[150px] max-h-[150px] overflow-hidden flex justify-start items-start">
              <img :src="article.image" alt="" class="w-full">
            </div>
            <div class="absolute w-full left-0 right-0 p-[10px] text-black bg-gray-200 bottom-0 flex justify-between items-center">
              <div>
                <span class="uppercase text-sm font-bold">Article</span>
                <span class="font-bold uppercase text-gray-400 text-sm mx-[10px]">{{ article.source }}</span>
              </div>
              <div class="flex justify-center items-center">
                <p class="mx-[10px]">{{ article.likes.length }}</p>
                <div v-if="article.is_liked_by_current_user">
                  <i class="text-red-500 text-xl fa-solid fa-heart"></i>
                </div>
                <div v-else>
                  <i class="fa-regular fa-heart text-xl text-black"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import HeartIcon from './HeartIcon.vue';
import Article from '@/components/types/Article';
const props = defineProps<{
    articles: Article[],
}>();

const sortedPublishedArticles = ref<Article[]>([]);

watch(() => props.articles, () => {
    sortedPublishedArticles.value = props.articles.filter(article => article.is_published).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
});

</script>

<style>

</style>