<template>
  <div class="w-full p-[20px] flex justify-center items-center">
    <div class="w-full flex items-start">
        <div class="flex justify-center items-center mr-[10px] md:mr-[20px]">
            <img src="../assets/img/Rectangle.jpg" alt="" class="rounded-[50%] h-[30px] w-[30px] md:w-[50px] md:h-[50px] ">
        </div>
        <form class="w-full" @submit.prevent="postComment">
            <div class="w-full flex justify-between items-center relative">
                <div class="w-[49%] md:my-[10px] my-[5px] mt-0 text-left ">
                    <input type="text" id="username" v-model="username" aria-label="username" placeholder="Username or Name" class="placeholder:text-blue-700 placeholder w-full p-[10px] border border-gray-400 rounded-[5px] outline-none focus:border-blue-500">
                </div>
                <div class="w-[49%] md:my-[10px] my-[5px] mt-0 text-left ">
                    <input type="text" id="email" v-model="email" placeholder="Email" class="placeholder:text-blue-700 placeholder w-full p-[10px] border border-gray-400 rounded-[5px] outline-none focus:border-blue-500">
                </div>
            </div>
            <div class="flex justify-between items-center relative w-full">
                <div class="w-full md:my-[10px] my-[5px]">
                    <textarea type="text" id="description" v-model="content" placeholder="Add comment" class=" w-full h-[100px] md:h-[200px] resize-none p-[10px] border border-gray-400 rounded-[5px] outline-none focus:border-blue-500"></textarea>
                </div>
            </div>
            <div class="flex justify-between items-center relative w-full">
                <div class="w-full flex my-[5px] md:my-[20px] justify-end ">
                    <button type="submit" :disabled="loading" class="bg-blue-700 text-white min-w-[70px] hover:bg-blue-800 hover:font-bold duration-300 rounded-[5px] px-[20px] py-[10px] ">
                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                        <span v-else>Post</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
const loading = ref(false);
import axiosInstance from '@/plugins/axios';

const username = ref('');
const email = ref('');
const content = ref('');

const router = useRouter();

const errorMessage = ref("");

const postComment = async () => {
  const commentData = {
    username: username.value,
    email: email.value,
    content: content.value,
  };

  const articleId = router.currentRoute.value.params.id;

  try {
    loading.value = true
    const response = await axiosInstance.post(`/articles/${articleId}/comments/`, commentData);

    clearForm();
    window.location.reload();

  } catch (error) {
        errorMessage.value = 'Erreur !!! Votre commentaire n\'a pas été envoyé';
        hideErrorMessageAfterDelay();
  } finally {
    loading.value = false
  }
};

const clearForm = () => {
  username.value = '';
  email.value = '';
  content.value = '';
};

const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 5000);
};
</script>

<style>

</style>