<template class="max-w-screen">
    <div class="flex font-montserrat flex-col justify-center items-center">
      <HeaderComponent/>
      <div class="relative">
          <div class="lg:h-[80px] h-[50px]"></div>
          <carouselBranding :initial-slide="initialSlide" ></carouselBranding>
          <div class="absolute z-[1000000] text-left bg-black/50 px-[20px] md:px-[50px] text-white top-0 left-0 right-0 bottom-0 w-full flex items-center flex-col justify-center ">
              <h1 class="text-4xl md:text-6xl uppercase text-center font-bold opacity-100">{{ $t('training') }}</h1>
          </div>
      </div>
      <div class="container max-w-[1024px]">
        <div class="w-full relative flex justify-center items-center md:mt-[50px] mt-[20px]">
          <div class="container relative w-full md:my-[50px] my-[20px]">
              <h1 class="text-2xl my-[20px] font-bold text-center text-cyan-700 uppercase">
                {{ $t('training') }}
              </h1>
              <div class="w-full flex relative ">
                  <span @click="goToTrainingPage" :class="{
                  'border-b-4 w-4/12 cursor-pointer md:min-w-[200px] px-[10px]': true, 
                  ' border-cyan-700': $route.path === '/training'
              }">{{ $t('FD') }}</span>
                  <span @click="goToTrainingSPage" :class="{
                  'border-b-4 w-4/12 cursor-pointer md:min-w-[200px] px-[10px]': true, 
                  ' border-cyan-700': $route.path === '/trainingFS'
              }">{{ $t('FS') }}</span>
                  <span @click="goToTrainingCPage" :class="{
                  'border-b-4 w-4/12 cursor-pointer md:min-w-[200px] px-[10px]': true, 
                  ' border-cyan-700': $route.path === '/trainingFC'
              }">{{ $t('FC') }}</span>
              </div>
              <One_trainings :trainings="trainings" />
          </div>
      </div>
      </div>
      <FooterComponent/>
    </div>
  </template>
  
  <script setup lang="ts">
  import HeaderComponent from '../components/HeaderComponent.vue'
  import carouselBranding from '../components/CarouselBranding.vue'
  import One_trainings from '@/components/One_trainings.vue';
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
import FooterComponent from '@/components/FooterComponent.vue';
  const router = useRouter()
  const initialSlide = 1;

  
  function goToTrainingSPage() {
      router.push('/trainingFS')
  }
  function goToTrainingCPage() {
      router.push('/trainingFC')
  }
  function goToTrainingPage() {
      router.push('/training')
  }
  const pageTitle = ref('IronSecur | Training'); 
  
  onMounted(() => {
      document.title = pageTitle.value; 
  });
  
  const trainings = ref([

    {   nam: 't4_nam', 
        target: 't4_target', 
        pre: 't4_pre', 
        PREstyle: 'hidden',
        edu: ["t4_edu1", "t4_edu2", "t4_edu3", "t4_edu4", "t4_edu5", "t4_edu6", "t4_edu7"], 
        image: "/img/training3.jpg", 
        booklet: '#', 
        PA: ["t4_PA1", "t4_PA2", "t4_PA3", "t4_PA4"], 
        Duration: ["t4_Duration1", "t4_Duration2", "t4_Duration3", "t4_Duration4"], 
        PR: ["t4_PR1", "t4_PR2", "t4_PR3", "t4_PR4", "t4_PR5"] 
    },
    {   nam: 't5_nam', 
        target: 't5_target', 
        pre: 't5_pre', 
        edu: ["t5_edu1", "t5_edu2", "t5_edu3", "t5_edu4", "t5_edu5", "t5_edu6", "t5_edu7"], 
        image: "/img/training.jpg", 
        booklet: '#', 
        PA: ["t5_PA1", "t5_PA2", "t5_PA3", "t5_PA4"], 
        Duration: ["t5_Duration1", "t5_Duration2", "t5_Duration3", "t5_Duration4"], 
        PR: ["t5_PR1", "t5_PR2", "t5_PR3", "t5_PR4", "t5_PR5"] 
    },

    {   nam: 't6_nam', 
        style: "md:leading-10",
        target: 't6_target', 
        pre: 't6_pre', 
        edu: ["t6_edu1", "t6_edu2", "t6_edu3", "t6_edu4", "t6_edu5", "t6_edu6", "t6_edu7"], 
        image: "/img/training4.jpg", 
        booklet: '#',
        PA: ["t6_PA1", "t6_PA2", "t6_PA3", "t6_PA4"], 
        Duration: ["t6_Duration1", "t6_Duration2", "t6_Duration3", "t6_Duration4"], 
        PR: ["t6_PR1", "t6_PR2", "t6_PR3", "t6_PR4", "t6_PR5"] 
    },
 
    {   nam: 't7_nam', 
        target: 't7_target', 
        pre: 't7_pre', 
        edu: ["t7_edu1", "t7_edu2", "t7_edu3", "t7_edu4", "t7_edu5", "t7_edu6", "t7_edu7"],  
        image: "/img/training5.jpg", 
        booklet: '#', 
        PA: ["t7_PA1", "t7_PA2", "t7_PA3", "t7_PA4"], 
        Duration: ["t7_Duration1", "t7_Duration2", "t7_Duration3", "t7_Duration4"], 
        PR: ["t7_PR1", "t7_PR2", "t7_PR3", "t7_PR4", "t7_PR5"] 
    },

        
  ]);
  
  </script>
  
  <style>
  
  </style>