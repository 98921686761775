<template>
    <div class="w-full font-montserrat relative">
        <headerComponent/>
        <div class="relative mb-[50px] w-full">
            <div class="p-[20px] flex flex-col items-center relative bg-gray-50 min-h-screen top-[60px]">
                <h1 class="text-black font-bold py-[20px] text-center">Home / Profil</h1>
                <h1 class="text-2xl uppercase font-bold text-center text-black py-[20px]">Profile</h1>
                <div class=" block md:flex justify-center w-[90%] py-[10px]">
                    <div class="md:w-[200px] p-[10px] w-full md:h-[200px] bg-white md:mx-[10px] flex flex-col justify-center items-center">
                        <img src="../assets/img/icon.jpeg" alt=""  class="w-[120px] h-[120px] rounded-[50%] border border-black">
                        <h1 class="uppercase my-[10px]"> {{ user.firstname }} {{ user.lastname }} </h1>
                    </div>
                    <div class="w-full md:my-[0px] my-[20px] md:w-[calc(100%-240px)] relative bg-white md:mx-[10px] px-[10px] py-[50px]">
                        <linkProfile/>
                        <div class="md:py-[40px] md:px-[20px] px-[10px] py-[30px]">
                            <div class="py-[15px]">
                                <h1 class="text-cyan-600 uppercase font-bold text-1xl">About</h1>
                                <p class="inline-flex text-justify">
                                    {{ user.about }}
                                </p>
                            </div>
                            <div class="py-[15px]">
                                <h1 class="text-cyan-600 uppercase font-bold text-1xl">Profile details</h1>
                            </div>
                            <div class="py-[15px] flex justify-between items-center">
                                <h1 class="text-cyan-600 uppercase text-1xl">Full name</h1>
                                <div class="flex justify-start items-center w-[calc(100%/2)]">
                                    <span class="uppercase text-justify "> {{ user.firstname }} {{ user.lastname }} </span>
                                </div>
                            </div>
                            <div class="py-[15px] flex justify-between items-center">
                                <h1 class="text-cyan-600 uppercase text-1xl">Job</h1>
                                <div class="flex justify-start items-center w-[calc(100%/2)]">
                                    <span class="text-justify"> {{ user.job }} </span>
                                </div>
                                
                            </div>
                            <div class="py-[15px] flex justify-between items-center">
                                <h1 class="text-cyan-600 uppercase text-1xl">Phone</h1>
                                <div class="flex justify-start items-center w-[calc(100%/2)]">
                                    <span class="text-justify"> {{ user.phone }} </span>
                                </div>
                            </div>
                            <div class="py-[15px] h-auto flex justify-between items-center">
                                <h1 class="text-cyan-600 uppercase text-1xl">Email</h1>
                                <div class="flex justify-start relative items-center w-[calc(100%/2)]">
                                    <span class="text-justify max-w-[100%] h-auto"> {{ user.email }} </span>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    
                </div>
            </div>
        </div>
        <div class="w-full border-t border-gray-400 py-[50px] flex justify-center items-center">
            <h1 class="text-center font-bold text-sm">&copy; 2023 Cotonou Cadjehoun, Tous droits réservés.</h1>
        </div>
    </div>
</template>

<script setup lang="ts">
import headerComponent from '../components/headerComponentAdmin.vue'
import linkProfile from '../components/linkProfile.vue'
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
const pageTitle = ref('Profile Overview'); 
import axios from 'axios';
const router = useRouter()
import axiosInstance from '@/plugins/axios';


onMounted(() => {
    document.title = pageTitle.value; 
});


const logout = () => {
    localStorage.clear();
    router.push('/');
};

let user = ref({
});

const fetchUserProfile = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const response = await axiosInstance.get('/auth/users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    user.value = response.data;
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  fetchUserProfile();
});
</script>

<style>

</style>